import 'moment/locale/fr';
import './App.css';
import { ThemeProvider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useCallback, useEffect } from 'react';
import { provider, useInstances } from 'react-ioc';
import { BrowserRouter } from 'react-router-dom';
import { AgencyAdminStore } from './Stores/AgencyAdmin.store';
import { LoginViewStore } from './Stores/viewStore/LoginView.store';
import { UserConnectStore } from './Stores/UserConnect.store';
import AppModule from './AppModule';
import { AppAvailability } from '@assets/models/configuration/appAvailability.model';
import AppUnavailableComponent from './Component/AppUnvailable/AppUnavailableComponent';
import ChatBotComponent from './Component/ChatBot/ChatBotComponent';
import PageLoaderComponent from './Component/PageLoader/PageLoaderComponent';
import SnackErrorComponent from './Component/SnackbarError/SnackErrorComponent';
import { useFeatureFlag } from './Hooks/useFeatureFlag';
import { isAuthenticated } from './Services/accessToken.service';
import { AgenciesStore } from './Stores/Agencies.store';
import { AgencyInvoiceStore } from './Stores/AgencyInvoice.store';
import { AppConfigStore } from './Stores/AppConfig.store';
import { BeneficiariesStore } from './Stores/Beneficiaries.store';
import { BeneficiaryPaybackStore } from './Stores/BeneficiaryPayback.store';
import { MandateStore } from './Stores/Mandate.store';
import { PayinStore } from './Stores/Payin.store';
import { ProvisionStore } from './Stores/Provision.store';
import { RechargingViewStore } from './Stores/viewStore/RechargingView.store';
import { themeMui } from './Style/ThemeMui';
import { onRefreshTokenError } from './Services/interceptors.service';
import { errorStore } from './Stores/Error.store';

export interface IUser {
  companyId: string,
  firstName: string,
  lastName: string,
  email: string,
  role: string,
  userId: string

}

type LocalStore = [UserConnectStore, AgenciesStore, AgencyAdminStore, MandateStore, AgencyInvoiceStore, AppConfigStore];

const App = provider(
  UserConnectStore,
  AgenciesStore,
  MandateStore,
  AgencyInvoiceStore,
  RechargingViewStore,
  BeneficiariesStore,
  AgencyAdminStore,
  PayinStore,
  BeneficiaryPaybackStore,
  ProvisionStore,
  LoginViewStore,
  AppConfigStore,
)(observer(() => {
  const [
    userConnectStore,
    agenciesStore,
    agencyAdminStore,
    mandateStore,
    agencyInvoiceStore,
    appConfigStore,
  ] = useInstances<LocalStore>(UserConnectStore, AgenciesStore, AgencyAdminStore, MandateStore, AgencyInvoiceStore, AppConfigStore);

  const isConfigReady = appConfigStore.isReady();
  const appAvailability = useFeatureFlag<AppAvailability>('appAvailability', false);
  const isAppAvailable = appAvailability.enabled;

  const logout: () => void = useCallback(() => {
    userConnectStore.userLogout();
    agenciesStore.reset();
    agencyAdminStore.resetAgencyAdmin();
    mandateStore.reset();
    agencyInvoiceStore.reset();
  }, []);

  onRefreshTokenError(() => {
    userConnectStore.userLogout();
  });

  useEffect(() => {
    if (isAuthenticated()) {
      userConnectStore.userLogin();
    } else {
      userConnectStore.userLogout();
    }
    moment.locale('fr');
  }, []);

  useEffect(() => {
    if (userConnectStore.isConnected && isAppAvailable) {
      void agencyAdminStore.getConnectedAgencyAdmin();
    }
  }, [userConnectStore.isConnected, isAppAvailable]);

  useEffect(() => {
    if (agencyAdminStore.agencyAdminStatus === 'ERROR') {
      logout();
    }
  }, [agencyAdminStore.agencyAdminStatus]);

  useEffect(() => {
    const { agencyAdminSelected }: AgencyAdminStore = agencyAdminStore;
    if (!agencyAdminSelected) {
      return;
    }
    const { companyId, uid } = agencyAdminSelected;
    void agenciesStore.fetchAllAgenciesOfConnectingAdmin(companyId, uid);
  }, [agencyAdminStore.agencyAdminSelected]);

  return (
    <ThemeProvider theme={themeMui}>
      {!isConfigReady && <PageLoaderComponent/>}

      {isConfigReady && !isAppAvailable && <AppUnavailableComponent message={appAvailability.message}/>}

      {isConfigReady && isAppAvailable &&
          <BrowserRouter>
            <AppModule/>
            {(errorStore.error && userConnectStore.isConnected) && (
              <SnackErrorComponent
                message={errorStore.messageToDisplay}
                onClose={() => errorStore.clear()}
            />
            )}
            <ChatBotComponent></ChatBotComponent>
          </BrowserRouter>
      }
    </ThemeProvider>
  );
}));

export default App;
