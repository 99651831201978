
export function formatCurrencyToEuro(value: number) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency', currency: 'EUR',
  }).format(value);
}

export function formatDateToLocaleString(date: Date | string | number, locales: string = 'fr-FR') {
  return new Date(date).toLocaleDateString(locales);
}
