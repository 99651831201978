import { Dialog } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import { useLocation } from 'react-router-dom';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import BeneficiaryDetailsComponent
  from '../../Beneficiaries/BeneficiaryDetails/BeneficiaryDetails/BeneficiaryDetailsComponent';
import StepperComponent from '../../Component/StepperComponent';
import { TStepAddNewRecharging } from '../../Models/Types/TStepAddNewRecharging.model';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { PayingServiceStore } from '../../Stores/PayingService.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { OrderValidationViewStore } from '../../Stores/viewStore/OrderValidationView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import useDialogStyles from '../../Style/MuiStyles/Dialog';
import { PageBlock } from '../../Style/Style';
import AddNewBeneficiariesUpload from '../AddNewBeneficiariesUpload/AddNewBeneficiariesUpload';
import OrderListBeneficiariesComponent from '../OrderListBeneficiaries/OrderListBeneficiariesCompnent';
import OrderListBeneficiariesFromCsvComponent from '../OrderListBeneficiaries/OrderListBeneficiariesFromCsvComponent';
import OrderParametersComp from '../OrderParameters/OrderParametersComp';
import OrderPayment from '../OrderPayment/OrderPayment';
import OrderSummary from '../OrderSummary/OrderSummary';
import OrderValidationComponent from '../OrderValidation/OrderValidationComponent';
import SelectMethodRechargingComponent from '../SelectMethodRecharging/SelectMethodRechargingComponent';
import { useAddNewRechargingStyles } from './Styles';
import YourInformation from '../YourInformation/YourInformation';
import { useNavigate } from 'react-router';

type LocalStore = [AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, OrderPaymentViewStore];

const AddNewRechargingComponent: FunctionComponent = provider(
  StepperRechargingStore,
  OrderValidationViewStore,
  OrderPaymentViewStore,
  PayingServiceStore,
)(observer(() => {
  const [
    agenciesStore,
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
    orderPaymentViewStore,
  ]: [
    AgenciesStore,
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore,
    OrderPaymentViewStore,
  ] = useInstances<LocalStore>(AgenciesStore, RechargingViewStore, StepperRechargingStore, BeneficiariesStore, OrderPaymentViewStore);

  const location = useLocation();

  const { hasInvoiceInformation }: AgenciesStore = agenciesStore;

  const currentStep: TStepAddNewRecharging = stepperRechargingStore.currentStep;
  const beneficiariesActiveList: BeneficiaryModel[] = beneficiariesStore.activeBeneficiaryList;
  const { dateString } = orderPaymentViewStore;

  const AddNewRechargingStyles: ClassNameMap<'buttonMargin' | 'reload' | 'stepper' | 'mainTitle'> = useAddNewRechargingStyles();
  const dialogClasses: ClassNameMap<'dialogPaper'> = useDialogStyles();
  const { t } = useTranslation('reloading');
  const navigate = useNavigate();

  const steps: TStepAddNewRecharging[] = [
    'SELECT_METHOD_RECHARGING',
    stepperRechargingStore.isUploadingCsv ? 'ADD_NEW_BENEFICIARIES_UPLOAD' : 'PARAMETERS',
    hasInvoiceInformation ? 'YOUR_INFORMATION' : null,
    stepperRechargingStore.isUploadingCsv ? 'LIST_BENEFICIARIES_FROM_CSV' : 'LIST_BENEFICIARIES',
    'CONFIRMATION',
    'PAYMENT'
  ].filter((step): step is TStepAddNewRecharging => !!step);

  useEffect(() => {
    stepperRechargingStore.setStepNameList([...steps, 'RECAP']);
  }, [steps]);

  useEffect(() => {
    if (agenciesStore.currentAgency && !rechargingViewStore.openBeneficiaryDetails) {
      void beneficiariesStore.fetchAllBeneficiariesByAgencyId(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency, rechargingViewStore.openBeneficiaryDetails]);

  useEffect(() => {
    if (location.pathname === '/continueOrder') {
      stepperRechargingStore.goToStep('PAYMENT');
      orderPaymentViewStore.reloadPendingOrderDetails();
      agenciesStore.setPendingCurrentAgency();
      navigate('/addNewRecharging');
    }
  }, [location.pathname]);

  return (
    <PageBlock>
      {currentStep !== 'SELECT_METHOD_RECHARGING'
        && <>
          {currentStep !== 'ADD_NEW_BENEFICIARIES_UPLOAD'
            ? <>
              {dateString &&
                  <h1>{`${t('title')} ${dateString}`}</h1>}
              <br/>
              {t('activeBeneficiaries', { count: beneficiariesActiveList.length })}
            </>
            : <h1 className={AddNewRechargingStyles.mainTitle}>{t('titleChoice')}</h1>}
              <div className={AddNewRechargingStyles.stepper}>
                  <StepperComponent steps={steps}/>
              </div>
          </>
      }
      {currentStep === 'SELECT_METHOD_RECHARGING' && <SelectMethodRechargingComponent/>}
      {currentStep === 'PARAMETERS' && <OrderParametersComp/>}
      {currentStep === 'ADD_NEW_BENEFICIARIES_UPLOAD' && <AddNewBeneficiariesUpload/>}
      {currentStep === 'YOUR_INFORMATION' && <YourInformation/>}
      {currentStep === 'LIST_BENEFICIARIES' && <OrderListBeneficiariesComponent/>}
      {currentStep === 'LIST_BENEFICIARIES_FROM_CSV' && <OrderListBeneficiariesFromCsvComponent/>}
      {currentStep === 'CONFIRMATION' && <OrderValidationComponent/>}
      {currentStep === 'PAYMENT' && <OrderPayment/>}
      {currentStep === 'RECAP' && <OrderSummary/>}

      {(currentStep === 'LIST_BENEFICIARIES' || currentStep === 'LIST_BENEFICIARIES_FROM_CSV') &&
          <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md"
                  open={rechargingViewStore.openBeneficiaryDetails}
                  onClose={() => rechargingViewStore.setOpenBeneficiaryDetails(false)}
          >
              <BeneficiaryDetailsComponent
                  beneficiary={null}
                  onClose={() => rechargingViewStore.setOpenBeneficiaryDetails(false)}
              />
          </Dialog>
      }
    </PageBlock>
  );
}));

export default AddNewRechargingComponent;
