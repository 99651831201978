import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const COLORS = {
  primary: '#86D0EF',
  textPrimary: '#2E4052',
  textSecondary: '#79BEDB',
  error: '#E94B32',
  backgroundBlock: '#F7F7F8',
  footerBlock:'#EDEFF0',
  borderBlock: '#D5D9DC',
  greyRgb247: 'rgb(247, 247, 248)',
  white: '#FFFFFF',
  validated: '#82E0AA',
  success: 'rgba(140,216,103,0.2)',
  lightError: 'rgba(233,75,50,0.21)',
  failed: 'rgba(255, 193, 94, 0.4)',
  blackLight: '#515A5A',
  orangeLight : '#FFC15E'
};

export const HOVER = {
  blockShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
};

export const FONT = {
  primary: 'Inter',
  secondary: 'HurmeGeometricSans1-Bold',
};

export const FONT_WEIGHT = {
  normal: 400,
  semiBold: 500,
  bold: 600,
};

// eslint-disable-next-line react/prop-types
export const PageBlock = ({ children, className = undefined }) => {
  return (
    <div
      className={className}
      style={{
        backgroundColor: 'white',
        margin: '20px',
        height: 'calc(100% - 40px)',
        borderRadius: '20px',
        padding: '30px',
        fontFamily: FONT.primary,
        color: COLORS.textPrimary,
      }}>
      {children}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
export const PageSubtitle = ({ children }) => {
  return (
    <div style={{
      marginTop: '1rem',
      marginBottom: '16px',
      color: COLORS.textPrimary,
      fontSize: '14px',
    }}>
      {children}
    </div>
  );
};

export const appBarStyle = makeStyles({
  root: {
    '.MuiPaper-elevation4': {
      boxShadow: 'none !important',
    },
    '.MuiTab-root': {},
  },
  borderBottom: {
    borderBottom: '1px solid #D5D9DC',
  },
});
