const clientTimezone = 'Europe/Paris';

export function getGeniusFormatDate(date: (string | number)): string {
  return new Date(date).toISOString();
}

export function getFrenchFormatDate(date: Date): string {
  return date.toLocaleDateString('fr', { timeZone: 'Europe/Paris' });
}

export function getCurrentDate(): Date {
  return new Date();
}

export function dateToTimestamp(date: Date): number {
  return date.getTime();
}

export function getTimeStampStartOfDay(input: unknown): number {
  const date = toDate(input);

  return date.setHours(0, 0, 0, 0);
}

export function getLocalTimeStampStartOfDay(input: unknown): number {
  const localStringDate = getLocalStringDate(input);
  const localDate = toDate(localStringDate);

  if (!isStartOfDay(localDate)) {
    const utcStartOfDay = localDate.setUTCHours(0, 0, 0, 0);
    const utcDate = new Date(utcStartOfDay);

    return adjustLocalTimestamp(utcDate);
  }

  return dateToTimestamp(toDate(input));
}

export function getTimeStampEndOfDay(input: unknown): number {
  const date = toDate(input);

  return date.setHours(23, 59, 59, 999);
}

export function getLocalTimeStampEndOfDay(input: unknown): number {
  const localStringDate = getLocalStringDate(input);
  const localDate = toDate(localStringDate);

  if (!isEndOfDay(localDate)) {
    const utcEndOfDay = localDate.setUTCHours(23, 59, 59, 999);
    const utcDate = new Date(utcEndOfDay);

    return adjustLocalTimestamp(utcDate);
  }

  return dateToTimestamp(toDate(input));
}

export function castDate(input: unknown): Date | undefined {
  if (input) {
    return toDate(input);
  }

  return undefined;
}

export function getLocalStringDate(input: unknown): string {
  return toDate(input).toLocaleString('en-US', { timeZone: clientTimezone });
}

export function toDate(input?: unknown): Date {
  if (input instanceof Date) {
    return input;
  }

  if (typeof input === 'string' || typeof input === 'number') {
    return new Date(input);
  }

  return new Date();
}

export function getTimeStampMinusMonth(monthRange: number, timeStamp?: number): number {
  const date = timeStamp ? new Date(timeStamp) : new Date();
  return date.setMonth(date.getMonth() - monthRange);
}

export function getTimeStampPlusMonth(monthRange: number, timeStamp?: number): number {
  const date = timeStamp ? new Date(timeStamp) : new Date();

  return date.setMonth(date.getMonth() + monthRange);
}

export function getCurrentDateInDDMMYYYYFormat(separator = '-'): string {
  const now: Date = new Date();
  const { day, month, year } = {
    day: String(now.getDate()).padStart(2, '0'),
    month: String(now.getMonth() + 1).padStart(2, '0'), // Month is 0-indexed
    year: now.getFullYear(),
  };
  return [day, month, year].join(separator);
}

function isStartOfDay(date: Date): boolean {
  return date.getHours() === 0 &&
    date.getMinutes() === 0 &&
    date.getSeconds() === 0 &&
    date.getMilliseconds() === 0;
}

function isEndOfDay(date: Date): boolean {
  return date.getHours() === 23 &&
    date.getMinutes() === 59 &&
    date.getSeconds() === 59 &&
    date.getMilliseconds() === 999;
}

function adjustLocalTimestamp(utcDate: Date): number {
  const offsetInHours = getLocalDateOffset(utcDate);

  return utcDate.setHours(utcDate.getHours() - offsetInHours);
}

function getLocalDateOffset(utcDate: Date): number {
  const formatToParts = Intl.DateTimeFormat('en-US', {
    timeZone: 'Europe/Paris',
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
    hour12: false,
  }).formatToParts(utcDate);

  const { year, month, day, hour, minute, second } = Object.fromEntries(
    formatToParts.map(({ type, value }) => [type, parseInt(value)]),
  );


  const utcDateInClientTimeZoneTS = Date.UTC(year, month - 1, day, hour % 24, minute, second);
  const utcDateTS = utcDate.getTime();
  const timeZoneOffset = utcDateInClientTimeZoneTS - utcDateTS;

  return Math.round(timeZoneOffset / 1000 / 60 / 60); // tz offset in hour
}
