import React from 'react';
import logo from '../assets/logo-side-menu.svg';
import home from '../assets/home.svg';
import agency from '../assets/agency.svg';
import user from '../assets/user.svg';
import accounting from '../assets/accounting.svg';
import reload from '../assets/recharging.svg';
import euro from '../assets/euro.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { List } from '@material-ui/core';
import StyledSideBarMenuListItem from '../Style/MuiStyles/SideBarListItem';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../Stores/Agencies.store';

const SidebarMenuComponent = observer(() => {
  const agenciesStore = useInstance(AgenciesStore);
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line react/prop-types
  const MenuLink = ({ title, linkTo, iconeName }) => {
    return (
      <StyledSideBarMenuListItem
        onClick={() => navigate(linkTo)}
        button key={title}
        iscurrentpage={location.pathname === linkTo ? 1 : 0}
      >
        <img style={{ marginBottom: '7px' }} width={25} alt={`Page ${title}`} src={iconeName}/>
        {title}
      </StyledSideBarMenuListItem>);
  };

  return (
    <div style={{ width: '150px', marginBottom: '4rem' }}>
      <div style={{
        display: 'flex',
        placeContent: 'center',
        marginTop: '3rem',
        width: '100%',
        marginBottom: '1rem',
        cursor: 'pointer',
      }}
           onClick={() => navigate('/dashboard')}
      >
        <img width={60} height={60} alt="logo" src={logo}/>
      </div>
      <List>
        <MenuLink title="Accueil" linkTo="/dashboard" iconeName={home}/>
        <MenuLink title="Rechargement des droits" linkTo="/reloading" iconeName={reload}/>
        <MenuLink title="Paramètres" linkTo="/agency" iconeName={agency}/>
        <MenuLink title="Collaborateurs" linkTo="/beneficiaries" iconeName={user}/>
        <MenuLink title="Factures" linkTo="/accounting" iconeName={accounting}/>
        {agenciesStore.currentAgency?.isManagedPaymentMode === false &&
            <MenuLink title="Remboursements" linkTo="/paymentrequest" iconeName={euro}/>
        }
      </List>
    </div>
  );
});

export default SidebarMenuComponent;
