import { makeStyles } from '@material-ui/core';

const useDialogStyles = makeStyles(() => ({
  dialogPaper: {
    '& .MuiDialog-paper': {
      margin: 0
    },
    '& .MuiDialog-scrollPaper': {
      justifyContent: 'flex-end !important'
    },
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100vh !important'

    }
  }
}));

export default useDialogStyles;
