export function getPaginatedMap<T>(listToPaginate: T[], itemsPerPage: number): Map<number, T[]> {
  const paginationMap: Map<number, T[]> = new Map();
  let page = 1;
  listToPaginate.forEach((value: T, index: number) => {
    if (index >= page * itemsPerPage) {
      page++;
    }
    if (!paginationMap.has(page)) {
      paginationMap.set(page, []);
    }
    (paginationMap.get(page) as T[]).push(value);
  });
  return paginationMap;
}
