export const CURRENT_ENV: string = 'DEMO';
export const apiMapping = {
  "createAgencyMandate": {
    "functionName": "createAgencyMandate",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/mandate/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneAgency": {
    "functionName": "createOneAgency",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgenciesByContractType": {
    "functionName": "getAgenciesByContractType",
    "path": "https://demo.apistayopen.com/admin/agencies/:contractType",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAgencyMandate": {
    "functionName": "getAgencyMandate",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/mandate/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAgencyPaymentBankAccountAlias": {
    "functionName": "getAgencyPaymentBankAccountAlias",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/external-bank-wire",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgencies": {
    "functionName": "getAllAgencies",
    "path": "https://demo.apistayopen.com/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesByCompanyId": {
    "functionName": "getAllAgenciesByCompanyId",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesOfAgencyAdmin": {
    "functionName": "getAllAgenciesOfAgencyAdmin",
    "path": "https://demo.apistayopen.com/companies/:companyId/agency-admin/:agencyAdminId/agencies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllAgenciesPerPage": {
    "functionName": "getAllAgenciesPerPage",
    "path": "https://demo.apistayopen.com/agencies/paginate",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingAllAgencies": {
    "functionName": "getExportingAllAgencies",
    "path": "https://demo.apistayopen.com/agencies/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneAgency": {
    "functionName": "getOneAgency",
    "path": "https://demo.apistayopen.com/agencies/:agencyId",
    "verb": "GET",
    "authType": "JWT"
  },
  "updateAgencyContract": {
    "functionName": "updateAgencyContract",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/contract",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAgencyPayment": {
    "functionName": "updateAgencyPayment",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/payment",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneAgency": {
    "functionName": "updateOneAgency",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAgencyRestitution": {
    "functionName": "updateAgencyRestitution",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/restitution",
    "verb": "POST",
    "authType": "JWT"
  },
  "createAgencyAdmin": {
    "functionName": "createAgencyAdmin",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "deactivateAgencyAdmin": {
    "functionName": "deactivateAgencyAdmin",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/agency-admin/:userUid/deactivate",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllAgencyAdmins": {
    "functionName": "getAllAgencyAdmins",
    "path": "https://demo.apistayopen.com/companies/:companyId/agencies/:agencyId/agency-admin/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getConnectedAgencyAdmin": {
    "functionName": "getConnectedAgencyAdmin",
    "path": "https://demo.apistayopen.com/connected/agency-admin",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneAgencyAdminByEmail": {
    "functionName": "getOneAgencyAdminByEmail",
    "path": "https://demo.apistayopen.com/agency-admin/get-by-email",
    "verb": "POST",
    "authType": "JWT"
  },
  "changePasswordForAgencyAdmin": {
    "functionName": "changePasswordForAgencyAdmin",
    "path": "https://demo.apistayopen.com/auth/agency-admin/change-password",
    "verb": "POST",
    "authType": "NONE"
  },
  "changePasswordForBeneficiary": {
    "functionName": "changePasswordForBeneficiary",
    "path": "https://demo.apistayopen.com/auth/beneficiary/change-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "changePasswordForSuperAdmin": {
    "functionName": "changePasswordForSuperAdmin",
    "path": "https://demo.apistayopen.com/auth/super-admin/change-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "checkFirstLoggedInForBeneficiary": {
    "functionName": "checkFirstLoggedInForBeneficiary",
    "path": "https://demo.apistayopen.com/auth/beneficiary/check-first-logged-in",
    "verb": "POST",
    "authType": "NONE"
  },
  "createPinForBeneficiary": {
    "functionName": "createPinForBeneficiary",
    "path": "https://demo.apistayopen.com/auth/beneficiary/create-pin",
    "verb": "POST",
    "authType": "NONE"
  },
  "getImportedStatusForAgencyAdmin": {
    "functionName": "getImportedStatusForAgencyAdmin",
    "path": "https://demo.apistayopen.com/agency-admin/imported-status",
    "verb": "POST",
    "authType": "NONE"
  },
  "getImportedStatusForBeneficiary": {
    "functionName": "getImportedStatusForBeneficiary",
    "path": "https://demo.apistayopen.com/auth/beneficiary/imported-status",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForAgencyAdmin": {
    "functionName": "loginForAgencyAdmin",
    "path": "https://demo.apistayopen.com/agency-admin/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForBeneficiary": {
    "functionName": "loginForBeneficiary",
    "path": "https://demo.apistayopen.com/beneficiary/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForBeneficiaryWithPin": {
    "functionName": "loginForBeneficiaryWithPin",
    "path": "https://demo.apistayopen.com/auth/beneficiary/login-with-pin",
    "verb": "POST",
    "authType": "NONE"
  },
  "loginForSuperAdmin": {
    "functionName": "loginForSuperAdmin",
    "path": "https://demo.apistayopen.com/super-admin/login",
    "verb": "POST",
    "authType": "NONE"
  },
  "refreshTokenForAgencyAdmin": {
    "functionName": "refreshTokenForAgencyAdmin",
    "path": "https://demo.apistayopen.com/auth/agency-admin/refresh-token",
    "verb": "POST",
    "authType": "NONE"
  },
  "refreshTokenForBeneficiary": {
    "functionName": "refreshTokenForBeneficiary",
    "path": "https://demo.apistayopen.com/auth/beneficiary/refresh-token",
    "verb": "POST",
    "authType": "NONE"
  },
  "refreshTokenForSuperAdmin": {
    "functionName": "refreshTokenForSuperAdmin",
    "path": "https://demo.apistayopen.com/auth/super-admin/refresh-token",
    "verb": "POST",
    "authType": "NONE"
  },
  "regeneratePasswordForOpenAdmin": {
    "functionName": "regeneratePasswordForOpenAdmin",
    "path": "https://demo.apistayopen.com/users/:userId/regenerate-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "requestPasswordChangeForAgencyAdmin": {
    "functionName": "requestPasswordChangeForAgencyAdmin",
    "path": "https://demo.apistayopen.com/agency-admin/forgot-password",
    "verb": "POST",
    "authType": "NONE"
  },
  "validateHashForAgencyAdmin": {
    "functionName": "validateHashForAgencyAdmin",
    "path": "https://demo.apistayopen.com/auth/agency-admin/validate-hash",
    "verb": "POST",
    "authType": "NONE"
  },
  "createOneCompany": {
    "functionName": "createOneCompany",
    "path": "https://demo.apistayopen.com/companies/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllCompanies": {
    "functionName": "getAllCompanies",
    "path": "https://demo.apistayopen.com/companies",
    "verb": "GET",
    "authType": "JWT"
  },
  "getAllCompaniesPaginated": {
    "functionName": "getAllCompaniesPaginated",
    "path": "https://demo.apistayopen.com/companies/paginated",
    "verb": "POST",
    "authType": "JWT"
  },
  "getOneCompany": {
    "functionName": "getOneCompany",
    "path": "https://demo.apistayopen.com/companies/:companyId",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneCompanyByName": {
    "functionName": "getOneCompanyByName",
    "path": "https://demo.apistayopen.com/companies/company-name",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneCompany": {
    "functionName": "updateOneCompany",
    "path": "https://demo.apistayopen.com/companies/:companyId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBouncesPerPage": {
    "functionName": "getAllBouncesPerPage",
    "path": "https://demo.apistayopen.com/email-event/bounces",
    "verb": "POST",
    "authType": "JWT"
  },
  "resendInvitationEmailAndUpdateBounceSendStatus": {
    "functionName": "resendInvitationEmailAndUpdateBounceSendStatus",
    "path": "https://demo.apistayopen.com/email-event/bounces/:emailEventId/resend-and-update",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendEmail": {
    "functionName": "sendEmail",
    "path": "https://demo.apistayopen.com/email/send",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendInvitationEmailToBeneficiary": {
    "functionName": "sendInvitationEmailToBeneficiary",
    "path": "https://demo.apistayopen.com/email/beneficiary/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendMailsToUnOnboardedBeneficiaries": {
    "functionName": "sendMailsToUnOnboardedBeneficiaries",
    "path": "https://demo.apistayopen.com/email/unonboarded/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendResetPasswordEmailToAgencyAdmin": {
    "functionName": "sendResetPasswordEmailToAgencyAdmin",
    "path": "https://demo.apistayopen.com/email/agency-admin/reset-password",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendResyncBridgeCredentialsEmail": {
    "functionName": "sendResyncBridgeCredentialsEmail",
    "path": "https://demo.apistayopen.com/email/resync/invitation",
    "verb": "POST",
    "authType": "JWT"
  },
  "acceptBeneficiaryCgu": {
    "functionName": "acceptBeneficiaryCgu",
    "path": "https://demo.apistayopen.com/beneficiary/:beneficiaryId/accept-cgu",
    "verb": "POST",
    "authType": "JWT"
  },
  "createBeneficiaryPan": {
    "functionName": "createBeneficiaryPan",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/pans/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOneBeneficiary": {
    "functionName": "createOneBeneficiary",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "manageBeneficiaryDeactivationSchedule": {
    "functionName": "manageBeneficiaryDeactivationSchedule",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/manageDeactivationSchedule",
    "verb": "POST",
    "authType": "JWT"
  },
  "deleteBeneficiaryPan": {
    "functionName": "deleteBeneficiaryPan",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/pans/:panId/delete",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBeneficiaries": {
    "functionName": "getAllBeneficiaries",
    "path": "https://demo.apistayopen.com/beneficiaries",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllBeneficiariesByAgencyId": {
    "functionName": "getAllBeneficiariesByAgencyId",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiariesByFilters": {
    "functionName": "getBeneficiariesByFilters",
    "path": "https://demo.apistayopen.com/beneficiaries/search",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiariesCountByAgencyId": {
    "functionName": "getBeneficiariesCountByAgencyId",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/count",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryAppAvailability": {
    "functionName": "getBeneficiaryAppAvailability",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/beneficiaryAppAvailability",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryBalances": {
    "functionName": "getBeneficiaryBalances",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/balances",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryPans": {
    "functionName": "getBeneficiaryPans",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/pans/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingAllBeneficiaries": {
    "functionName": "getExportingAllBeneficiaries",
    "path": "https://demo.apistayopen.com/beneficiaries/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingBeneficiaryBalances": {
    "functionName": "getExportingBeneficiaryBalances",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/balances/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getExportingBeneficiaryDetail": {
    "functionName": "getExportingBeneficiaryDetail",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/details/export",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiariesOpenBankingKO": {
    "functionName": "getBeneficiariesOpenBankingKO",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/open-banking-ko",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFullBeneficiaryByIdForAdmin": {
    "functionName": "getFullBeneficiaryByIdForAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/full",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFullBeneficiaryEmail": {
    "functionName": "getFullBeneficiaryEmail",
    "path": "https://demo.apistayopen.com/beneficiaries/full",
    "verb": "POST",
    "authType": "JWT"
  },
  "getLatestBeneficiaryBalance": {
    "functionName": "getLatestBeneficiaryBalance",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/balances/latest",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneBeneficiaryByIdForAdmin": {
    "functionName": "getOneBeneficiaryByIdForAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "changeBeneficiaryMode": {
    "functionName": "changeBeneficiaryMode",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/change-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetBeneficiaryMode": {
    "functionName": "resetBeneficiaryMode",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/reset-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetBeneficiaryOpenBankingSync": {
    "functionName": "resetBeneficiaryOpenBankingSync",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/reset-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryActivity": {
    "functionName": "updateBeneficiaryActivity",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/activity",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryRestrictedSettings": {
    "functionName": "updateBeneficiaryRestrictedSettings",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/update-restricted-settings",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryEmailForAgency": {
    "functionName": "updateBeneficiaryEmailForAgency",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/email/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryIbanForAgency": {
    "functionName": "updateBeneficiaryIbanForAgency",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/iban/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryMode": {
    "functionName": "updateBeneficiaryMode",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/update-mode",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryPan": {
    "functionName": "updateBeneficiaryPan",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/pans/:panId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateBeneficiaryRef": {
    "functionName": "updateBeneficiaryRef",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/ref",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateIbanBeneficiary": {
    "functionName": "updateIbanBeneficiary",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/iban",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateOneBeneficiary": {
    "functionName": "updateOneBeneficiary",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "uploadBeneficiaries": {
    "functionName": "uploadBeneficiaries",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/upload",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyInvoices": {
    "functionName": "getAgencyInvoices",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/invoices",
    "verb": "POST",
    "authType": "JWT"
  },
  "getInvoiceBeneficiariesByDate": {
    "functionName": "getInvoiceBeneficiariesByDate",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/invoices/beneficiaries",
    "verb": "POST",
    "authType": "JWT"
  },
  "cleanDatabases": {
    "functionName": "cleanDatabases",
    "path": "https://demo.apistayopen.com/clean-databases",
    "verb": "POST",
    "authType": "JWT"
  },
  "cleanUpInvoiceBucket": {
    "functionName": "cleanUpInvoiceBucket",
    "path": "https://demo.apistayopen.com/cleanup-invoice-bucket",
    "verb": "POST",
    "authType": "JWT"
  },
  "getFeatureFlags": {
    "functionName": "getFeatureFlags",
    "path": "https://demo.apistayopen.com/config/feature-flags",
    "verb": "POST",
    "authType": "NONE"
  },
  "logMobile": {
    "functionName": "logMobile",
    "path": "https://demo.apistayopen.com/maintenance/logMobile",
    "verb": "POST",
    "authType": "NONE"
  },
  "maintenance": {
    "functionName": "maintenance",
    "path": "https://demo.apistayopen.com/maintenance",
    "verb": "POST",
    "authType": "JWT"
  },
  "manageBuckets": {
    "functionName": "manageBuckets",
    "path": "https://demo.apistayopen.com/manage-buckets",
    "verb": "POST",
    "authType": "JWT"
  },
  "test": {
    "functionName": "test",
    "path": "https://demo.apistayopen.com/test",
    "verb": "POST",
    "authType": "NONE"
  },
  "addAllEmailContactsByRechargingYear": {
    "functionName": "addAllEmailContactsByRechargingYear",
    "path": "https://demo.apistayopen.com/millesime/emails/contacts/add",
    "verb": "POST",
    "authType": "JWT"
  },
  "addEmailContactsForOneAgency": {
    "functionName": "addEmailContactsForOneAgency",
    "path": "https://demo.apistayopen.com/millesime/emails/contacts/agency/add",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgenciesByRechargingYear": {
    "functionName": "getAgenciesByRechargingYear",
    "path": "https://demo.apistayopen.com/millesime/agencies",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingAgencyUsageReport": {
    "functionName": "getExportingAgencyUsageReport",
    "path": "https://demo.apistayopen.com/millesime/agencies/:agencyId/usage-reports/export",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingEndOfMillesimeExecutionReport": {
    "functionName": "getExportingEndOfMillesimeExecutionReport",
    "path": "https://demo.apistayopen.com/millesime/execution-report/export",
    "verb": "POST",
    "authType": "JWT"
  },
  "getMillesimeMetadata": {
    "functionName": "getMillesimeMetadata",
    "path": "https://demo.apistayopen.com/millesime/metadata",
    "verb": "POST",
    "authType": "JWT"
  },
  "initMillesimeMetadata": {
    "functionName": "initMillesimeMetadata",
    "path": "https://demo.apistayopen.com/millesime/init",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendMillesimeEmailToBeneficiaries": {
    "functionName": "sendMillesimeEmailToBeneficiaries",
    "path": "https://demo.apistayopen.com/millesime/emails",
    "verb": "POST",
    "authType": "JWT"
  },
  "setAgencyRightsReconductionType": {
    "functionName": "setAgencyRightsReconductionType",
    "path": "https://demo.apistayopen.com/millesime/agencies/:agencyId/rights-reconduction",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateAllEmailContactsByRechargingYear": {
    "functionName": "updateAllEmailContactsByRechargingYear",
    "path": "https://demo.apistayopen.com/millesime/emails/contacts/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateEmailContactsForOneAgency": {
    "functionName": "updateEmailContactsForOneAgency",
    "path": "https://demo.apistayopen.com/millesime/emails/contacts/agency/update",
    "verb": "POST",
    "authType": "JWT"
  },
  "addSyncedBankAccounts": {
    "functionName": "addSyncedBankAccounts",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/add-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "forceToHandleLastPaybacks": {
    "functionName": "forceToHandleLastPaybacks",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/force-to-handle",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAvailableBankAccounts": {
    "functionName": "getAvailableBankAccounts",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts",
    "verb": "GET",
    "authType": "JWT"
  },
  "getSyncedBankAccountStatus": {
    "functionName": "getSyncedBankAccountStatus",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/sync-status",
    "verb": "GET",
    "authType": "JWT"
  },
  "selectBankAccount": {
    "functionName": "selectBankAccount",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/:bankAccountId/select",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateSyncedBankAccounts": {
    "functionName": "updateSyncedBankAccounts",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/bank-accounts/update-sync",
    "verb": "POST",
    "authType": "JWT"
  },
  "createAgencyOrder": {
    "functionName": "createAgencyOrder",
    "path": "https://demo.apistayopen.com/agencies/orders/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyOrderProvision": {
    "functionName": "getAgencyOrderProvision",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/orders/:orderId/provisions",
    "verb": "GET",
    "authType": "JWT"
  },
  "getBeneficiaryProvisions": {
    "functionName": "getBeneficiaryProvisions",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/beneficiaries/:beneficiaryId/provisions",
    "verb": "POST",
    "authType": "JWT"
  },
  "createPaybackForScanCB": {
    "functionName": "createPaybackForScanCB",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/create-scan-cb",
    "verb": "POST",
    "authType": "JWT"
  },
  "createPaybackTransaction": {
    "functionName": "createPaybackTransaction",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/create-transaction",
    "verb": "POST",
    "authType": "JWT"
  },
  "forcePaymentForRejectedBusinessPaybackByAdmin": {
    "functionName": "forcePaymentForRejectedBusinessPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/force-payment",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaybacksCount": {
    "functionName": "getBeneficiaryPaybacksCount",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryPaybacksForAdmin": {
    "functionName": "getBeneficiaryPaybacksForAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks",
    "verb": "POST",
    "authType": "JWT"
  },
  "getBeneficiaryUncategorizedTransactions": {
    "functionName": "getBeneficiaryUncategorizedTransactions",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/transactions/uncategorized",
    "verb": "POST",
    "authType": "JWT"
  },
  "getExportingPaybacksForAdmin": {
    "functionName": "getExportingPaybacksForAdmin",
    "path": "https://demo.apistayopen.com/admin/paybacks/export",
    "verb": "POST",
    "authType": "JWT"
  },
  "getNewPaybacksCountForMobile": {
    "functionName": "getNewPaybacksCountForMobile",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/new-count",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOnePaybackForAdmin": {
    "functionName": "getOnePaybackForAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/get",
    "verb": "GET",
    "authType": "JWT"
  },
  "getPaginatedPaybacksForAdmin": {
    "functionName": "getPaginatedPaybacksForAdmin",
    "path": "https://demo.apistayopen.com/admin/paybacks/paginated",
    "verb": "POST",
    "authType": "JWT"
  },
  "getPaybacksCountForAdmin": {
    "functionName": "getPaybacksCountForAdmin",
    "path": "https://demo.apistayopen.com/admin/paybacks/count",
    "verb": "POST",
    "authType": "JWT"
  },
  "getPaybacksForMobile": {
    "functionName": "getPaybacksForMobile",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/paybacks",
    "verb": "GET",
    "authType": "JWT"
  },
  "moveForwardRejectedPaybackByAdmin": {
    "functionName": "moveForwardRejectedPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/move-forward",
    "verb": "POST",
    "authType": "JWT"
  },
  "processBeneficiaryPayoutsByAdmin": {
    "functionName": "processBeneficiaryPayoutsByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/process-payouts",
    "verb": "POST",
    "authType": "JWT"
  },
  "rejectPendingBusinessPaybackByAdmin": {
    "functionName": "rejectPendingBusinessPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/reject-business",
    "verb": "POST",
    "authType": "JWT"
  },
  "rejectPendingOcrPaybackByAdmin": {
    "functionName": "rejectPendingOcrPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/reject-ocr",
    "verb": "POST",
    "authType": "JWT"
  },
  "resetPaybacksCountForMobile": {
    "functionName": "resetPaybacksCountForMobile",
    "path": "https://demo.apistayopen.com/beneficiaries/:beneficiaryId/paybacks/reset-count",
    "verb": "POST",
    "authType": "JWT"
  },
  "resolvePendingBusinessPaybackByAdmin": {
    "functionName": "resolvePendingBusinessPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/resolve-business",
    "verb": "POST",
    "authType": "JWT"
  },
  "resolvePendingOcrPaybackByAdmin": {
    "functionName": "resolvePendingOcrPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/resolve-ocr",
    "verb": "POST",
    "authType": "JWT"
  },
  "rollbackRejectedPaybackByAdmin": {
    "functionName": "rollbackRejectedPaybackByAdmin",
    "path": "https://demo.apistayopen.com/admin/beneficiaries/:beneficiaryId/paybacks/:paybackId/rollback",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPayinReports": {
    "functionName": "getAgencyPayinReports",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/payins/reports",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAgencyPayins": {
    "functionName": "getAgencyPayins",
    "path": "https://demo.apistayopen.com/agencies/:agencyId/payins",
    "verb": "GET",
    "authType": "JWT"
  },
  "getPayinsByorderId": {
    "functionName": "getPayinsByorderId",
    "path": "https://demo.apistayopen.com/orders/:orderId/payins",
    "verb": "GET",
    "authType": "JWT"
  },
  "addBeneficiaryPushToken": {
    "functionName": "addBeneficiaryPushToken",
    "path": "https://demo.apistayopen.com/notifications/beneficiaries/:beneficiaryId/add-token",
    "verb": "POST",
    "authType": "JWT"
  },
  "sendPushNotificationToBeneficiary": {
    "functionName": "sendPushNotificationToBeneficiary",
    "path": "https://demo.apistayopen.com/beneficiaries/push-notification",
    "verb": "POST",
    "authType": "JWT"
  },
  "createMaskPaymentDescriptor": {
    "functionName": "createMaskPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/:agencyUid/:listType/create",
    "verb": "POST",
    "authType": "JWT"
  },
  "deleteMaskPaymentDescriptor": {
    "functionName": "deleteMaskPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/delete-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "getCountPaymentDescriptor": {
    "functionName": "getCountPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/:agencyUid/count",
    "verb": "GET",
    "authType": "JWT"
  },
  "getOneMaskPaymentDescriptor": {
    "functionName": "getOneMaskPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/:agencyUid/:listType/get-one-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "getListPaymentDescriptor": {
    "functionName": "getListPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/:agencyUid/:listType/list",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateMaskPaymentDescriptor": {
    "functionName": "updateMaskPaymentDescriptor",
    "path": "https://demo.apistayopen.com/admin/referential-actions/:agencyUid/:listType/update-mask",
    "verb": "POST",
    "authType": "JWT"
  },
  "createOpenAdmin": {
    "functionName": "createOpenAdmin",
    "path": "https://demo.apistayopen.com/users/create-open-admin",
    "verb": "POST",
    "authType": "JWT"
  },
  "editUserRole": {
    "functionName": "editUserRole",
    "path": "https://demo.apistayopen.com/users/edit-role",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllUsers": {
    "functionName": "getAllUsers",
    "path": "https://demo.apistayopen.com/users/all",
    "verb": "POST",
    "authType": "JWT"
  },
  "getUserById": {
    "functionName": "getUserById",
    "path": "https://demo.apistayopen.com/users/:userId/get-one",
    "verb": "GET",
    "authType": "JWT"
  },
  "getUserByToken": {
    "functionName": "getUserByToken",
    "path": "https://demo.apistayopen.com/users/get-by-token",
    "verb": "POST",
    "authType": "JWT"
  },
  "updateUserEmail": {
    "functionName": "updateUserEmail",
    "path": "https://demo.apistayopen.com/users/:userId/update-email",
    "verb": "POST",
    "authType": "JWT"
  },
  "getAllVat": {
    "functionName": "getAllVat",
    "path": "https://demo.apistayopen.com/vat/rates",
    "verb": "GET",
    "authType": "JWT"
  },
  "isSundayOrPublicHoliday": {
    "functionName": "isSundayOrPublicHoliday",
    "path": "https://demo.apistayopen.com/calendar/is-sunday-or-holiday",
    "verb": "POST",
    "authType": "NONE"
  },
  "getCountryFlags": {
    "functionName": "getCountryFlags",
    "path": "https://demo.apistayopen.com/country-flags",
    "verb": "GET",
    "authType": "NONE"
  },
  "processBeneficiaryDeactivation": {
    "functionName": "processBeneficiaryDeactivation",
    "path": "https://demo.apistayopen.com/tasks/processBeneficiaryDeactivation",
    "verb": "POST",
    "authType": "NONE"
  }
} as const;