import { Button, MenuItem, Select } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import calendarIcon from '../../assets/calendar.svg';
import DownArrow from '../../assets/down-arrow';
import forkAndSpoonIcon from '../../assets/fork-spoon.svg';
import graphIcon from '../../assets/graphTransparent.svg';
import RechargingOrderExistDialog from '../../Component/Dialog/RechargingOrderExistDialog/RechargingOrderExistDialog';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { InputSelectBgWhite } from '../../Style/MuiStyles/Select/Select';
import { COLORS } from '../../Style/Style';
import { getAgencyParticipationPercent } from '../../Utils/AgencyContractValues.service';
import { useOrderParamsStyles } from './Styles';

type LocalStore = [AgenciesStore, RechargingViewStore, StepperRechargingStore, AgencyInvoiceStore, OrderPaymentViewStore];
type LocalStyles =
  'wrapper'
  | 'block'
  | 'content'
  | 'contentMonth'
  | 'unit'
  | 'description'
  | 'icon'
  | 'iconSelect'
  | 'buttons'
  | 'selectOnError'
  | 'errorMessage';

/**
 * Tab: Rechargement des droits Manuel
 * Step: Mes paramètres de commande (PARAMETERS)
 */
const OrderParametersComp: FunctionComponent = observer(() => {
  const [
    agenciesStore,
    rechargingViewStore,
    stepperRechargingStore,
    agencyInvoiceStore,
    orderPaymentViewStore,
  ]: LocalStore = useInstances<LocalStore>(AgenciesStore, RechargingViewStore, StepperRechargingStore, AgencyInvoiceStore, OrderPaymentViewStore);
  const { t } = useTranslation('reloading');

  const orderParamsStyles: ClassNameMap<LocalStyles> = useOrderParamsStyles();

  const [hasError, setHasError] = useState(false);
  const [isOrderExist, setIsOrderExist] = useState(false);

  const nextStep: () => void = useCallback(() => {
    if (rechargingViewStore.reloadingDateKey === 'unknown') {
      setHasError(true);
    } else {
      testOrderMonth();
    }
  }, [rechargingViewStore.reloadingDateKey]);

  const selectMonth: (evt: React.ChangeEvent<HTMLInputElement>) => void = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    rechargingViewStore.setReloadingDateKey(evt.target.value);
    sessionStorage.setItem('reloadingDateKey', evt.target.value);
    orderPaymentViewStore.setOrderDetails({
      ...orderPaymentViewStore.orderDetails,
      year: rechargingViewStore.selectedReloadingDate.getFullYear(),
      month: rechargingViewStore.selectedReloadingDate.getMonth() + 1,
    });
    setHasError(false);
  }, []);

  const testOrderMonth: () => void = useCallback(() => {
    const test: boolean = agencyInvoiceStore.invoices.some((invoice) => {
      const date: Date = new Date(invoice.invoiceDate);
      return date.getMonth() === rechargingViewStore.selectedReloadingDate.getMonth()
        && date.getFullYear() === rechargingViewStore.selectedReloadingDate.getFullYear();
    });
    if (!test) {
      return stepperRechargingStore.goToNextStep();
    }
    setIsOrderExist(test);
  }, [rechargingViewStore.selectedReloadingDate, agencyInvoiceStore.invoices]);

  useEffect(() => {
    rechargingViewStore.setInitialData();
  }, []);

  useEffect(() => {
    const registeredReloadingDateKey: string | null = sessionStorage.getItem('reloadingDateKey');

    if (registeredReloadingDateKey) {
      rechargingViewStore.setReloadingDateKey(registeredReloadingDateKey);
    }
  }, []);

  return (
    <>
      <div className={orderParamsStyles.wrapper}>

        <div className={orderParamsStyles.content}>
          <div className={orderParamsStyles.block}>
            <div>
              <div className={orderParamsStyles.icon}>
                <img width={60} alt={'Chargement de fichier'} src={forkAndSpoonIcon}/>
              </div>
              <span className={orderParamsStyles.unit}>
                {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' })
                  .format(agenciesStore.currentAgency?.contractFacialValue)}
              </span>
            </div>
            <div className={orderParamsStyles.description}>
              {t('dailyAmount')}
            </div>
          </div>

          <div className={orderParamsStyles.block}>
            <div>
              <div className={orderParamsStyles.icon}>
                <img width={50} alt={'Chargement de fichier'} src={graphIcon}/>
              </div>
              <span className={orderParamsStyles.unit}>
                {new Intl.NumberFormat('fr-FR', {
                  style: 'currency', currency: 'EUR',
                }).format(Number(agenciesStore.currentAgency.contractAgencyPart))}
              </span>
            </div>
            <div className={orderParamsStyles.description}>
              {t('contribution', { companyQuota: getAgencyParticipationPercent(agenciesStore.currentAgency).toFixed(2) })}
            </div>
          </div>
        </div>

        <div className={orderParamsStyles.contentMonth}>
          <div className={orderParamsStyles.iconSelect}>
            <img width={50} alt={'Chargement de fichier'} src={calendarIcon}/>
          </div>
          <div style={{ flex: 1, position: 'relative' }}>
            <Select
              value={rechargingViewStore.reloadingDateKey}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => selectMonth(evt)}
              labelId="label"
              id="select"
              className={hasError && orderParamsStyles.selectOnError || ''}
              input={<InputSelectBgWhite title={t('reloadingDate')} required/>}
              IconComponent={(props) => <div
                style={{ marginTop: '0px' }}>{DownArrow({ svgColor: COLORS.textPrimary, ...props })}</div>}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {Object.entries(rechargingViewStore.reloadingDate).map(([dateKey, date], index) => {
                return (<MenuItem value={dateKey} key={index}>
                  {dateKey === 'unknown'
                    ? 'Selectionnez un mois'
                    : moment(date).format('MMMM YYYY')
                  }
                </MenuItem>);
              })}
            </Select>
            {hasError && <span className={orderParamsStyles.errorMessage}>La saisie d’un mois est nécessaire</span>}
          </div>

        </div>
      </div>
      <div className={orderParamsStyles.buttons}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => stepperRechargingStore.goToPreviousStep()}
          type="button">
          {t('cancel')}
        </Button>
        <Button
          style={{ marginLeft: '2rem' }}
          variant="contained"
          color="primary"
          onClick={nextStep}
          type="button">
          {t('nextStep')}
        </Button>
      </div>
      <RechargingOrderExistDialog
        isActive={isOrderExist}
        onClose={() => setIsOrderExist(false)}
        takeNewOrder={() => stepperRechargingStore.goToNextStep()}
        orderMonth={rechargingViewStore.reloadingDate[rechargingViewStore.reloadingDateKey]}
      />
    </>
  );
});

export default OrderParametersComp;
