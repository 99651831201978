import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StyledDataGrid from '../../../Style/MuiStyles/Datagrid';
import { GridCellParams, GridColDef, GridValueGetterParams } from '@material-ui/data-grid';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import Downarrow from '../../../assets/down-arrow';
import InputComponent from '../../../Component/InputComponent';
import magnifyingGlass from '../../../assets/magnifying-glass.svg';
import moment from 'moment';
import { useBeneficiaryProvisionsComponentStyles } from './BeneficiaryProvisionsStyles';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { GetBeneficiaryProvisionResponse } from '../../../assets/responses/orders/GetBeneficiaryProvisions.response';
import { ProvisionStore } from '../../../Stores/Provision.store';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { OptionalTimePeriodRequest } from '../../../assets/requests/TimePeriod.request';

export interface IBeneficiaryProvisionsComponentProps {
  beneficiary?: BeneficiaryModel;
  onClose: () => void;
}

const BeneficiaryProvisionsComponent: FunctionComponent<IBeneficiaryProvisionsComponentProps> = observer((props: IBeneficiaryProvisionsComponentProps) => {
  const provisionStore: ProvisionStore = useInstance(ProvisionStore);
  const isFetching = provisionStore.isLoading;

  const [data, setData] = useState<GetBeneficiaryProvisionResponse[]>([]);
  const [filteredData, setFilteredData] = useState<GetBeneficiaryProvisionResponse[]>([]);

  const beneficiaryPaybackClasses = useBeneficiaryProvisionsComponentStyles(props);
  const { t } = useTranslation('beneficiaries');

  const [search, setSearch] = useState('');
  const [sinceDate, setSinceDate] = React.useState('0');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const dateOptions = { year: 'numeric', month: 'long' } as const;

  const applySearch = (newSearch: string, beneficiaryProvisions: GetBeneficiaryProvisionResponse[]) => {
    setSearch(newSearch);

    const filterData: GetBeneficiaryProvisionResponse[] = beneficiaryProvisions.filter((provision) => {
      const transactionDate: Date = new Date(provision.creationDate);

      return (
        (provision.creationDate && transactionDate.toLocaleDateString().search(newSearch) !== -1)
        || provision.beneficiaryCredit.numberOfRechargingDays.toLocaleString().search(newSearch) !== -1
        || (provision.beneficiaryCredit.numberOfRechargingDays * provision.invoice.contractAgencyPart).toLocaleString().search(newSearch) !== -1
      );
    });

    const beneficiaryProvisionSortByDateDesc: GetBeneficiaryProvisionResponse[] =
      filterData.sort((provision_a: GetBeneficiaryProvisionResponse, provision_b: GetBeneficiaryProvisionResponse) => provision_b.invoice.creationDate - provision_a.invoice.creationDate);

    setFilteredData(beneficiaryProvisionSortByDateDesc);
  };

  async function getProvisions(fromDate: number) {
    const request: OptionalTimePeriodRequest = {
      from: fromDate,
    };

    await provisionStore.getBeneficiaryProvisions(props.beneficiary.agencyId, props.beneficiary.uid, request);

    if (!provisionStore.isLoading && provisionStore.getBeneficiaryProvisionsStatus === 'SUCCESS') {
      setData(provisionStore.beneficiaryProvisions);
      applySearch(search, provisionStore.beneficiaryProvisions);
    }
  }

  useEffect(() => {
    const currentDate: Date = new Date();
    const fromDate = currentDate.setMonth(currentDate.getMonth() - 3);

    getProvisions(fromDate);
  }, [props.beneficiary]);

  const dataGridColumns: GridColDef[] = [
    {
      field: 'invoiceDate',
      headerName: 'Période',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.invoice.invoiceDate,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{new Date(this.valueGetter(params)).toLocaleDateString('fr-FR', dateOptions)} </div>
        );
      },
    },
    {
      field: 'creationDate',
      headerName: 'Date de rechargement',
      flex: 1.1,
      valueGetter: (params: GridValueGetterParams) => params.row.invoice.creationDate,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{moment(new Date(this.valueGetter(params))).format('DD/MM/YYYY')} </div>
        );
      },
    },
    {
      field: 'nbDays',
      headerName: 'Détail',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => `${t('day', { count: params.row.beneficiaryCredit.numberOfRechargingDays })} x ${currencyFormat.format(params.row.invoice.contractAgencyPart)}`,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{this.valueGetter(params)} </div>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Montant rechargé',
      flex: 1.1,
      valueGetter: (params: GridValueGetterParams) => params.row.beneficiaryCredit.numberOfRechargingDays * params.row.invoice.contractAgencyPart,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div
            style={{ width: '100%' }}>{currencyFormat.format(this.valueGetter(params))} </div>
        );
      },
    },
  ];

  return (
    <>
      <p className={beneficiaryPaybackClasses.title}>
        ENSEMBLE DES DROITS ATTRIBUÉS SUR LA PÉRIODE
      </p>
      <div className={beneficiaryPaybackClasses.block}>
        <Select
          value={sinceDate}
          onChange={(evt) => {
            const currentDate: Date = new Date();
            let from: number;
            switch (evt.target.value) {
              case '1':
                from = currentDate.setMonth(currentDate.getMonth() - 6);
                break;
              case '2':
                from = currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
              case '0':
              default:
                from = currentDate.setMonth(currentDate.getMonth() - 3);
            }

            setSinceDate(evt.target.value as string);
            getProvisions(from);
          }}
          labelId="label"
          id="select"
          input={<StyledSelect/>}
          IconComponent={(props) => Downarrow({
            svgColor: '#FFFFFF', ...props, className: beneficiaryPaybackClasses.icon,
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="0">{t('since3months')}</MenuItem>
          <MenuItem value="1">{t('since6months')}</MenuItem>
          <MenuItem value="2">{t('since1year')}</MenuItem>
        </Select>
        <div className={beneficiaryPaybackClasses.search}>
          <InputComponent
            placeholder={t('paybackSearchPlaceholder')}
            icon={magnifyingGlass}
            onChange={(search) => applySearch(search, data)}
            value={search}
            iconLeft
          />
        </div>
      </div>
      <StyledDataGrid
        idKey="orderUid"
        rows={filteredData}
        columns={dataGridColumns}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        disableSelectionOnClick
        loading={isFetching}
      />
      <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '40px' }}>
        <Button
          onClick={props.onClose}
          type="button"
          variant="contained"
          color="primary">
          {t('close')}
        </Button>
      </div>
    </>
  );
});

export default BeneficiaryProvisionsComponent;
