import React, { FunctionComponent, useEffect } from 'react';
import { PageBlock } from '../../Style/Style';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import StyledDataGrid from '../../Style/MuiStyles/Datagrid';
import { Button, CircularProgress } from '@material-ui/core';
import { useOrderListBeneficiariesStyles } from './Styles';
import { observer } from 'mobx-react-lite';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { useInstances } from 'react-ioc';
import { toJS } from 'mobx';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import { isBeneficiaryActive } from '../../assets/utils/functions/isBeneficiaryActive.util';

type LocalStore = [RechargingViewStore, StepperRechargingStore, BeneficiariesStore];

/**
 * Tab: Rechargement des droits Manuel
 * Step: Mes collaborateurs (LIST_BENEFICIARIES)
 */
const OrderListBeneficiariesComponent: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
  ]: [
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore,
  ] = useInstances<LocalStore>(RechargingViewStore, StepperRechargingStore, BeneficiariesStore);

  const { selectedReloadingDate } = rechargingViewStore;
  const activeBeneficiariesCouldBeRecharged: BeneficiaryModel[] = beneficiariesStore.getActiveBeneficiariesAtDate(selectedReloadingDate);

  const { t } = useTranslation('reloading');
  const orderListBeneficiariesStyles: ClassNameMap<'input'> = useOrderListBeneficiariesStyles();

  useEffect(() => {
    beneficiariesStore.setBeneficiariesToRecharge(activeBeneficiariesCouldBeRecharged);
  }, [activeBeneficiariesCouldBeRecharged]);

  const listBeneficiariesGrid: GridColDef[] = [
    {
      field: 'firstname',
      headerName: 'Collaborateur',
      flex: 2,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{`${params.row.lastName} ${params.row.firstName}`} </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Adresse Email',
      flex: 2,
    },
    {
      field: 'ibanLastNumbers',
      headerName: 'IBAN',
      flex: 1,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div>
            ***********{params.value}
          </div>
        );
      },
    },
    {
      field: 'numberOfWorkingDays',
      headerName: 'Jours travaillés',
      flex: 1,
      sortable: false,
      renderCell: function actionCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            <input
              type="number"
              className={orderListBeneficiariesStyles.input}
              pattern="[0-9]{2}"
              value={params.value as number ?? 0}
              max={31}
              min={0}
              onChange={(e) => {
                const objIndex = activeBeneficiariesCouldBeRecharged.findIndex(obj => obj.uid === params.row.uid);
                activeBeneficiariesCouldBeRecharged[objIndex].numberOfWorkingDays = Number.parseFloat(e.target.value);
                beneficiariesStore.setBeneficiariesList([...activeBeneficiariesCouldBeRecharged]);
              }}
            />

          </div>
        );
      },
    },
    {
      field: 'isActive',
      headerName: 'Actif',
      flex: 1,
      renderCell: function isActiveCell(params) {
        return (
          <div style={{
            width: 8,
            height: 8,
            borderRadius: 8,
            backgroundColor: params.value ? '#8CD867' : '#E94B32',
          }}/>
        );
      },
    },
  ];

  return (
    <PageBlock>
      {beneficiariesStore.isLoading
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '14px', marginBottom: '54px' }}>
              Renseignez ici le nombre de jours travaillés pour chacun de vos collaborateurs.
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => rechargingViewStore.setOpenBeneficiaryDetails(true)}
              type="button">
              {t('addBeneficiary')}
            </Button>
          </div>
          <StyledDataGrid
            rows={activeBeneficiariesCouldBeRecharged}
            columns={listBeneficiariesGrid}
            showColumnRightBorder={false}
            showCellRightBorder={false}
            disableSelectionOnClick
          />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '40px' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => stepperRechargingStore.goToPreviousStep()}
              type="button">
              {t('cancel')}
            </Button>
            <Button
              style={{ marginLeft: '40px' }}
              variant="contained"
              color="primary"
              disabled={activeBeneficiariesCouldBeRecharged.findIndex((d) => isNaN(d.numberOfWorkingDays)) !== -1}
              onClick={() => stepperRechargingStore.goToNextStep()}
              type="button">
              {t('nextStep')}
            </Button>
          </div>
        </>
      }
    </PageBlock>
  );
});

export default OrderListBeneficiariesComponent;
