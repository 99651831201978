export interface EnvCookiesIds {
  clientId: string;
  cookiesVersion: string;
}

interface AcceptioCookiesIds {
  DEV: EnvCookiesIds;
  DEMO: EnvCookiesIds;
  UAT: EnvCookiesIds;
  PROD: EnvCookiesIds;
}

export const axeptioCookiesIds: AcceptioCookiesIds = {
  DEV: {
    clientId: '64ca1f994271021bdbe9d769',
    cookiesVersion: 'web-employeur-dev-fr_Cp_no-choice',
  },
  DEMO:{
    clientId: '64df206137d8366e597568bd',
    cookiesVersion: 'web-employeur-demo-fr-2',
  },
  UAT: {
    clientId: '6380c1813963501b97e43dbc',
    cookiesVersion: 'site employeur open!eat uat-fr-2',
  },
  PROD: {
    clientId: '6380c0308f2b2f8e80c315ac',
    cookiesVersion: 'site employeur open!eat-fr',
  },
} as const;