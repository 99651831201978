import { Dialog } from '@material-ui/core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { PageBlock, PageSubtitle } from '../Style/Style';
import InputComponent from '../Component/InputComponent';
import magnifyingGlass from '../assets/magnifying-glass.svg';
import StyledDataGrid from '../Style/MuiStyles/Datagrid';
import { columnsBeneficiaries } from './DataGridBeneficiaries';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { useFeatureFlag } from '../Hooks/useFeatureFlag';
import { AgenciesStore } from '../Stores/Agencies.store';
import { BeneficiariesStore } from '../Stores/Beneficiaries.store';
import { BeneficiariesViewStore } from '../Stores/viewStore/BeneficiariesView.store';
import Button from '../Style/MuiStyles/Button';
import useDialogStyles from '../Style/MuiStyles/Dialog';
import AddBeneficiariesByUploadComponent from './addBeneficiariesByUpload/AddBeneficiariesByUploadComponent';
import { useBeneficiariesStyles } from './BeneficiariesStyles';
import BeneficiaryDetailsComponent from './BeneficiaryDetails/BeneficiaryDetails/BeneficiaryDetailsComponent';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { isBeneficiaryActiveToday } from '../assets/utils/functions/isBeneficiaryActive.util';

type LocalStore = [AgenciesStore, BeneficiariesViewStore, BeneficiariesStore];
type LocalStyle = 'subtitle' | 'searchBlock' | 'searchInput' | 'marginButton'

const BeneficiariesComponent: FunctionComponent = provider(
  BeneficiariesViewStore,
  BeneficiariesStore,
)(observer(() => {
  const [
    agenciesStore,
    beneficiariesViewStore,
    beneficiariesStore,
  ]: [AgenciesStore, BeneficiariesViewStore, BeneficiariesStore] = useInstances<LocalStore>(AgenciesStore, BeneficiariesViewStore, BeneficiariesStore);

  const beneficiariesList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
  const showInactiveBeneficiaries: boolean = beneficiariesViewStore.showInactiveBeneficiaries;
  const openDialog: boolean = beneficiariesViewStore.openDialog;
  const { t } = useTranslation('beneficiaries');
  const [openDrawerUpload, setOpenDrawerUpload] = React.useState<boolean>(false);
  const beneficiariesClasses: ClassNameMap<LocalStyle> = useBeneficiariesStyles({ beneficiariesCount: beneficiariesStore.beneficiariesFilteredList.length });
  const dialogClasses = useDialogStyles();

  const { enabled: isUploadEnabled } = useFeatureFlag('uploadBeneficiaries', false);

  useEffect(() => {
    if (agenciesStore.currentAgency && !openDialog) {
      beneficiariesStore.fetchAllBeneficiariesByAgencyId(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency, openDialog]);

  useEffect(() => {
    if (!openDialog) {
      const searchBeneficiariesToLowerCase = beneficiariesViewStore.inputFilter.toLowerCase();
      beneficiariesStore.filterBeneficiariesByString(showInactiveBeneficiaries, searchBeneficiariesToLowerCase);
    }
  }, [beneficiariesViewStore.inputFilter, showInactiveBeneficiaries, openDialog, beneficiariesList]);

  const handleOpenDialog = (beneficiaryId: string = null) => {
    if (beneficiaryId) {
      beneficiariesStore.fetchBeneficiaryById(beneficiaryId)
        .then(() => beneficiariesViewStore.setOpenDialog(true));
    } else {
      beneficiariesStore.currentBeneficiary = null;
      beneficiariesViewStore.setOpenDialog(true);
    }
  };

  const handleClose = useCallback(() => {
    beneficiariesViewStore.setOpenDialog(false);
  }, []);

  const handleCloseDrawerUpload = useCallback(() => {
    setOpenDrawerUpload(false);
  }, []);

  const handleOpenDrawerUpload = useCallback(() => {
    setOpenDrawerUpload(true);
  }, []);

  return (
    <PageBlock>
      <h1>{t('title')}</h1>
      <PageSubtitle>
        {t('subtitlePartOne', { count: beneficiariesList.length })}
        {beneficiariesStore.inactiveBeneficiaryList.length !== 0 &&
            <span>
          &nbsp;{t('subtitlePartTwo', { count: beneficiariesStore.inactiveBeneficiaryList.length })}
                <div className={beneficiariesClasses.subtitle}
                     onClick={() => beneficiariesViewStore.setShowInactiveBeneficiaries(!showInactiveBeneficiaries)}>
                {showInactiveBeneficiaries ? t('hidden') : t('display')}
              </div>)
            </span>
        }
      </PageSubtitle>
      <div className={beneficiariesClasses.searchBlock}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog()}
          className={beneficiariesClasses.marginButton}
          type="button">
          {t('addBeneficiary')}
        </Button>
        {isUploadEnabled && <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDrawerUpload}
            type="button">
            Ajouter / mettre à jour plusieurs collaborateurs par fichier
        </Button>}
        <div className={beneficiariesClasses.searchInput}>
          <InputComponent
            placeholder={t('searchPlaceholder')}
            icon={magnifyingGlass}
            iconLeft
            onChange={(valueSearch: string) => beneficiariesViewStore.setInputFilter(valueSearch)}
            value={beneficiariesViewStore.inputFilter}
          />
        </div>
      </div>
      <StyledDataGrid
        rows={toJS(beneficiariesStore.beneficiariesFilteredList.map((beneficiary: BeneficiaryModel) => ({
          ...beneficiary, isActive: isBeneficiaryActiveToday(beneficiary),
        })))}
        loading={!agenciesStore.isCurrentAgencyLoaded || beneficiariesStore.isLoading}
        columns={columnsBeneficiaries}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        disableSelectionOnClick
        onRowClick={params => !beneficiariesStore.isLoading && handleOpenDialog(params.row.uid)}
      />
      <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md" open={openDialog} onClose={handleClose}>
        <BeneficiaryDetailsComponent beneficiary={beneficiariesStore.currentBeneficiary} onClose={handleClose}/>
      </Dialog>
      <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md" open={openDrawerUpload}
              onClose={handleCloseDrawerUpload}>
        <AddBeneficiariesByUploadComponent
          onClose={handleCloseDrawerUpload}/>
      </Dialog>
    </PageBlock>
  );
}));

export default BeneficiariesComponent;
