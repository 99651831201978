import { style } from 'typestyle';
import { COLORS } from '../Style/Style';
import { makeStyles } from '@material-ui/core';

export const useAgencyComponentStyles = makeStyles(() => ({
  appBar: {
    borderBottom: '1px solid #D5D9DC',
    paddingLeft: '30px',
  },
  tab: {
    minWidth: 300,
  },
}));

export const container = style({
  minHeight: '850px',
});

export const agencyTitle = style({
  color: COLORS.textPrimary,
  marginBottom: '40px',
  letterSpacing: '2px',
  marginTop: '42px',
});

export const agencyInputContainer = style({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  paddingBottom: '1rem',
});

export const agencyInputCol4 = style({
  flex: '0 0 33%',
  maxWidth: 'calc(33% - 10px)',
});

export const agencyInputCol6 = style({
  flex: '0 0 50%',
  maxWidth: 'calc(50% - 10px)',
});

export const agencyInputCol12 = style({
  flex: '0 0 100%',
  maxWidth: '100%',
});

export const root = style({
  opacity: 0.6,
  pointerEvents: 'none',
});

export const input = style({
  width: '50%',
  margin: '1rem',
});

export const restitutionMethodContainer = style({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '180px',
  height: '100%',
});

export const restitutionMethodContainerButtons = style({
  margin: '1rem',
  width: '100px',
});

export const radioLabel = style({
  marginTop: '1rem',
  opacity: 1,
  color: 'rgba(46,64,82,1)',
  fontFamily: 'Inter-Regular',
  fontSize: '14px !important',
  fontWeight: '400',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'justify',
});

export const formHelper = style({
  color: '#a3aab2',
  fontFamily: 'Inter-Regular',
  fontSize: '14px',
  fontWeight: '400',
  fontStyle: 'normal',
  letterSpacing: '0px',
  textAlign: 'left',
});
