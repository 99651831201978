import { Snackbar, SnackbarContent } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useState } from 'react';
import closeIcon from '../../assets/closeBlack.svg';
import { SnackBarErrorStyles } from './SnackBarErrorStyles';

interface SnackErrorComponentProps {
  message: string;
  onClose?: () => void;
}

const SnackErrorComponent: FunctionComponent<SnackErrorComponentProps> = (props) => {
  const style = SnackBarErrorStyles();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(true);

  const onClose = useCallback(() => {
    setShowErrorSnackbar(false);
    props.onClose?.();
  }, [props.onClose]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showErrorSnackbar}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <SnackbarContent
        className={style.snackbar}
        message={
          <>
            <div className={style.close}>
              <img alt="close" className={style.closeArrow} src={closeIcon}
                   onClick={() => setShowErrorSnackbar(false)}/>
            </div>
            <div className={style.snackbarMessage}>
              {props.message}
            </div>
          </>
        }/>
    </Snackbar>
  );
};

export default SnackErrorComponent;