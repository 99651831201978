import { ContractPart } from '../../models/agencies/Agency.model';
import { MinimalOrderData } from '../../models/orders/Order.model';
import { VatModel } from '../../models/vat/Vat.model';
import { getSubscriptionsBeneficiaryCount } from '../invoices/invoice.utils';

export function getExcludingTaxesOrderFeesAmount(contractPart: ContractPart, agencyOrder: MinimalOrderData): number {
  const { contractSubscriptionFee } = contractPart;
  const subscriptionsBeneficiaryCount = getSubscriptionsBeneficiaryCount(agencyOrder.credits);

  return contractSubscriptionFee * subscriptionsBeneficiaryCount;
}

export function getVatOrderFeesAmount(contractPart: ContractPart, agencyOrder: MinimalOrderData, vat: VatModel): number {
  const excludingTaxesFeesAmount = getExcludingTaxesOrderFeesAmount(contractPart, agencyOrder);
  const vatRate = (vat.rate / 100);

  return excludingTaxesFeesAmount * vatRate;
}

export function getIncludingTaxesOrderFeesAmount(contractPart: ContractPart, agencyOrder: MinimalOrderData, vat: VatModel): number {
  const excludingTaxesFeesAmount = getExcludingTaxesOrderFeesAmount(contractPart, agencyOrder);
  const vatAmount = getVatOrderFeesAmount(contractPart, agencyOrder, vat);

  return excludingTaxesFeesAmount + vatAmount;
}

export function getBeneficiaryCreditAmount(contractPart: ContractPart, numberOfRechargingDays: number): number {
  const provisionDailyAmount = getProvisionDailyAmount(contractPart);

  return provisionDailyAmount * numberOfRechargingDays;
}

export function getProvisionDailyAmount(contractPart: ContractPart): number {
  return contractPart.contractAgencyPart;
}
