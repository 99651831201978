import { ListItem, styled } from '@material-ui/core';
import React from 'react';
import { FONT, COLORS } from '../Style';

interface StyledSideBarMenuListItemProps {
    iscurrentpage: number;
  }

const StyledSideBarMenuListItem = styled(
  (props: StyledSideBarMenuListItemProps & any) => <ListItem {...props} />
)({
  display: 'flex',
  flexDirection: 'column',
  placeItems: 'center',
  placeContent: 'center',
  height: '85px',
  width: '100%',
  cursor: 'pointer',
  backgroundColor: ({ iscurrentpage }: StyledSideBarMenuListItemProps) =>
    iscurrentpage ? '#F7F7F8' : '',
  borderRight: ({ iscurrentpage }: StyledSideBarMenuListItemProps) =>
    iscurrentpage ? `3px solid ${COLORS.textPrimary}` : '',
  fontFamily: FONT.primary,
  color: COLORS.textPrimary,
  fontSize: '14px',
  textAlign: 'center'
});

export default StyledSideBarMenuListItem;
