import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AccountingComponent from './Accounting/Invoices/InvoicesComponent';
import AgencyComponent from './Agency/AgencyComponent';
import LoginPassword from './Auth/LoginPassword';
import LoginResetPassword from './Auth/LoginResetPassword';
import LoginView from './Auth/LoginView';
import ResetPasswordComponent from './Auth/ResetPassword/ResetPasswordComponent';
import BeneficiariesComponent from './Beneficiaries/BeneficiariesComponent';
import CGVsComponent from './Confidentiality/CGVs/CGVsComponent';
import LegalNoticeComponent from './Confidentiality/LegalNoticeComponent';
import MangoPayRGPD from './Confidentiality/MangoPayRGPD';
import RGPDComponent from './Confidentiality/RGPD/RGPDComponent';
import ConnectedComponent from './Connected/ConnectedComponent';
import DashboardComponent from './Dashboard/DashboardComponent';
import PaymentRequestComponent from './PaymentRequest/PaymentRequest/PaymentRequestComponent';
import AddNewRechargingComponent from './Recharging/AddNewRecharging/AddNewRechargingComponent';
import RechargingComponent from './Recharging/RechargingComponent';
import { UserConnectStore } from './Stores/UserConnect.store';
import CookiesComponent from './Cookies/CookiesComponent';

interface IPrivateRouteProps {
  children: any;
  redirectTo?: string;
}

const PrivateRoute: FunctionComponent = observer((props: IPrivateRouteProps) => {
  const userConnectStore: UserConnectStore = useInstance<UserConnectStore>(UserConnectStore);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [awaitingStore, setAwaitingStore] = useState(true);

  useEffect(() => {
    if (awaitingStore && userConnectStore.isReady) {
      setAwaitingStore(false);
    }

    if (userConnectStore.isReady) {
      setIsAuthenticated(userConnectStore.isConnected);
    }
  }, [awaitingStore, userConnectStore.isReady, userConnectStore.isConnected]);

  if (awaitingStore || !userConnectStore.isReady) {
    return <CircularProgress color={'primary'}/>;
  }

  return isAuthenticated
    ? props.children
    : <Navigate to={props.redirectTo ?? '/login'}/>
    ;
});

const AppModule: FunctionComponent = () => {

  return (
    <Routes>
      <Route path="/reload" element={null}/>

      <Route path="/login" element={<LoginView/>}/>
      <Route path="/login/password" element={<LoginPassword/>}/>
      <Route path="/login/reinitialisation" element={<LoginResetPassword/>}/>

      <Route path="/reset-password" element={<ResetPasswordComponent/>}/>
      <Route path="/register-admin" element={<ResetPasswordComponent/>}/>

      <Route path="/cookies" element={<CookiesComponent/>}/>
      <Route path="/legalnotice" element={<LegalNoticeComponent/>}/>
      <Route path="/rgpd" element={<RGPDComponent/>}/>
      <Route path="/cgv" element={<CGVsComponent/>}/>
      <Route path="/mangopayrgpd" element={<MangoPayRGPD/>}/>

      <Route element={<PrivateRoute><ConnectedComponent><Outlet/></ConnectedComponent></PrivateRoute>}>
        <Route path="/dashboard" element={<DashboardComponent/>}/>
        <Route path="/beneficiaries" element={<BeneficiariesComponent/>}/>
        <Route path="/reloading" element={<RechargingComponent/>}/>
        <Route path="/addnewrecharging" element={<AddNewRechargingComponent/>}/>
        <Route path="/continueOrder" element={<AddNewRechargingComponent/>}/>
        <Route path="/agency" element={<AgencyComponent/>}/>
        <Route path="/accounting" element={<AccountingComponent/>}/>
        <Route path="/paymentrequest" element={<PaymentRequestComponent/>}/>
      </Route>

      { /*Redirect to a "default" route if no other routes match*/}
      <Route path="*" element={<Navigate to="/dashboard" replace/>}/>
    </Routes>
  );
};

export default AppModule;
