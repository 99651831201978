import { callable } from './httpWrapper.service';
import { PaybackModel } from '../assets/models/paybacks/Payback.model';
import { GetBeneficiaryPaybacksRequest } from '../assets/requests/paybacks/GetBeneficiaryPaybacks.request';

export async function getBeneficiaryPaybacks(beneficiaryId: string, request: GetBeneficiaryPaybacksRequest): Promise<PaybackModel[]> {
  return callable(
    'getBeneficiaryPaybacksForAdmin',
    { beneficiaryId },
    request,
  );
}
