import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router';
import logoText from '../assets/logo-text-new.svg';
import MailSvg from '../assets/mail';
import resetSuccess from '../assets/reset-pass-success.svg';
import { UserConnectStore } from '../Stores/UserConnect.store';
import Button from '../Style/MuiStyles/Button';
import StyledTextField from '../Style/MuiStyles/TextField';
import { validationEmail } from '../Utils/Login.service';
import { useConnectionStyles } from './Login/Connection/ConnectionStyles';
import { useLoginComponentStyles } from './Login/LoginStyles';

type LocalStore = [UserConnectStore];
type LocalFormValue = {
  email: string
};

const LoginResetPassword = observer(() => {

  const [userConnectStore]: LocalStore = useInstances<LocalStore>(UserConnectStore);
  const {isNewUrlSent}: UserConnectStore = userConnectStore;

  const { t } = useTranslation('auth');
  const loginComponentClasses = useLoginComponentStyles();
  const connectionClasses = useConnectionStyles();
  const search: string = location.search;
  const params: URLSearchParams = new URLSearchParams(search);
  const email: string = params.get('email') ?? '';

  const sendEmailForResetPassword: ({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) => Promise<void> = useCallback(async ({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(true);
    await userConnectStore.requestPasswordChange(email);
    setSubmitting(false);
  }, []);

  const formik = useFormik({
    initialValues: {
      email,
    },
    validationSchema: validationEmail,
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => sendEmailForResetPassword(values, setSubmitting),
  });

  return (
    <div className={loginComponentClasses.root}>
      <div className={loginComponentClasses.leftBlock}>
        {t('hello')}<br/>
        {t('hello2')}
      </div>
      <div className={loginComponentClasses.rightBlock}>
        <div className={loginComponentClasses.content}>
          {isNewUrlSent
            ? <>
              <div className={loginComponentClasses.imgContent}>
                <div>
                  <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
                </div>
                <div>
                  <img className={loginComponentClasses.logo} alt="logo" width="125px" src={resetSuccess}/>
                </div>
                <p style={{ paddingLeft: '1rem' }}>{t('subTitleResetPasswordSuccess')}</p>
              </div>
            </>
            : <>
              <div className={loginComponentClasses.textAlignCenter}>
                <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
                <p>{t('subtitleResetPassword')}</p>
                <p>{t('subtitleResetPassword_two')}</p>
              </div>
              <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit} noValidate>
                <StyledTextField
                  className={connectionClasses.input}
                  id="email"
                  name="email"
                  label={t('email')}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.email) && formik.touched.email}
                  InputProps={{ endAdornment: <MailSvg style={{ marginTop: '20px' }}/> }}
                  fullWidth
                  required
                  placeholder={t('emailPlaceholder')}
                  errormessage={formik.errors.email}
                  autoFocus
                  autoComplete="new-password"
                />
                <Button
                  variant="contained"
                  style={{ margin: '0 auto' }}
                  color="primary"
                  isFetching={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                  type="submit"
                >
                  {t('resetPassword')}
                </Button>
              </form>
            </>}
        </div>
      </div>
    </div>
  );
});

export default LoginResetPassword;