import { AgencyModel } from '../assets/models/agencies/Agency.model';
import { callable } from './httpWrapper.service';
import { MandateModel } from '../assets/models/mandates/mandate.model';
import { RedirectUrlResponse } from '../assets/responses/RedirectUrl.response';
import { PaymentBankAccountsModel } from '../assets/models/payments/PaymentBankAccounts.model';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';

export async function getAgencyByAgencyId(agencyId: string): Promise<AgencyModel | null> {
  return callable(
    'getOneAgency',
    { agencyId },
  );
}

export async function getAllAgenciesOfAgencyAdmin(companyId: string, agencyAdminId: string): Promise<AgencyModel[]> {
  return callable(
    'getAllAgenciesOfAgencyAdmin',
    { companyId, agencyAdminId },
  );
}

export async function getAgencyMandate(agencyId: string): Promise<MandateModel | null> {
  return callable(
    'getAgencyMandate',
    { agencyId },
  );
}

export async function createAgencyMandate(agencyId: string): Promise<RedirectUrlResponse> {
  return callable(
    'createAgencyMandate',
    { agencyId },
  );
}

export async function getAgencyBankAccountAlias(companyId: string, agencyId: string): Promise<PaymentBankAccountsModel> {
  return callable(
    'getAgencyPaymentBankAccountAlias',
    { companyId, agencyId },
  );
}

export async function updateAgencyRestitution(companyId: string, agencyId: string, request: UpdateAgencyRestitutionRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateAgencyRestitution',
    { companyId, agencyId },
    request,
  );
}