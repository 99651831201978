import { callable } from './httpWrapper.service';
import { GetUserImportedStatusRequest } from '../assets/requests/auth/GetUserImportedStatus.request';
import { LoginRequest } from '../assets/requests/auth/Login.request';
import { LoginResponse } from '../assets/responses/Login.response';
import { GetUserImportedStatusResponse } from '../assets/responses/users/GetUserImportedStatus.response';
import { ConfirmationResponse } from '../assets/responses/Confirmation.response';
import { ResetPasswordRequest } from '../assets/requests/auth/ResetPassword.request';
import { setRefreshToken, setAccessToken } from './accessToken.service';
import {
  ChangePasswordForAgencyAdminRequest,
} from '../../../../lambdas/shared/requests/auth/ChangePasswordForAgencyAdmin.request';
import {
  ValidateHashForAgencyAdminRequest,
} from '../../../../lambdas/shared/requests/auth/ValidateHashForAgencyAdmin.request';
import {
  ValidateHashForAgencyAdminResponse,
} from '../../../../lambdas/shared/responses/auth/ValidateHashForAgencyAdmin.response';

export async function authenticate(request: LoginRequest): Promise<LoginResponse> {
  const response: LoginResponse = await callable(
    'loginForAgencyAdmin',
    request,
  );

  if (response) {
    setRefreshToken(response.refreshToken);
    setAccessToken(response.token);
  }
  return response;
}

export async function activateAgencyAdmin(request: ChangePasswordForAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'changePasswordForAgencyAdmin',
    request,
  );
}

export async function requestPasswordChangeForAgencyAdmin(request: ResetPasswordRequest): Promise<ConfirmationResponse> {
  return callable(
    'requestPasswordChangeForAgencyAdmin',
    request,
  );
}

export async function confirmPasswordChangeForAgencyAdmin(request: ChangePasswordForAgencyAdminRequest): Promise<ConfirmationResponse> {
  return callable(
    'changePasswordForAgencyAdmin',
    request,
  );
}

export async function getUserImportedStatusByEmail(request: GetUserImportedStatusRequest): Promise<GetUserImportedStatusResponse> {
  return callable(
    'getImportedStatusForAgencyAdmin',
    request,
  );
}

export async function validateHashForAgencyAdmin(request: ValidateHashForAgencyAdminRequest): Promise<ValidateHashForAgencyAdminResponse> {
  return callable(
    ('validateHashForAgencyAdmin'),
    request,
  );
}
