import { makeStyles } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from '../../../Style/Style';

interface useBeneficiaryAccountInformationsStylesProps {
  disabled?: boolean,
}

export const useBeneficiaryAccountInformationsStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px',
  },
  imgClose: {
    cursor: 'pointer',
  },
  input: {
    marginBottom: '25px',
  },
  select: {
    placeContent: 'center',
    marginBottom: '25px',
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '10px 50px 40px 0px',
    color: COLORS.textPrimary,
    textTransform: 'uppercase',
  },
  tab: {
    fontSize: '16px',
    fontWeight: FONT_WEIGHT.semiBold,
  },
  appBar: {
    borderBottom: '1px solid #D5D9DC',
    paddingLeft: '30px',
  },
  warning: {
    height: '57px',
    borderRadius: '10px',
    backgroundColor: 'rgba(255,193,94,0.2)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningTxt: {
    paddingLeft: '10px',
  },
  switchOpacity: ({ disabled }: useBeneficiaryAccountInformationsStylesProps) => ({
    opacity: disabled ? '.5' : '1',
  }),
  hiddenButton: ({ disabled }: useBeneficiaryAccountInformationsStylesProps) => ({
    display: disabled ? 'none' : 'initial',
  }),
  beneficiaryDeactivate: {
    color: COLORS.error,
    fontSize: '16px',
    fontWeight: 400,
    marginBottom: '25px',
  },
}));
