import { RefreshTokenResponse } from '../assets/responses/RefreshToken.response';
import { callable } from './httpWrapper.service';
import { getCookieValue, setCookie, unsetCookie } from './cookies.service';

const REFRESH_COOKIE: string = 'openRefreshToken';
const ACCESS_COOKIE: string = 'openAccessToken';
const ACCESS_TTL: number = 15 * 60 * 1000; // 15 min
const REFRESH_TTL: number = 24 * 60 * 60 * 1000; // 24h
let refreshToken: string = getRefreshToken();

export function isAuthenticated(): boolean {
  return !!refreshToken;
}

export function clearAuthTokens(): void {
  unsetCookie(ACCESS_COOKIE);
  unsetCookie(REFRESH_COOKIE);
  refreshToken = '';
}

export function setAccessToken(accessToken: string): void {
  void setCookie(ACCESS_COOKIE, accessToken);
}

export async function getAccessToken(): Promise<string> {
  const accessToken: string = getCookieValue(ACCESS_COOKIE);
  return accessToken || await refreshAccessToken() || '';
}

export function setRefreshToken(newRefreshToken: string): void {
  refreshToken = newRefreshToken;
  void setCookie(REFRESH_COOKIE, newRefreshToken);
}

function getRefreshToken(): string {
  return getCookieValue(REFRESH_COOKIE) || '';
}

export async function refreshAccessToken(): Promise<string | undefined> {
  const refreshToken: string = getRefreshToken();

  if (!refreshToken) {
    return;
  }

  try {
    const { token }: RefreshTokenResponse = await callable(
      'refreshTokenForAgencyAdmin',
      { refreshToken },
    );
    if (token) {
      void setAccessToken(token);
    }
    return token;
  } catch (error: any) {
    const { status } = error.response;
    if (status === 500) {
      // logout
    }
    return;
  }

}
