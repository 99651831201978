import moment from 'moment';
import { isIBAN } from '../../assets/utils/checkTypes/isIBAN';
import * as regexList from '../regexList';

// region Interfaces & Types
export interface FieldTestResult {
  passed: boolean;
  errorMessage?: string;
}

export interface ErrorDetails {
  expectedValue?: string;
  minValue?: number;
  maxValue?: number;
  minLength?: number;
  maxLength?: number;
  lowerRange?: number;
  upperRange?: number;
}

export interface FieldTestReport<T> extends FieldTestResult, ErrorDetails {
  fieldName: keyof T;
}

export interface ObjectTestResult<T> {
  passed: boolean;
  errors?: FieldTestReport<T>[];
}

export interface RowTestResult<T> extends ObjectTestResult<T> {
  rowIndex: number;
  csvEntity?: CsvRowEntity<T>;
}

export type TesterFunction = (value: string) => FieldTestResult;

export type TestSet<T> = {
  [key in keyof T]?: TesterFunction[];
}

export type CsvRowEntity<T> = { [key in keyof T]: string };
// endregion

// region Constants
const acceptedTitle: string[] = ['M.', 'Mme'];
const acceptedYesNoQuestion: string[] = ['O', 'N'];

// endregion

export function getTestFieldResult(value: string, testCondition: () => boolean, errorMessage: string, errorDetails?: ErrorDetails): FieldTestResult {
  if (testCondition()) {
    return {
      passed: true,
    };
  }

  return {
    passed: false,
    errorMessage: errorMessage,
    ...(errorDetails || {}),
  };
}

// region Validators
export function isString(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => (value
      ? regexList.regexOnlyLetters.test(String(value))
      : true),
    'NOT_A_STRING',
  );
}

export function isStringWithSpace(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexOnlyLettersWithSpace.test(String(value)),
    'NOT_A_STRING',
  );
}

export function isNumber(value: string): FieldTestResult {
  const stringToNumber = Number(value);

  return getTestFieldResult(
    value,
    () => !isNaN(stringToNumber),
    'NOT_A_NUMBER',
  );
}

export function isRequired(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => value !== '' && value.trim() !== '',
    'IS_REQUIRED',
  );
}

export function isAlphaNumeric(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexAlphaNumeric.test(String(value)),
    'NOT_AN_ALPHANUMERIC',
  );
}

export function isAlphaNumericWithSpace(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => value.length === 0 || regexList.regexAlphaNumericWithSpace.test(String(value)),
    'NOT_AN_ALPHANUMERIC',
  );
}

export function isEmail(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => regexList.regexEmail.test(String(value)),
    'NOT_AN_EMAIL',
  );
}

export function isDate(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => moment(value, 'DD/MM/YYYY', true).isValid(),
    'NOT_A_DATE',
  );
}
export function isValueGreater(minValue: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => Number(value) >= minValue,
    'NOT_VALUE_GREATER',
    { minValue},
  );
}

export function isValidIBAN(value: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => isIBAN(value),
    'NOT_A_VALID_IBAN',
  );
}

export function isRespectedLengthCurried(minLength: number, maxLength: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => (value.length >= minLength && value.length <= maxLength),
    'NOT_RESPECT_LENGTH',
    { minLength, maxLength },
  );
}

export function isRespectedMaxLengthCurried(maxLength: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => value.length <= maxLength,
    'NOT_RESPECT_MAX_LENGTH',
    { maxLength },
  );
}

export function isValueBetweenCurried(minValue: number, maxValue: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => Number(value) >= minValue && Number(value) <= maxValue,
    'NOT_VALUE_BETWEEN',
    { minValue, maxValue },
  );
}

export function isAcceptedValue(value: string, acceptedValues: string[], errorMessage: string): FieldTestResult {
  return getTestFieldResult(
    value,
    () => acceptedValues.includes(value),
    errorMessage,
  );
}

export function isYesNoQuestionValue(value: string): FieldTestResult {
  return isAcceptedValue(
    value,
    acceptedYesNoQuestion,
    'NOT_YES_NO_VALUE',
  );
}

export function isAcceptedTitle(value: string): FieldTestResult {
  return isAcceptedValue(
    value,
    acceptedTitle,
    'NOT_ACCEPTED_TITLE',
  );
}

// endregion

function testEntity<T>(entityToTest: CsvRowEntity<T>, testSet: TestSet<T>): FieldTestReport<T>[] {
  const entityKeys: (keyof T)[] = Object.keys(entityToTest) as (keyof T)[];

  return entityKeys.reduce((entityErrors: FieldTestReport<T>[], key: keyof T) => {
    const testsToUse: TesterFunction[] = testSet[key];

    if (testsToUse) {
      let fieldTestResult: FieldTestResult;

      for (const test of testsToUse) {
        fieldTestResult = test(entityToTest[key].trim());

        if (!fieldTestResult.passed) {
          entityErrors.push({
            ...fieldTestResult,
            fieldName: key,
          });

          break;
        }
      }
    }
    return entityErrors;
  }, []);
}

function convertCsvToJson<T>(propertyNamesList: (keyof T)[], csvRawData: string[][]): CsvRowEntity<T>[] {
  return csvRawData.map((row: string[]) => {
    return row.reduce((entity: CsvRowEntity<T>, value: string, index: number) => {
      const propertyName = propertyNamesList[index];
      entity[propertyName] = value;
      return entity;
    }, {} as CsvRowEntity<T>);
  });
}

function getFileTestResult<T>(csvRawEntities: CsvRowEntity<T>[], testSet: TestSet<T>): RowTestResult<T>[] {

  return csvRawEntities.map((entity: CsvRowEntity<T>, index: number) => {
    const isEmptyLine: boolean = Object.values(entity).every((item:string) => item.trim() === '');

    if (isEmptyLine) {
      return {
        rowIndex: index,
        passed: true,
        errors: [],
        csvEntity: entity,
      };
    }

    const fieldsTestErrors: FieldTestReport<T>[] = testEntity(entity, testSet);
    const isSuccess: boolean = !fieldsTestErrors.length;

    return {
      rowIndex: index,
      passed: isSuccess,
      errors: fieldsTestErrors,
      csvEntity: entity,
    };
  });
}

export function getObjectTestResult<T>(object, testSet: TestSet<T>): ObjectTestResult<T> {
  const errors: FieldTestReport<T>[] = testEntity(object, testSet);
  const isSuccess = !errors.length;

  return {
    passed: isSuccess,
    errors: errors,
  };
}

function analyseCsvHeaderRow(headerRow: string[], keysMap) {
  const emptyHeaderIndex: number = headerRow.findIndex((columnName: string) => columnName !== '');
  const hasEmptyHeader: boolean = emptyHeaderIndex === -1;

  if (hasEmptyHeader) {
    throw  new Error(`Error column ${emptyHeaderIndex + 1} has no name`);
  }

  const unknownHeaderKey = headerRow.find((headerKey) => !(headerKey in keysMap));

  if (!!unknownHeaderKey) {
    throw  new Error(`Error column naming ${unknownHeaderKey}`);
  }
}

function convertCsvHeaderRowToPropertyNamesList<T>(headerRow: string[], keysMap): (keyof T | undefined)[] {
  analyseCsvHeaderRow(headerRow, keysMap);

  return headerRow.map((headerKey: string) => keysMap[headerKey]);
};

export type AnalyseResults<T> = { csvRawEntities: CsvRowEntity<T>[], fileTestResult: RowTestResult<T>[] };

export function analyseUploadingFile<T>(
  headerRow: string[],
  dataRows: string[][],
  testSet: TestSet<T>,
  keysMap,
): RowTestResult<T>[] {
  const propertyNamesList: (keyof T | undefined)[] = convertCsvHeaderRowToPropertyNamesList<T>(headerRow, keysMap);
  const testSetKeys: (keyof T)[] = (Object.keys(testSet) as (keyof T)[]).sort();
  const includesAllTestSetKeys = testSetKeys.every((key: keyof T) => propertyNamesList.includes(key));
  const csvRawEntities: CsvRowEntity<T>[] = convertCsvToJson<T>(propertyNamesList, dataRows);

  if (!includesAllTestSetKeys) {
    return [{
      rowIndex: -1,
      passed: false,
      errors: [],
      csvEntity: null,
    }];
  }

  const fileTestResult: RowTestResult<T>[] = getFileTestResult(csvRawEntities, testSet);

  return fileTestResult;
}
