import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import auth from './fr/auth';
import common from './fr/common';
import agency from './fr/agency';
import resetpassword from './fr/resetpassword';
import beneficiaries from './fr/beneficiaries';
import dashboard from './fr/dashboard';
import footer from './fr/footer';
import accounting from './fr/accounting';
import country from './fr/country';
import onboarding from './fr/onboarding';
import nationality from './fr/nationality';
import reloading from './fr/reloading';
import payment from './fr/payment';
import beneficiariesfromcsv from './fr/beneficiariesfromcsv';
import mandate from './fr/mandate';
import beneficiaryTakeOut from './fr/beneficiaryTakeOut';

const resources = {
  fr: {
    common,
    agency,
    resetpassword,
    beneficiaries,
    dashboard,
    footer,
    accounting,
    country,
    onboarding,
    nationality,
    reloading,
    payment,
    beneficiariesfromcsv,
    mandate,
    auth,
    beneficiaryTakeOut,
  }
};

i18next
  .use(initReactI18next)
  .init({
    interpolation: {
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
      escapeValue: false
    },
    lng: 'fr', // 'en' | 'es'
    resources: resources,
    react: {
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
      useSuspense: false
    }
  });

export default i18next;
