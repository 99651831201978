import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {PageBlock} from '../Style/Style';
import {useTranslation} from 'react-i18next';
import { Button, CircularProgress, Dialog, MenuItem, Select } from '@material-ui/core';
import addIcon from '../assets/add-black.svg';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import classnames from 'classnames';
import useDialogStyles from '../Style/MuiStyles/Dialog';
import OrderDetailsComponent from './OrderDetails';
import {observer} from 'mobx-react-lite';
import {useInstances} from 'react-ioc';
import {AgenciesStore} from '../Stores/Agencies.store';
import { AgencyInvoiceStore, OrderInvoices } from '../Stores/AgencyInvoice.store';
import {RechargingViewStore} from '../Stores/viewStore/RechargingView.store';
import OrderCardComponent from '../Component/OrderCard/OrderCardComponent';
import {ClassNameMap} from '@material-ui/core/styles/withStyles';
import {InvoiceModel} from '@assets/models/invoices/invoice.model';
import {StyledSelect} from "../Style/MuiStyles/Select/Select";
import Downarrow from '../assets/down-arrow';
import {useInvoicesStyles} from "../Accounting/Invoices/InvoicesStyles";
import { useRechargingStyles } from './RechargingStyles';

type LocalStore = [AgenciesStore, AgencyInvoiceStore, RechargingViewStore];

const RechargingComponent: FunctionComponent = (observer(() => {
    const [
        agenciesStore,
        agencyInvoiceStore,
        rechargingViewStore,
    ]: [AgenciesStore, AgencyInvoiceStore, RechargingViewStore] = useInstances<LocalStore>(AgenciesStore, AgencyInvoiceStore, RechargingViewStore);
    const {t} = useTranslation('reloading');
    const reloadingClasses: ClassNameMap<'invoices' | 'newRechargingCard' | 'newReloadingBlock' | 'addIcon' | 'title'> = useRechargingStyles();
    const navigate: NavigateFunction = useNavigate();
    const dialogClasses: ClassNameMap<'dialogPaper'> = useDialogStyles();
    const invoicesClasses = useInvoicesStyles();

    const [sinceDate, setSinceDate] = useState<number>(1);

    const selectSinceDate: (value: number) => Date = useCallback((value: number) => {
        let from = new Date();
        switch (value) {
            case 0:
                from.setMonth(from.getMonth() - 6);
                break;
            case 1:
                from.setFullYear(from.getFullYear() - 1);
                break;
            case 2:
                from.setFullYear(from.getFullYear() - 2);
                break;
            case 3:
            default:
                from = null;
        }
        return from;
    }, []);

    const getInvoicesByDate: (from: Date) => Promise<void> = useCallback(async (from: Date) => {
        if (!agenciesStore.currentAgency) {
            return;
        }
        const invoices: InvoiceModel[] = await agencyInvoiceStore.fetchAgencyInvoices(agenciesStore.currentAgency.uid, from?.getTime());
        agencyInvoiceStore.sortInvoicesByRechargingMonthAndYear(invoices);
    }, [agenciesStore.currentAgency?.uid]);

    useEffect(() => {
        if (agenciesStore.currentAgency) {
            void getInvoicesByDate(selectSinceDate(sinceDate));
        }
        sessionStorage.removeItem('reloadingDateKey');
    }, [agenciesStore.currentAgency, sinceDate]);

    return (
        <PageBlock>
            <h1 className={reloadingClasses.title}>Vos derniers rechargements</h1>
            <Select
                value={sinceDate}
                onChange={(evt) => setSinceDate(evt.target.value as number)}
                labelId="label"
                id="select"
                input={<StyledSelect/>}
                IconComponent={(props) => Downarrow({svgColor: '#FFFFFF', ...props, className: invoicesClasses.icon})}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
            >
                <MenuItem value={0}>{t('since6months')}</MenuItem>
                <MenuItem value={1}>{t('since1year')}</MenuItem>
                <MenuItem value={2}>{t('since2years')}</MenuItem>
                <MenuItem value={3}>{t('sinceBeginning')}</MenuItem>
            </Select>
            <div className={reloadingClasses.invoices}>
                <div
                    className={classnames(reloadingClasses.newRechargingCard, reloadingClasses.newReloadingBlock)}
                    onClick={() => navigate('/addNewRecharging')}
                >
                    <div style={{textAlign: 'center', margin: '100px 0px 50px 0px'}}>
                        <div className={classnames(reloadingClasses.addIcon)}>
                            <img width={43} alt={'Nouveau rechargement'} src={addIcon}/>
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="button">
                        {t('newReload')}
                    </Button>
                </div>
                {agencyInvoiceStore.isInvoicesLoading
                    ? <CircularProgress size={64} color={'primary'}/>
                    : <>
                        {agencyInvoiceStore.ordersInvoices?.map((orderInvoices: OrderInvoices, i: number) =>
                            <OrderCardComponent
                                key={i}
                                invoiceKey={i}
                                orderInvoices={orderInvoices}
                            />,
                        )
                        }
                    </>
                }
            </div>

            <Dialog
                className={dialogClasses.dialogPaper}
                fullWidth
                maxWidth="md"
                open={rechargingViewStore.openOrderDetails}
                onClose={() => rechargingViewStore.setOpenOrderDetails(false)}
            >
                <OrderDetailsComponent
                    orderInvoices={agencyInvoiceStore.selectedOrder}
                    onClose={() => rechargingViewStore.setOpenOrderDetails(false)}
                />
            </Dialog>

        </PageBlock>
    );
}));

export default RechargingComponent;
