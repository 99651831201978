import { action, makeAutoObservable } from 'mobx';
import { IOrderDetails } from '../../Models/Interfaces/IOrderDetails.model';
import { PaymentMethodType } from '../../assets/types/PaymentMethod.type';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import moment from 'moment';
import {
  BeneficiaryCreditRequest,
  CreateAgencyOrderRequest,
} from '../../assets/requests/orders/CreateAgencyOrder.request';
import { InvoiceCustomMetadataModel } from '@assets/models/invoices/InvoiceCustomMetadata.model';

export class OrderPaymentViewStore {
  paymentMethod: PaymentMethodType = null;
  methodLoading: boolean = false;
  isOpenMandateDialog: boolean = false;

  beneficiariesToRecharge: BeneficiaryModel[] = [];
  orderDetails: IOrderDetails = {
    beneficiaryToRechargeCount: 0,
    beneficiariesToRecharge: [],
    month: 0,
    year: 0,
    totalHT: 0,
    tva: 0,
    totalTTC: 0,
    contractSubscriptionFee: 0,
    contractAgencyPart: 0,
    provisionTotalDays: 0,
    provisionAmount: 0,
    netAmountPayable: 0,
    invoiceCustomMetadata: {},
  };

  get credits() {
    return this.beneficiariesToRecharge.map(beneficiary => ({
      beneficiaryUid: beneficiary.uid,
      numberOfRechargingDays: beneficiary.numberOfWorkingDays,
    }));
  }

  get dateString(): string {
    const year: number = this.orderDetails.year;
    const month: number = this.orderDetails.month;
    return !!month && !!year ? moment(`${month} ${year}`, 'MM YYYY').format('MMMM YYYY') : '';
  }

  get tva(): string {
    return this.currencyFormat(this.orderDetails.tva);
  }

  get totalHT(): string {
    return this.currencyFormat(this.orderDetails.totalHT);
  }

  get totalTTC(): string {
    return this.currencyFormat(this.orderDetails.totalTTC);
  }

  get beneficiaryToRechargeCount(): number {
    return this.orderDetails.beneficiaryToRechargeCount;
  }

  get provisionTotalDays(): number {
    return this.orderDetails.provisionTotalDays;
  }

  get contractSubscriptionFee(): string {
    return this.currencyFormat(this.orderDetails.contractSubscriptionFee);
  }

  get provisionAmount(): string {
    return this.currencyFormat(this.orderDetails.provisionAmount);
  }

  get netAmountPayable(): string {
    return this.currencyFormat(this.orderDetails.netAmountPayable);
  }

  get contractAgencyPart(): string {
    return this.currencyFormat(this.orderDetails.contractAgencyPart);
  }

  get agencyOrderCreationRequest(): Omit<CreateAgencyOrderRequest, 'agencyId'> {
    const { year, month, invoiceCustomMetadata }: IOrderDetails = this.orderDetails;
    const credits: BeneficiaryCreditRequest[] = this.beneficiariesToRecharge.map(beneficiary => ({
      beneficiaryUid: beneficiary.uid,
      numberOfRechargingDays: beneficiary.numberOfWorkingDays,
    }));

    return { month, year, credits, invoiceCustomMetadata };
  }

  get invoiceCustomMetadata(): InvoiceCustomMetadataModel {
    return this.orderDetails.invoiceCustomMetadata;
  }

  get hasAtLeastMetadata(): boolean {
    return !!this.countSetMetadata;
  }

  get countSetMetadata(): number {
    return Object.values(this.invoiceCustomMetadata).filter(value => value !== undefined).length;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setOpenMandateDialog(value: boolean) {
    this.isOpenMandateDialog = value;
  }

  @action setPaymentMethod(newMethod: PaymentMethodType) {
    this.paymentMethod = newMethod;
  }

  @action setBeneficiariesToRecharge(beneficiaries: BeneficiaryModel[]): void {
    this.beneficiariesToRecharge = beneficiaries;
  }

  @action setOrderDetails(orderDetails: IOrderDetails): void {
    this.orderDetails = orderDetails;
    localStorage.setItem('openPendingOrderDetails', JSON.stringify(orderDetails));
  }

  @action setInvoiceCustomMetadataItem(key: keyof InvoiceCustomMetadataModel, value: string): void {
    this.setOrderDetails({
      ...this.orderDetails,
      invoiceCustomMetadata: {
        ...this.orderDetails.invoiceCustomMetadata,
        [key]: value,
      },
    });
  }

  @action reloadPendingOrderDetails(): void {
    const jsonStringOrderDetails: string = localStorage.getItem('openPendingOrderDetails');
    const pendingOrderDetails: IOrderDetails = jsonStringOrderDetails && JSON.parse(jsonStringOrderDetails);

    if (pendingOrderDetails) {
      this.orderDetails = pendingOrderDetails;
      this.beneficiariesToRecharge = pendingOrderDetails.beneficiariesToRecharge;
    }
  }

  private currencyFormat(value: number) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
  }
}
