import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import {
  FieldTestResult,
  getTestFieldResult,
  isAcceptedTitle,
  isAlphaNumeric,
  isAlphaNumericWithSpace,
  isDate,
  isEmail,
  isNumber,
  isRequired,
  isRespectedMaxLengthCurried,
  isString, isStringWithSpace, isValidIBAN,
  isValueBetweenCurried,
  isYesNoQuestionValue, isValueGreater,
  RowTestResult,
} from './BaseTester.service';
import moment from 'moment';
import {
  getTimeStampMinusMonth, getTimeStampPlusMonth,
  getTimeStampStartOfDay,
} from '../../assets/utils/dates/getParsedDate.util';

export const postcodeLength: number = 5;
export const ibanLength: number = 27;
export const workingDayNumberScope = { min: 0, max: 20 };
export const recharginDaysNumberScope = { min: 0, max: 999 };
export const yearScope = { min: 2022, max: 2122 };
export const monthScope = { min: 1, max: 12 };
export const firstRightDateScope = { lowerRange: 2, upperRange: 6 };

export const uploadBeneficiaryKeysMap = {
  'Matricule entreprise': 'registrationNumber',
  'Email': 'email',
  'Civilite (M./Mme)': 'title',
  'Nom': 'lastName',
  'Prenom': 'firstName',
  'Pays de residence': 'countryCode',
  'Adresse': 'streetAddress',
  'Complement d\'adresse': 'additionalAddress',
  'code postal': 'postalCode',
  'Ville': 'city',
  'IBAN': 'iban',
  'Travaille le dimanche (O/N)': 'activeSundaysAndHolidays',
  'NB jours par defaut': 'numberOfWorkingDays',
  'date debut droit (dd/mm/yyyy)': 'firstRightDate',

  registrationNumber: 'Matricule entreprise',
  email: 'Email',
  title: 'Civilite (M./Mme)',
  lastName: 'Nom',
  firstName: 'Prenom',
  countryCode: 'Pays de residence',
  streetAddress: 'Adresse',
  additionalAddress: 'Complement d\'adresse',
  postalCode: 'code postal',
  city: 'Ville',
  iban: 'IBAN',
  activeSundaysAndHolidays: 'Travaille le dimanche (O/N)',
  numberOfWorkingDays: 'NB jours par defaut',
  firstRightDate: 'date debut droit (dd/mm/yyyy)',
};

export const beneficiaryRechargingKeysMap = {
  'Annee': 'year',
  'Mois': 'month',
  'Matricule': 'registrationNumber',
  'Nb jours': 'numberOfWorkingDays',
  year: 'Annee',
  month: 'Mois',
  registrationNumber: 'Matricule',
  numberOfWorkingDays: 'Nb jours',
};

export interface CsvBeneficiary {
  registrationNumber: string;
  email: string;
  title: string;
  lastName: string;
  firstName: string;
  countryCode: string;
  streetAddress: string;
  additionalAddress: string;
  postalCode: string;
  city: string;
  iban: string;
  activeSundaysAndHolidays: string;
  numberOfWorkingDays: string;
  firstRightDate: string;
}

export interface BeneficiaryRowTestResult extends RowTestResult<CsvBeneficiary> {
  firstName: string;
  lastName: string;
  userValidity: BeneficiaryExistsStatus;
}

export type BeneficiaryExistsStatus = 'USER_UNKNOWN' | 'USER_EXISTS' | 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS' | 'EMPTY_LINE';

export interface CsvBeneficiaryRecharging {
  year: string;
  month: string;
  registrationNumber: string;
  numberOfWorkingDays: string;
}

function isRegistrationNumberExistsCurried(existingBeneficiaryList: BeneficiaryModel[]): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === value) === undefined,
    'REGISTRATION_NUMBER_ALREADY_TAKEN',
    { expectedValue: value },
  );
}

function isBeneficiaryEmailExistsCurried(existingBeneficiaryList: BeneficiaryModel[]): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.email === value) === undefined,
    'EMAIL_ALREADY_TAKEN',
  );
}

function isRegistrationNumberFoundCurried(existingBeneficiaryList: BeneficiaryModel[]): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.registrationNumber === value) !== undefined,
    'USER_REF_NOT_FOUND',
    { expectedValue: value },
  );
}

function isDateWithinAcceptedRange(lowerRange: number, upperRange: number): (value: string) => FieldTestResult {
  return (value: string) => getTestFieldResult(
    value,
    () => {
      const date: number = moment(value, 'DD/MM/YYYY', true).toDate().getTime();
      const normalizedDate: number = getTimeStampStartOfDay(date);
      const minimumAcceptedDate: number = getTimeStampStartOfDay(getTimeStampMinusMonth(lowerRange));
      const maximumAcceptedDate: number = getTimeStampStartOfDay(getTimeStampPlusMonth(upperRange));

      return minimumAcceptedDate < normalizedDate && maximumAcceptedDate > normalizedDate;
    },
    'NOT_ACCEPTABLE_RANGE_DATE',
    { lowerRange, upperRange },
  );
}

export function getUploadBeneficiaryTestSet(existingBeneficiaryList: BeneficiaryModel[]) {

  return {
    registrationNumber: [isRequired, isAlphaNumeric, isRegistrationNumberExistsCurried(existingBeneficiaryList)],
    email: [isRequired, isEmail, isBeneficiaryEmailExistsCurried(existingBeneficiaryList)],
    title: [isRequired, isAcceptedTitle],
    lastName: [isRequired, isStringWithSpace],
    firstName: [isRequired, isStringWithSpace],
    countryCode: [isString],
    streetAddress: [isAlphaNumericWithSpace],
    additionalAddress: [isAlphaNumericWithSpace],
    postalCode: [isRequired, isNumber, isRespectedMaxLengthCurried(postcodeLength)],
    city: [isRequired, isAlphaNumericWithSpace], // TODO implement country code validator
    iban: [isRequired, isAlphaNumeric, isValidIBAN],
    activeSundaysAndHolidays: [isRequired, isYesNoQuestionValue],
    numberOfWorkingDays: [isRequired, isNumber, isValueBetweenCurried(workingDayNumberScope.min, workingDayNumberScope.max)],
    firstRightDate: [isRequired, isDate, isDateWithinAcceptedRange(firstRightDateScope.lowerRange, firstRightDateScope.upperRange)],
  };
}

export function getFormBeneficiaryTestSet(existingBeneficiaryList: BeneficiaryModel[]) {
  return {
    registrationNumber: [isRequired, isAlphaNumeric, isRegistrationNumberExistsCurried(existingBeneficiaryList)],
    email: [isRequired, isEmail, isBeneficiaryEmailExistsCurried(existingBeneficiaryList)],
    iban: [isRequired, isAlphaNumeric, isRespectedMaxLengthCurried(ibanLength)],
  };
}

export function getBeneficiaryRechargingTestSet(existingBeneficiaryList: BeneficiaryModel[]) {
  return {
    year: [isRequired, isNumber, isValueGreater(yearScope.min)],
    month: [isRequired, isNumber, isValueBetweenCurried(monthScope.min, monthScope.max)],
    registrationNumber: [isRequired, isAlphaNumeric, isRegistrationNumberFoundCurried(existingBeneficiaryList)],
    numberOfWorkingDays: [isRequired, isNumber, isValueBetweenCurried(recharginDaysNumberScope.min, recharginDaysNumberScope.max)],
  };
}
