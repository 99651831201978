import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import rightArrow from '../../../assets/right-arrow.svg';
import { useTranslation } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';

const useBreadcrumbTopMenuStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    padding: '0 1rem',
    alignItems: 'center',
    display: 'flex',
    borderRadius: '0 20px 20px 0'
  },
  link: {
    color: '#79BEDB',
    cursor: 'pointer',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  img: {
    margin: '0 1rem'
  },
  currentPage: {
    fontSize: '14px'
  }
}));

interface IBreadcrumbTopMenuProps {
  navigate: NavigateFunction;
  pathname: string;
}

export default function BreadcrumbTopMenu (props:IBreadcrumbTopMenuProps) {
  const breadcrumbTopMenuClasses = useBreadcrumbTopMenuStyles();
  const { t } = useTranslation('common');

  return <div className={breadcrumbTopMenuClasses.root}>
    {props.pathname !== '/dashboard' &&
        <>
          <span className={breadcrumbTopMenuClasses.link} onClick={() => props.navigate('dashboard')}>
            Accueil
          </span>
          <img className={breadcrumbTopMenuClasses.img} width={7.5} src={rightArrow}  alt='Flèche droite' />
        </>
    }
    <span className={breadcrumbTopMenuClasses.currentPage}>{t(props.pathname.substring(1).toLocaleLowerCase())}</span>
  </div>;
}
