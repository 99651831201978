import {
  BeneficiaryModel,
  DeactivationPolicyType,
} from '../../../assets/models/beneficiaries/Beneficiary.model';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useBeneficiariesTakeOutStyles } from './BeneficiaryTakeOutStyles';
import warningBlue from '../../../assets/warningBlue.svg';
import wallet from '../../../assets/wallet.svg';
import calendar from '../../../assets/calendar.svg';
import InputComponent from '../../../Component/InputComponent';
import Button from '../../../Style/MuiStyles/Button';
import { getEndOfMillesimeDate } from '../../../assets/utils/millesime/millesime.util';
import { manageBeneficiaryDeactivationSchedule } from '../../../Services/beneficiariesAPI.service';
import { useNavigate } from 'react-router';
import StyledSwitch from '../../../Style/MuiStyles/Switch';
import { NavigateFunction } from 'react-router-dom';
import { getTimeStampEndOfDay } from '../../../assets/utils/dates/getParsedDate.util';
import {
  ManageBeneficiaryDeactivationScheduleRequest,
} from '../../../assets/requests/beneficiaries/ManageBeneficiaryDeactivationSchedule.request';
import { obfuscateIbanNumbersUtil } from '../../../assets/utils/databank/obfuscateIbanNumbers.util';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  allowToModifyBeneficiaryDeactivationConfig,
} from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';
import {
  FormControlLabel,
  Radio,
} from '@material-ui/core';

export interface IBeneficiaryTakeOutComponentProps {
  beneficiary?: BeneficiaryModel;
  onClose: () => void;
}

export interface IFormErrors {
  deactivationDate?: string;
  deactivationPolicy?: string;
  haveAbandonedRights?: boolean;
  consumptionRightLimitDate?: string;
}

const timestampToDateString = (timestamp: number): string => {
  return new Date(timestamp).toISOString().replace(/T.+$/, '');
};

const BeneficiaryTakeOutComponent: FunctionComponent<IBeneficiaryTakeOutComponentProps> = observer((props: IBeneficiaryTakeOutComponentProps) => {
  const { beneficiary }: IBeneficiaryTakeOutComponentProps = props;
  const beneficiaryId: string = beneficiary.uid;
  const initialValues: {
    deactivationDate: string,
    deactivationPolicy: DeactivationPolicyType,
    consumptionRightLimitDate: string
  } = useMemo(() => ({
    deactivationDate: beneficiary.deactivationDate ? timestampToDateString(beneficiary.deactivationDate) : timestampToDateString(Date.now()),
    consumptionRightLimitDate: beneficiary.consumptionRightLimitDate ? timestampToDateString(beneficiary.consumptionRightLimitDate) : '',
    deactivationPolicy: beneficiary.deactivationPolicy || 'ABANDON_RIGHTS',
  }), [beneficiary]);

  const disableModification: boolean = useMemo(() => {
    return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
  }, [beneficiary]);

  const { t } = useTranslation('beneficiaryTakeOut');
  const [takeOut, setTakeOut] = useState<boolean>(!!beneficiary.deactivationDate);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasRestitutionFailed, setHasRestitutionFailed] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<DeactivationPolicyType>(beneficiary.deactivationPolicy || 'ABANDON_RIGHTS');
  const [formErrors, setFormErrors] = useState<IFormErrors>({});
  const [formValues, setFormValues] = useState(initialValues);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate: NavigateFunction = useNavigate();
  const beneficiariesTakeOutStyles = useBeneficiariesTakeOutStyles({
    disabled: disableModification,
    formErrors,
    hasRestitutionFailed,
  });

  const hasChanges: boolean = useMemo(() => {
    return takeOut !== !!beneficiary.deactivationDate
      || formValues.deactivationDate !== initialValues.deactivationDate
      || formValues.consumptionRightLimitDate !== initialValues.consumptionRightLimitDate
      || formValues.deactivationPolicy !== initialValues.deactivationPolicy;
  }, [beneficiary, formValues, takeOut]);

  const generateRestitutionStatement = useMemo(() => {
    setHasRestitutionFailed(beneficiary.deactivationDetails?.state === 'PENDING');
    if (beneficiary.deactivationDetails?.state === 'PENDING') {
      return <p>{`Il n’y avait plus assez de provisions dans votre portefeuille Openeat le ${new Date(beneficiary.deactivationDetails?.lastExecutionDate).toLocaleDateString() || 'Date non valide'} pour vous restituer les ${beneficiary.deactivationDetails?.restitutionAmount?.toFixed(2)} € du solde non consommé du bénéficiaire`}</p>;
    }
    if (!beneficiary.deactivationDetails?.restitutionAmount || beneficiary?.deactivationDetails?.restitutionAmount === 0) {
      return <p>Le solde du collaborateur était de 0,00 € et n’a pas donné lieu à une restitution.</p>;
    } else {
      const formattedAmount = `${beneficiary.deactivationDetails?.restitutionAmount?.toFixed(2) || ''} €.`;
      let statement =
        <span>L'entreprise a récupéré les droits non consommés par le collaborateur soit {formattedAmount}</span>;

      switch (beneficiary.deactivationDetails.restitutionMethod) {
        case 'DIRECT_DEBIT_IBAN_PAYOUT':
          return (
            <>
              {statement}
              <br/>
              <p className={beneficiariesTakeOutStyles.restitutionDetail}>Virement sur le compte de prélèvement.</p>
            </>
          );
        case 'RESTITUTION_IBAN_PAYOUT':

          return (
            <>
              {statement}
              <br/>
              <p className={beneficiariesTakeOutStyles.restitutionDetail}>Virement sur le compte de
                l'IBAN {obfuscateIbanNumbersUtil(beneficiary.deactivationDetails.restitutionIban)}.</p>
            </>
          );
        case 'PROVISIONS_WALLET_DEPOSIT':
          return (
            <>
              {statement}
              <br/>
              <p className={beneficiariesTakeOutStyles.restitutionDetail}>Le montant de ces droits est conservé sur le
                portefeuille de provision.</p>
            </>
          );
        default:
          return statement;
      }
    }
  }, [beneficiary, beneficiary?.deactivationDetails?.restitutionMethod]);

  const validateSubmitedForm = () => {
    const deactivationDateValue: string = formValues.deactivationDate;
    const consumptionRightLimitDateValue: string = formValues.consumptionRightLimitDate;

    const deactivationDate: Date = new Date(deactivationDateValue);
    const consumptionRightLimitDate: Date = new Date(consumptionRightLimitDateValue);

    const deactivationDateTime: number = deactivationDate.getTime();
    const consumptionRightLimitDateTime: number = consumptionRightLimitDate.getTime();

    const endMillesimeDate: Date = getEndOfMillesimeDate(deactivationDate);
    const endMillesimeDateTime: number = endMillesimeDate.getTime();

    if (!takeOut) {
      return false;
    }

    if (!deactivationDate) {
      setFormErrors({
        deactivationDate: 'La date de désactivation est requise',
      });
      return true;
    } else if (consumptionRightLimitDateTime > endMillesimeDateTime) {
      setFormErrors({
        consumptionRightLimitDate: `Cette date ne peut être supérieure à la fin de millésime (${timestampToDateString(endMillesimeDateTime)}).`,
      });
      return true;
    } else if (consumptionRightLimitDateTime < deactivationDateTime) {
      setFormErrors({
        consumptionRightLimitDate: 'Cette date doit être supérieure ou égale à la date de sortie du dispositif',
      });
      return true;
    } else if (selectedOption === 'KEEP_RIGHTS' && !consumptionRightLimitDateValue) {
      setFormErrors({
        consumptionRightLimitDate: 'Ce champs est requis',
      });
      return true;
    }

    return false;
  };

  useEffect(() => {
    const deactivationDate: Date = new Date(formValues.deactivationDate);
    const consumptionRightLimitDate: Date = new Date(formValues.consumptionRightLimitDate);

    const consumptionRightLimitDateTime: number = consumptionRightLimitDate.getTime();

    const endMillesimeDate: Date = getEndOfMillesimeDate(deactivationDate);
    const endMillesimeDateTime: number = endMillesimeDate.getTime();

    if (consumptionRightLimitDateTime > endMillesimeDateTime) {
      setFormErrors({
        consumptionRightLimitDate: `Cette date ne peut être supérieure à la fin de millésime (${timestampToDateString(endMillesimeDateTime)}).`,
      });
      return;
    }
    setFormErrors({});
  }, [formValues.consumptionRightLimitDate, formValues.deactivationDate, takeOut]);

  const handleSubmit = useCallback(async (e) => {
    if (isSaving) {
      return;
    }
    e.preventDefault();
    const hasFormErrors = validateSubmitedForm();

    if (!hasFormErrors) {
      let formattedRequest: ManageBeneficiaryDeactivationScheduleRequest = {
        enableDeactivation: takeOut,
      };

      if (takeOut) {
        formattedRequest = {
          ...formattedRequest,
          deactivationDate: new Date(formValues.deactivationDate).getTime(),
          deactivationPolicy: formValues.deactivationPolicy,
          consumptionRightLimitDate: formValues.deactivationPolicy === 'KEEP_RIGHTS' && formValues.consumptionRightLimitDate
            ? getTimeStampEndOfDay(new Date(formValues.consumptionRightLimitDate).getTime())
            : undefined,
        };
      }

      setIsSaving(true);
      await manageBeneficiaryDeactivationSchedule(beneficiaryId, formattedRequest);
      setShowSnackbar(true);
      setIsSaving(false);
    }
    return;
  }, [formValues, takeOut]);

  const resetFormValues = useCallback(() => {
    setFormValues(initialValues);
    setFormErrors({});
    setTakeOut(!!beneficiary.deactivationDate);
    setSelectedOption(beneficiary.deactivationPolicy || 'ABANDON_RIGHTS');
    setHasRestitutionFailed(false);
  }, [beneficiary, initialValues]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={beneficiariesTakeOutStyles.containerFistBlock}>
          <div className={beneficiariesTakeOutStyles.containerWarning}>
            <img alt="icon-warning" className={beneficiariesTakeOutStyles.imgWarning} src={warningBlue}/>
            {!disableModification ?
              (
                <p className={beneficiariesTakeOutStyles.textWarning}>
                  En sortant le collaborateur du dispositif, vous ne pourrez plus commander pour ce bénéficiaire.<br/>
                  Cette action est définitive.
                </p>
              ) : (
                <p className={beneficiariesTakeOutStyles.textWarning}>Ce collaborateur est sortit du dispositif, il ne
                  sera pas présent dans la liste des bénéficiaires de
                  vos prochaines commandes.
                </p>
              )
            }
          </div>

          <div className={beneficiariesTakeOutStyles.leaveContainer}>
            <p>
              Sortir le collaborateur du dispositif
            </p>
            <div className={beneficiariesTakeOutStyles.switchOpacity}>
              <StyledSwitch
                disabled={disableModification}
                checked={takeOut}
                onChange={() => setTakeOut(!takeOut)}
              />
            </div>

            <div style={{ width: '358px', position: 'relative' }}>
              {takeOut && <InputComponent
                  className={beneficiariesTakeOutStyles.deactivationDateInput}
                  disabled={disableModification}
                  type={'date'}
                  icon={calendar}
                  onChange={(value) => {
                    setFormValues({ ...formValues, deactivationDate: value });
                  }}
                  title={'Date de sortie du dispositif'}
                  isRequired={!disableModification}
                  value={formValues.deactivationDate}
                  intent={disableModification ? '' : 'primary'}
              />
              }
            </div>
          </div>
        </div>
        {takeOut && (
          <hr/>
        )}
        <div>
          {takeOut && (
            <p className={beneficiariesTakeOutStyles.titleSecondBlock}>
              Récupération du solde de collaborateur
            </p>
          )}
          {takeOut && (
            <div className={beneficiariesTakeOutStyles.containerRadioButton}>

              <FormControlLabel control={(
                <Radio
                  style={{
                    color: hasChanges ? '#2E4052' : '#aaa',
                  }}
                  disabled={disableModification}
                  checked={selectedOption === 'ABANDON_RIGHTS'}
                  onClick={() => {
                    setSelectedOption('ABANDON_RIGHTS');
                    setFormValues({ ...formValues, deactivationPolicy: 'ABANDON_RIGHTS' });
                  }
                  }
                  className={beneficiariesTakeOutStyles.customRadio}
                  name="radio"
                  id={'radio'}
                  value={'ABANDON_RIGHTS'}
                />)}
                                label={<span className={beneficiariesTakeOutStyles.label}>Le collaborateur renonce à ses droits</span>}
              />
              <p className={beneficiariesTakeOutStyles.paragrapheRadio}>
                Le collaborateur n’aura plus accés à l’application Openeat à sa sortie du dispositif.
              </p>
            </div>
          )}

          {takeOut && (
            <div className={beneficiariesTakeOutStyles.radioButtonWithDate}>
              <div className={beneficiariesTakeOutStyles.containerRadioButton}>
                <FormControlLabel
                  control={<Radio
                    disabled={disableModification}
                    checked={selectedOption === 'KEEP_RIGHTS'}
                    className={beneficiariesTakeOutStyles.customRadio}
                    onChange={() => {
                      setSelectedOption('KEEP_RIGHTS');
                      setFormValues({ ...formValues, deactivationPolicy: 'KEEP_RIGHTS' });
                    }}
                    style={{ color: hasChanges ? '#2E4052' : '#aaa' }}
                    value={'KEEP_RIGHTS'}
                    name="radio"
                    id={'radio'}
                  />}
                  label={<span className={beneficiariesTakeOutStyles.label}>Le collaborateur garde ses droits jusqu’à une certaine date</span>}
                />
                <p className={beneficiariesTakeOutStyles.paragrapheRadio}>
                  Le collaborateur peut utiliser l’application Openeat et consommer son solde jusqu’à la date limite de
                  consommation des droits.
                </p>
              </div>
              {selectedOption === 'KEEP_RIGHTS' && (
                <div className={beneficiariesTakeOutStyles.containerDateKeepRights}>
                  <InputComponent
                    className={beneficiariesTakeOutStyles.consumptionRightLimitDateInput}
                    type="date"
                    icon={calendar}
                    onChange={(value) => setFormValues({ ...formValues, consumptionRightLimitDate: value })}
                    title={'Date limite de consommation des droits'}
                    isRequired={!disableModification}
                    value={formValues.consumptionRightLimitDate}
                    disabled={disableModification}
                    intent={disableModification ? '' : 'primary'}
                  />
                  {formErrors.consumptionRightLimitDate &&
                      <p className={beneficiariesTakeOutStyles.errorSelectedDate}>
                        {formErrors.consumptionRightLimitDate}
                      </p>
                  }
                </div>
              )}
            </div>
          )}

        </div>
        {disableModification && (
          <div className={beneficiariesTakeOutStyles.containerRestitutionStatement}>
            <div className={beneficiariesTakeOutStyles.imgWalletContainer}>
              {hasRestitutionFailed
                ? <img alt="warning"
                       className={beneficiariesTakeOutStyles.imgBlackWarning}
                       src={warningBlue}/>
                : <img alt="icon-wallet"
                       className={beneficiariesTakeOutStyles.imgWallet}
                       src={wallet}/>}
            </div>
            <div className={beneficiariesTakeOutStyles.restitutionStatement}>{generateRestitutionStatement}</div>
          </div>
        )}
        {takeOut && (
          <p onClick={() => navigate('/agency')} className={beneficiariesTakeOutStyles.paragrapheRefund}>
            Accéder aux paramètres de remboursement de l'entreprise
          </p>
        )}
        <div className={beneficiariesTakeOutStyles.buttonsBlock}>
          {hasChanges && (
            <>
              <Button
                onClick={resetFormValues}
                type="button"
                variant="contained"
                color="secondary"
                disabled={!hasChanges || isSaving}
              >
                Non, annuler
              </Button>

              <Button
                onClick={() => null}
                variant="contained"
                color="primary"
                type="submit"
                isFetching={isSaving}
                disabled={!hasChanges || isSaving}
              >
                Oui, je confirme
              </Button>
            </>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
        >
          <SnackbarContent
            className={beneficiariesTakeOutStyles.snackbarContent}
            message={
              <div className={beneficiariesTakeOutStyles.snackbarMessage}>
                {t('confirmation')}
              </div>
            }/>
        </Snackbar>
      </form>
    </>
  );
});
export default BeneficiaryTakeOutComponent;