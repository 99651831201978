import { FunctionsErrorCode } from './manageError.util';

export class DomainError extends Error {
  readonly statusCode: number;
  readonly time: Date;
  readonly code: FunctionsErrorCode;
  readonly messageToDisplay: string;

  constructor(message: string, code: FunctionsErrorCode, statusCode: number) {
    super(message);
    this.messageToDisplay = message;
    this.code = code;
    this.statusCode = statusCode;
    this.time = new Date();
  }
}
