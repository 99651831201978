const accounting = {
  creationDateLabel_STANDARD: 'Date de la facture',
  creationDateLabel_DISSOCIATED_FEES: 'Date de la facture',
  creationDateLabel_DISSOCIATED_PROVISIONS: 'Date du relevé',
  invoiceTitle_STANDARD: 'Facture N°',
  invoiceTitle_DISSOCIATED_FEES: 'Facture N°',
  invoiceTitle_DISSOCIATED_PROVISIONS: 'Relevé N°',
  amount_STANDARD: 'Montant TTC',
  amount_DISSOCIATED_FEES: 'Montant TTC',
  amount_DISSOCIATED_PROVISIONS: 'Montant HT',
  seeDetails_STANDARD: 'Voir le détail',
  seeDetails_DISSOCIATED_FEES: 'Détail de la facture des abonnements',
  seeDetails_DISSOCIATED_PROVISIONS: 'Détail du relevé des provisions',
  toPay: 'En attente de {{mode}}',
  paidDirectDebit: 'Prélevé le',
  paidBankTransfer: 'Virement effectué le',
  pageTitle: 'Documents comptables',
  pageSubtitle: 'Liste des documents Open!Eat',
  since6months: 'Depuis 6 mois',
  since1year: 'Depuis 1 an',
  since2years: 'Depuis 2 ans',
  sinceBeginning: 'Depuis le début',
  directDebit: 'Prélevé le',
  directDebitRef: 'Bordereau de versement n°',
  invoiceSearchPlaceholder: 'F123456, 10/11/2021,...',
  provisionSearchPlaceholder: 'P123456, 10/11/2021, 123,00 €...',
  invoiceDetails: {
    title_STANDARD: 'Détail de la facture n°{{reference}} du {{date}}',
    title_DISSOCIATED_FEES: 'Détail de la facture n°{{reference}} du {{date}}',
    title_DISSOCIATED_PROVISIONS: 'Détail du relevé de provision n°{{reference}} du {{date}}',
    subtitle: 'Retrouvez les informations concernant votre facture',
    statusPaidDirectDebit: 'Prélevé le ',
    statusPaidBankTransfer: 'Virement effectué le ',
    statusToPay_STANDARD: 'Facture en attente de paiement par {{mode}}',
    statusToPay_DISSOCIATED_FEES: 'Facture en attente de paiement par {{mode}}',
    statusToPay_DISSOCIATED_PROVISIONS: 'Relevé en attente de paiement par {{mode}}',
    provision: 'Montant de la participation employeur',
    subscriptionTitle_one: 'Titres restaurant : Abonnement pour {{count}} collaborateur pour {{month}}',
    subscriptionTitle_other: 'Titres restaurant : Abonnement pour {{count}} collaborateurs pour {{month}}',
    subscription: 'Frais d’abonnement',
    provisionTitle: 'Abondement de la participation employeur pour {{month}}',
    reloading: 'Basé sur le rechargement N° {{reference}} du {{date}}',
    excl: 'HT',
    incl: 'TTC',
    transaction_one: '{{count}} collaborateur x',
    transaction_other: '{{count}} collaborateurs ×',
    totalExcl: 'Total HT',
    total: 'Net à payer : Total abonnements TTC + Part Employeur',
    vat: 'TVA',
    totalFees_STANDARD: 'Net à payer abonnement',
    totalFees_DISSOCIATED_FEES: 'Net à payer',
    totalProvisions: 'Net à payer part employeur',
    download_STANDARD: 'Télécharger la facture',
    download_DISSOCIATED_FEES: 'Télécharger la facture',
    download_DISSOCIATED_PROVISIONS: 'Télécharger le relevé',
    expectedDescriptorLabel: 'Référence à rappeler :',
    close: 'Fermer',
  },
};

export default accounting;
