import { action, makeAutoObservable } from 'mobx';

export class AgencyViewStore {
  tabNumber: number = 0;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setTabNumber(number: number) {
    this.tabNumber = number;
  }
}
