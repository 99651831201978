import { makeStyles } from '@material-ui/core';
import { COLORS, HOVER } from '../../Style/Style';

export const useOrderParamsStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column' ,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0',
  },
  content: {
    backgroundColor: COLORS.backgroundBlock,
    border: `1px solid ${COLORS.borderBlock}`,
    '&:hover': {
      boxShadow: HOVER.blockShadow
    },
    borderRadius: '10px',
    height: '250px',
    width: '600px',
    boxSizing: 'border-box',
    margin: '1rem 0',
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  contentMonth: {
    position: 'relative',
    backgroundColor: COLORS.backgroundBlock,
    border: `1px solid ${COLORS.borderBlock}`,
    '&:hover': {
      boxShadow: HOVER.blockShadow
    },
    borderRadius: '10px',
    width: '600px',
    boxSizing: 'border-box',
    margin: '1rem 0',
    padding: '3rem 2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  block: {
    borderRadius: '10px',
    height: '250px',
    width: '250px',
    boxSizing: 'border-box',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  icon: { textAlign: 'center' },
  iconSelect: { textAlign: 'center', paddingRight: '1rem' },
  unit: { fontSize: '30px', fontWeight: 'bold', marginLeft: '1rem' },
  description: { textAlign: 'center', fontSize: '14px' },
  buttons: { display: 'flex', justifyContent: 'right', marginBottom: '40px' },
  selectOnError: {
    borderColor: COLORS.error,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
  },
  errorMessage: {
    position: 'absolute',
    bottom: -30,
    left: 0,
    color: COLORS.error,
    fontSize: 14
  }
}));
