import { makeStyles } from '@material-ui/core';
import { COLORS, FONT } from '../../Style/Style';

export const useTopMenuStyles = makeStyles((theme) => ({
  root: {
    height: '7rem',
    padding: '2rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F7F7F8',
    justifyContent: 'space-between',
    fontFamily: FONT.primary,
    color: COLORS.textPrimary
  },
  blockLeft: {
    display: 'flex',
    flexDirection: 'row'
  },
  agency: {
    background: 'linear-gradient(to right, #F7F7F8 50%, white 50%)'
  },
  blockRight: {
    display: 'flex',
    flexDirection: 'row'
  },
  initial: {
    backgroundColor: '#EDEFF0',
    width: '50px',
    borderRadius: '50px',
    display: 'flex',
    placeContent: 'center',
    placeItems: 'center',
    fontSize: '20px',
    marginRight: '20px',
    fontWeight: 'bold'
  },
  blockName: {
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontSize: '14px',
    lineHeight: '17px'
  },
  role: {
    backgroundColor: '#EDEFF0',
    fontSize: '12px',
    padding: '9px',
    borderRadius: '6px',
    height: '27px',
    display: 'flex',
    placeItems: 'center'
  },
  menu: {
    margin: '0 5rem 0 2rem',
    display: 'flex',
    justifyItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    width: '15px',
    height: '9px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));
