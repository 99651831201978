import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../../Style/Style';

export const useAllocatedRightChartStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '350px'
  },
  chartBlock: {
    width: '720px'
  },
  titleBlock: {
    marginBottom: '-70px'
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '10px 0px 5px 80px',
    color: COLORS.textPrimary
  },
  rightBlock: {
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    flex: '1',
    height: '100%'
  },
  rightBlockTitle: {
    width: '300px',
    color: COLORS.textPrimary,
    fontSize: '24px',
    fontWeight: 'bold'
  },
  rightBlockSubtitle: {
    width: '300px',
    fontSize: '14px',
    color: '#A3AAB2',
    marginBottom: '30px'
  }
}));
