import { makeAutoObservable } from 'mobx';
import { DomainError } from '../assets/utils/errors/domainError.util';

export class ErrorStore {
  error: any | Error | null = null;

  get messageToDisplay() {
    const domainError: DomainError = this.error?.response?.data;
    return domainError?.messageToDisplay || this.error.message;
  }

  constructor() {
    makeAutoObservable(this);
  }

  catchError(error: any | Error) {
    this.error = error;
  }

  dismiss(error: any | Error) {
    if (this.error === error) {
      this.error = null;
    }
  }

  clear() {
    this.error = null;
  }
}

export const errorStore = new ErrorStore();