import { BeneficiaryModel } from '../assets/models/beneficiaries/Beneficiary.model';
import { CreateBeneficiaryRequest } from '../assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '../assets/requests/beneficiaries/UpdateBeneficiary.request';
import { UpdateBeneficiaryActivityRequest } from '../assets/requests/beneficiaries/UpdateBeneficiaryActivityRequest';
import { UpdateBeneficiaryRefRequest } from '../assets/requests/beneficiaries/UpdateBeneficiaryRef.request';
import { UploadBeneficiaryRequest } from '../assets/requests/beneficiaries/UploadBeneficiary.request';
import { UpdateBeneficiaryIBANRequest } from '../assets/requests/billings/UpdateBeneficiaryIBAN.request';
import {
  SendMailsToUnboardedBeneficiariesRequest,
} from '../assets/requests/emails/SendMailsToUnboardedBeneficiaries.request';
import { BeneficiariesUploadResponse } from '../assets/responses/beneficiaries/BeneficiariesUpload.response';
import { ConfirmationResponse } from '../assets/responses/Confirmation.response';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '../assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import { callable } from './httpWrapper.service';
import {
  UpdateBeneficiaryEmailRequest
} from "../assets/requests/beneficiaries/UpdateBeneficiaryEmail.request";
import {
  ManageBeneficiaryDeactivationScheduleRequest
} from '@assets/requests/beneficiaries/ManageBeneficiaryDeactivationSchedule.request';

export async function getAllBeneficiariesByAgencyId(agencyId: string) {
  return callable(
    'getAllBeneficiariesByAgencyId',
    { agencyId },
  );
}

export async function getBeneficiariesCountByAgencyId(agencyId: string) {
  return callable(
    'getBeneficiariesCountByAgencyId',
    { agencyId },
  );
}

export async function getBeneficiaryById(beneficiaryId: string) {
  return callable(
    'getOneBeneficiaryByIdForAdmin',
    { beneficiaryId },
  );
}

export async function createOneBeneficiary(agencyId: string, createBeneficiaryRequest: CreateBeneficiaryRequest): Promise<BeneficiaryModel> {
  return callable(
    'createOneBeneficiary',
    { agencyId },
    createBeneficiaryRequest,
  );
}

export async function updateOneBeneficiary(agencyId: string, beneficiaryId: string, updateBeneficiaryRequest: UpdateBeneficiaryRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateOneBeneficiary',
    { agencyId, beneficiaryId },
    updateBeneficiaryRequest,
  );
}

export async function updateBeneficiaryActivities(beneficiaryId: string, updateBeneficiaryActivityRequest: UpdateBeneficiaryActivityRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateBeneficiaryActivity',
    { beneficiaryId },
    updateBeneficiaryActivityRequest,
  );
}

export async function updateBeneficiaryRegistrationNumber(agencyId: string, beneficiaryId: string, updateBeneficiaryRefRequest: UpdateBeneficiaryRefRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateBeneficiaryRef',
    { agencyId, beneficiaryId },
    updateBeneficiaryRefRequest,
  );
}

export async function uploadBeneficiaries(agencyId: string, uploadBeneficiariesRequest: UploadBeneficiaryRequest[]): Promise<BeneficiariesUploadResponse> {
  return callable(
    'uploadBeneficiaries',
    { agencyId },
    uploadBeneficiariesRequest,
  );
}

export async function sendMailsToUnOnboardedBeneficiaries(request:SendMailsToUnboardedBeneficiariesRequest): Promise<SendMailsToUnboardedBeneficiariesResponse> {
  return callable(
    'sendMailsToUnOnboardedBeneficiaries',
    request,
  );
}

export async function updateBeneficiaryEmailForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryEmailRequest): Promise<ConfirmationResponse> {
  return callable(
      'updateBeneficiaryEmailForAgency',
      { agencyId, beneficiaryId },
      request,
  );
}

export async function updateBeneficiaryIbanForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryIBANRequest): Promise<ConfirmationResponse> {
  return callable(
      'updateBeneficiaryIbanForAgency',
      { agencyId, beneficiaryId },
      request,
  );
}

export async function manageBeneficiaryDeactivationSchedule(beneficiaryId: string, request: ManageBeneficiaryDeactivationScheduleRequest): Promise<ConfirmationResponse> {
  return  callable(
    'manageBeneficiaryDeactivationSchedule',
    {beneficiaryId},
    request,
  )
}
