import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import closeIcon from '../../assets/close.svg';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import { CountryCodeType } from '../../assets/models/specials/CountryCode.model';
import { GenderModel } from '../../assets/models/specials/Gender.model';
import { ListOfCountryCode } from '../../assets/models/specials/listOfCountryCode';
import { SelectCountryCodeModel } from '../../assets/models/specials/selectListData.model';
import { UploadBeneficiaryRequest } from '../../assets/requests/beneficiaries/UploadBeneficiary.request';
import { BeneficiariesUploadResponse } from '../../assets/responses/beneficiaries/BeneficiariesUpload.response';
import { getTimeStampStartOfDay } from '../../assets/utils/dates/getParsedDate.util';
import { getPaginatedMap } from '../../assets/utils/functions/getPaginatedMap.util';
import warningIcon from '../../assets/warning.svg';
import FileUploadManager from '../../Component/Dropzone/FileUploadManager/FileUploadManager';
import SnackErrorComponent from '../../Component/SnackbarError/SnackErrorComponent';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import ButtonComponent from '../../Style/MuiStyles/Button';
import {
  hasDataTheCorrectNumberOfColumns,
  removeEmptyLines,
  removeEmptyLinesFromTestResult,
  trimCells,
} from '../../Utils/CsvManipulation.service';
import {
  analyseUploadingFile,
  CsvRowEntity,
  FieldTestReport,
  RowTestResult,
  TestSet,
} from '../../Utils/Tester/BaseTester.service';
import {
  BeneficiaryExistsStatus,
  BeneficiaryRowTestResult,
  CsvBeneficiary,
  getUploadBeneficiaryTestSet,
  uploadBeneficiaryKeysMap,
} from '../../Utils/Tester/BeneficiaryTester.service';
import { AddBeneficiariesAnalysisManager } from './AddBeneficiariesAnalysisManager/AddBeneficiariesAnalysisManager';
import { useAddBeneficiariesAnalysisManager } from './AddBeneficiariesAnalysisManager/AddBeneficiariesFromCsvError';
import { addBeneficiariesByUploadStyles } from './AddBeneficiariesByUploadStyles';
import { AddBeneficiariesUploadManager } from './AddBeneficiariesUploadManager/AddBeneficiariesUploadManager';
import moment from 'moment';
import { CreateBeneficiaryRequest } from '../../assets/requests/beneficiaries/CreateBeneficiary.request';
import helpUtf8 from '../../assets/helpUtf8.png';
import circleCross from '../../assets/circleCros.png';

export interface IAddBeneficiariesByUploadComponentProps {
  onClose: () => void;
}

function isBeneficiaryExists(isRegistrationNumberExists: boolean, isEmailExists: boolean, rowTestResult: RowTestResult<CsvBeneficiary>, existingBeneficiaryList: BeneficiaryModel[]): BeneficiaryExistsStatus {
  if (Object.values(rowTestResult.csvEntity).every((field: string) => field.trim() === '')) {
    return 'EMPTY_LINE';
  }
  if (!isRegistrationNumberExists && !isEmailExists) {
    return 'USER_UNKNOWN';
  }

  if (isRegistrationNumberExists && isEmailExists) {
    const { csvEntity }: RowTestResult<CsvBeneficiary> = rowTestResult;

    const userExist: boolean = existingBeneficiaryList.some((beneficiary: BeneficiaryModel) => {
      return beneficiary.registrationNumber === csvEntity.registrationNumber
        && beneficiary.email === csvEntity.email;
    });

    return userExist ? 'USER_EXISTS' : 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS';
  }

  return 'REGISTRATION_NUMBER_OR_EMAIL_EXISTS';
}

const REQUIRED_COLUMNS_COUNT: number = 14;

type LocalStore = [AgenciesStore, BeneficiariesStore];

const AddBeneficiariesByUploadComponent: FunctionComponent<IAddBeneficiariesByUploadComponentProps> =
  observer((props: IAddBeneficiariesByUploadComponentProps) => {
    const [
      agenciesStore,
      beneficiariesStore,
    ]: [AgenciesStore, BeneficiariesStore] =
      useInstances<LocalStore>(AgenciesStore, BeneficiariesStore);

    const beneficiariesByUploadStyles = addBeneficiariesByUploadStyles();
    const analysisManagerStyles = useAddBeneficiariesAnalysisManager();
    const { t } = useTranslation('beneficiaries');

    const existingBeneficiaryList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
    const [file, setFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [canBeUploaded, setCanBeUploaded] = useState<boolean>(false);
    const [beneficiaryFileTestResult, setBeneficiaryFileTestResult] = useState<BeneficiaryRowTestResult[]>([]);
    const [beneficiariesUploadedResponse, setBeneficiariesUploadedResponse] = useState<BeneficiariesUploadResponse>();
    const [showNewTestResult, setShowNewTestResult] = useState<boolean>(false);
    const [showAnalyseFileResult, setShowAnalyseFileResult] = useState<boolean>(false);
    const [showResultAnalyseCsvFile, setShowResultAnalyseCsvFile] = useState<boolean>(true);
    const [showResultUploadCsvFile, setShowResultUploadCsvFile] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [undefinedKey, setUndefinedKey] = useState<string>('');
    const [duplicateRegistrationNumbers, setDuplicateRegistrationNumbers] = useState<string[]>([]);
    const [hasErrorDuplicateRegistrationNumber, setHasErrorDuplicateRegistrationNumber] = useState<boolean>(false);
    const [hasErrorOfNbColumn, setHasErrorOfNbColumn] = useState<boolean>(false);
    const [isUTF8, setIsUTF8] = useState<boolean>(true);
    const [duplicateEmails, setDuplicateEmails] = useState<string[]>([]);
    const [hasErrorDuplicateEmail, setHasErrorDuplicateEmail] = useState<boolean>(false);

    const uploadBeneficiaryTestSet: TestSet<CsvBeneficiary> = useMemo(() => {
      return getUploadBeneficiaryTestSet(existingBeneficiaryList);
    }, [existingBeneficiaryList]);

    useEffect(() => {
      setIsLoading(false);
    }, [beneficiaryFileTestResult]);

    useEffect(() => {
      setBeneficiaryFileTestResult([]);
      setDuplicateEmails([]);
      setDuplicateRegistrationNumbers([]);
      setCanBeUploaded(false);
      setShowResultAnalyseCsvFile(true);
      setShowResultUploadCsvFile(false);
      setHasError(false);
      setErrorMessage('');
      setHasErrorOfNbColumn(false);
      setShowAnalyseFileResult(false);
      setHasErrorDuplicateEmail(false);
      setHasErrorDuplicateRegistrationNumber(false);
      setIsUTF8(true);
    }, [file]);

    const testFileIsUTF8: (fileContent: string[][]) => boolean = useCallback((fileContent: string[][]) => {
      const test: boolean = fileContent.every(row => row.every(cell => !/\uFFFD/.test(cell)));
      setIsUTF8(test);
      return test;
    }, []);

    function analyseBeneficiaryUploadingFile(csvData: string[][], testSet: TestSet<CsvBeneficiary>): void {

      const result: boolean = testFileIsUTF8(csvData);
      if (!result) {
        return;
      }

      const [headersRow, ...dataRows]: string[][] = trimCells(csvData);
      const cleanDataRows: string[][] = removeEmptyLines(dataRows);
      const hasCorrectNumberOfColumns: boolean = hasDataTheCorrectNumberOfColumns(headersRow, cleanDataRows, REQUIRED_COLUMNS_COUNT);

      if (!hasCorrectNumberOfColumns
        || headersRow.every((header: string) => header === '')
        || !cleanDataRows.length) {
        setHasErrorOfNbColumn(true);
        setShowAnalyseFileResult(true);
        return;
      }

      const registrationNumberCounters = cleanDataRows
        .reduce((counters: { [registrationNumber: string]: number }, [registrationNumber]: string[]) => {
          if (!counters[registrationNumber]) {
            counters[registrationNumber] = 0;
          }
          counters[registrationNumber] += 1;
          return counters;
        }, {});

      const duplicatedRegistrationNumbers: string[] = Object.keys(registrationNumberCounters)
        .filter((registrationNumber: string) => registrationNumberCounters[registrationNumber] > 1);

      setDuplicateRegistrationNumbers(duplicatedRegistrationNumbers);
      const hasDuplicateRegistrationNumber: boolean = duplicatedRegistrationNumbers.length > 0;
      setHasErrorDuplicateRegistrationNumber(hasDuplicateRegistrationNumber);

      const duplicatedMailsCounter = cleanDataRows
        .reduce((counters: { [mailCounter: string]: number }, [, email]: string[]) => {
          if (!counters[email]) {
            counters[email] = 0;
          }
          counters[email] += 1;
          return counters;
        }, {});

      const duplicatedEmails: string[] = Object.keys(duplicatedMailsCounter)
        .filter((email: string) => duplicatedMailsCounter[email] > 1);

      setDuplicateEmails(duplicatedEmails);
      const hasDuplicateEmail: boolean = duplicatedEmails.length > 0;
      setHasErrorDuplicateEmail(hasDuplicateEmail);

      try {
        const fileTestResult: RowTestResult<CsvBeneficiary>[] = analyseUploadingFile(headersRow, cleanDataRows, testSet, uploadBeneficiaryKeysMap);
        const beneficiaryFileTestResult: BeneficiaryRowTestResult[] = fileTestResult.map((rowTestResult: RowTestResult<CsvBeneficiary>): BeneficiaryRowTestResult => {
          const { firstName, lastName }: CsvRowEntity<CsvBeneficiary> = rowTestResult.csvEntity;

          const isRegistrationNumberExists: boolean = rowTestResult.errors
            .some((field: FieldTestReport<CsvBeneficiary>) => field.errorMessage === 'REGISTRATION_NUMBER_ALREADY_TAKEN');

          const isEmailExists: boolean = rowTestResult.errors
            .some((field: FieldTestReport<CsvBeneficiary>) => field.errorMessage === 'EMAIL_ALREADY_TAKEN');

          const userValidity: BeneficiaryExistsStatus = isBeneficiaryExists(isRegistrationNumberExists, isEmailExists, rowTestResult, existingBeneficiaryList);

          if (userValidity === 'USER_EXISTS') {
            rowTestResult.errors = rowTestResult.errors
              .filter((error: FieldTestReport<CsvBeneficiary>) => {
                return error.fieldName !== 'registrationNumber'
                  && error.fieldName !== 'email'
                  && error.fieldName !== 'firstRightDate';
              });
            rowTestResult.passed = !rowTestResult.errors.length;
          }

          return {
            firstName,
            lastName,
            userValidity,
            ...rowTestResult,
          };
        })
          .filter((entity: BeneficiaryRowTestResult) => {
            const csvBeneficiary: CreateBeneficiaryRequest = transformCsvEntityToRequestObject(entity);
            const existingBeneficiary = existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.email === csvBeneficiary.email);

            return !existingBeneficiary || Object.keys(csvBeneficiary).some((propertyName: string) => {
              if (propertyName === 'iban') {
                return existingBeneficiary.ibanLastNumbers !== csvBeneficiary.iban.slice(-4);
              }
              return existingBeneficiary[propertyName] !== csvBeneficiary[propertyName];
            });
          })
          .sort((beneficiaryA: BeneficiaryRowTestResult, beneficiaryB: BeneficiaryRowTestResult) => {
            const fullNameA: string = (beneficiaryA.lastName + beneficiaryA.firstName).toLowerCase();
            const fullNameB: string = (beneficiaryB.lastName + beneficiaryB.firstName).toLowerCase();

            return fullNameA.localeCompare(fullNameB);
          });

        setHasError(!!beneficiaryFileTestResult.find((beneficiary: BeneficiaryRowTestResult) => beneficiary.errors.length > 0));
        setBeneficiaryFileTestResult(beneficiaryFileTestResult);
        setShowAnalyseFileResult(true);
      } catch (e) {
        if (e?.message.includes('Error column naming')) {
          const undefinedKey: string = (e?.message as string).split(' ').reverse()[0];
          setErrorMessage(e?.message as string);
          setUndefinedKey(undefinedKey);
        }
        setHasError(true);
      }
    }

    function setCountryCode(label: string, localListOfCountries: SelectCountryCodeModel[]): CountryCodeType | undefined {
      const country: SelectCountryCodeModel | undefined = localListOfCountries
        .find((country: SelectCountryCodeModel) => country.label === label);
      return country?.value || undefined;
    }

    function transformCsvEntityToRequestObject(entity: BeneficiaryRowTestResult): UploadBeneficiaryRequest {
      let currentEmail = '';
      let currentBeneficiary: BeneficiaryModel | undefined = undefined;

      const { csvEntity, userValidity }: BeneficiaryRowTestResult = entity;
      const isUpdate: boolean = userValidity === 'USER_EXISTS';
      if (isUpdate) {
        currentEmail = csvEntity.email.trim();
        currentBeneficiary = existingBeneficiaryList.find((beneficiary: BeneficiaryModel) => beneficiary.email === currentEmail);
      }
      let title: GenderModel;
      let countryCode: CountryCodeType | undefined;
      if (csvEntity.title.trim() === 'M.') {
        title = 'Mr';
      } else if (csvEntity.title.trim() === 'Mme') {
        title = 'Mrs';
      } else {
        title = 'Unknown';
      }
      countryCode = setCountryCode(csvEntity.countryCode.trim(), ListOfCountryCode);

      return {
        registrationNumber: csvEntity.registrationNumber.trim(),
        email: csvEntity.email.trim(),
        title: title,
        firstName: csvEntity.firstName.trim(),
        lastName: csvEntity.lastName.trim(),
        streetAddress: csvEntity.streetAddress.trim() || undefined,
        additionalAddress: csvEntity.additionalAddress.trim() || undefined,
        city: csvEntity.city.trim(),
        countryCode: countryCode,
        postalCode: csvEntity.postalCode.trim(),
        iban: csvEntity.iban.trim(),
        numberOfWorkingDays: Number(csvEntity.numberOfWorkingDays.trim()),
        activeSundaysAndHolidays: csvEntity.activeSundaysAndHolidays.trim() === 'O' ?? false,
        firstRightDate: isUpdate ? currentBeneficiary!.firstRightDate : getTimeStampStartOfDay(moment(csvEntity.firstRightDate.trim(), 'DD/MM/YYYY', true)
          .toDate().getTime()),
        agencyId: agenciesStore.currentAgency.uid,
      };
    }

    async function uploadBeneficiaries(): Promise<void> {
      const beneficiariesToUpload: UploadBeneficiaryRequest[] = removeEmptyLinesFromTestResult(beneficiaryFileTestResult)
        .map((entity: BeneficiaryRowTestResult) => transformCsvEntityToRequestObject(entity));

      const paginatedMap: Map<number, UploadBeneficiaryRequest[]> = getPaginatedMap<UploadBeneficiaryRequest>(beneficiariesToUpload, 100);

      const arraysToUpload: UploadBeneficiaryRequest[][] = Array.from(paginatedMap.values());
      const paginatedRes: Awaited<BeneficiariesUploadResponse | undefined>[] = await Promise.all(
        arraysToUpload.map((arrayToUpload: UploadBeneficiaryRequest[]) => {
          return beneficiariesStore.uploadBeneficiaries(agenciesStore.currentAgency.uid, arrayToUpload);
        }),
      );

      if (paginatedRes.includes(undefined)) {
        return;
      }

      const res: BeneficiariesUploadResponse = paginatedRes.reduce((
        beneficiariesUploadRes: BeneficiariesUploadResponse,
        perPageItem: BeneficiariesUploadResponse,
      ) => {
        return {
          beneficiariesCreated: [...beneficiariesUploadRes.beneficiariesCreated, ...perPageItem.beneficiariesCreated],
          beneficiariesUpdated: [...beneficiariesUploadRes.beneficiariesUpdated, ...perPageItem.beneficiariesUpdated],
          beneficiariesFailed: [...beneficiariesUploadRes.beneficiariesFailed, ...perPageItem.beneficiariesFailed],
        };
      }, { beneficiariesCreated: [], beneficiariesUpdated: [], beneficiariesFailed: [] });

      setBeneficiariesUploadedResponse(res);

      if (res && !beneficiariesStore.isLoading) {
        setShowResultAnalyseCsvFile(false);
        setShowResultUploadCsvFile(true);
      }
    }

    return (
      <>
        <div className={beneficiariesByUploadStyles.block}>
          <div className={beneficiariesByUploadStyles.header}>
            <h1>{t('uploadBeneficiariesDrawer.title')}</h1>
            <img className={beneficiariesByUploadStyles.imgClose} alt="close" src={closeIcon} onClick={props.onClose}/>
          </div>
          <h3 className={beneficiariesByUploadStyles.subtitle}>{t('uploadBeneficiariesDrawer.subtitle')}</h3>
          {showResultAnalyseCsvFile &&
              <div className={beneficiariesByUploadStyles.dropzoneBlock}>
                  <a href={'/files/addBeneficiaries.csv'} download
                     className={beneficiariesByUploadStyles.downloadFile}>{t('uploadBeneficiariesDrawer.labelDownloadFile')}</a>
                  <FileUploadManager<CsvBeneficiary>
                      file={file}
                      setFile={setFile}
                      testSet={uploadBeneficiaryTestSet}
                      analyseUploadingFile={analyseBeneficiaryUploadingFile}
                      fileTypeAccepted={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      showNewTestResult={showNewTestResult}
                      setShowNewTestResult={setShowNewTestResult}
                  />

                {!isUTF8 &&
                    <div className={beneficiariesByUploadStyles.errorContainer}>
                        <div className={analysisManagerStyles.accordionBlockError}>
                            <div className={analysisManagerStyles.registrationNumberError}>
                                <Typography className={analysisManagerStyles.typographyErrorUtf8}>
                                    <img src={warningIcon} alt="warning"/>
                                    <span className={analysisManagerStyles.titleTextError}>
                                      Une erreur est survenue
                                    </span>
                                    <p className={analysisManagerStyles.textErrorUtf8}>
                                        Votre fichier ne semble pas être encodé en format « UTF-8 ». Cela pose notamment
                                        des problèmes pour l’interprétation des caractères accentués.
                                    </p>
                                    <p className={analysisManagerStyles.textErrorUtf8}>
                                        Pour y remédier, sous Excel lors de l’enregistrement de votre fichier, vérifiez
                                        l’option d’encodage pour sélectionner « CSV UTF-8 » :
                                    </p>
                                    <div className={analysisManagerStyles.helpUtf8BoxImg}>
                                      <img src={helpUtf8} alt={'help-error-utf8-file'} className={analysisManagerStyles.helpUtf8Img} />
                                    </div>
                                    <div className={analysisManagerStyles.textHelpUtf8}>
                                        <div className={analysisManagerStyles.warningUtf8}>
                                            <img src={circleCross} alt={'warning-utf8'} className={analysisManagerStyles.helpUtf8Img}/>
                                        </div>
                                        <p>N ‘hésitez pas à contacter le support si vous souhaitez être accompagné.</p>
                                    </div>
                                </Typography>
                            </div>
                        </div>
                    </div>
                }

                {showAnalyseFileResult && !hasErrorDuplicateEmail && !hasErrorDuplicateRegistrationNumber
                  && <AddBeneficiariesAnalysisManager
                        fileTestResult={beneficiaryFileTestResult}
                        existingBeneficiaryList={existingBeneficiaryList}
                        setCanBeUploaded={setCanBeUploaded}
                        hasErrorOfNbColumn={hasErrorOfNbColumn}
                    />
                }
                {hasError && <div className={beneficiariesByUploadStyles.uploadButtonBlock}>
                    <ButtonComponent
                        style={{ marginLeft: '1rem' }}
                        variant="contained"
                        color="primary"
                        onClick={props.onClose}
                        type="button"
                    >
                      {t('uploadBeneficiariesDrawer.hasError')}
                    </ButtonComponent>
                </div>
                }

                {hasErrorDuplicateRegistrationNumber && <div className={beneficiariesByUploadStyles.errorContainer}>
                    <div className={analysisManagerStyles.accordionBlockError}>
                        <div className={analysisManagerStyles.registrationNumberError}>
                          {duplicateRegistrationNumbers
                            .map((item: string, index: number) => (
                              <Typography key={index} className={analysisManagerStyles.typographyError}>
                                <img src={warningIcon} alt="warning"/>
                                <span className={analysisManagerStyles.titleTextError}>
                      le matricule {item} est présent plusieurs fois dans le fichier
                    </span>
                              </Typography>))}
                        </div>
                    </div>
                </div>}

                {hasErrorDuplicateEmail && <div className={beneficiariesByUploadStyles.errorContainer}>
                    <div className={analysisManagerStyles.accordionBlockError}>

                        <div className={analysisManagerStyles.registrationNumberError}>
                          {duplicateEmails
                            .map((item: string, index: number) => <Typography key={index}
                                                                              className={analysisManagerStyles.typographyError}>
                              <img src={warningIcon} alt="warning"/>
                              <span className={analysisManagerStyles.titleTextError}>
                        le mail {item} est présent plusieurs fois dans le fichier
                      </span>
                            </Typography>)}

                        </div>
                    </div>
                </div>}

                {canBeUploaded && !hasErrorDuplicateEmail && !hasErrorDuplicateRegistrationNumber
                  && <div className={beneficiariesByUploadStyles.uploadButtonBlock}>
                        <ButtonComponent
                            variant="contained"
                            color="secondary"
                            onClick={props.onClose}
                            type="button"
                            disabled={beneficiariesStore.isLoading}
                        >
                          {t('cancel')}
                        </ButtonComponent>
                        <ButtonComponent
                            style={{ marginLeft: '1rem' }}
                            variant="contained"
                            color="primary"
                            onClick={() => uploadBeneficiaries()}
                            type="button"
                            disabled={beneficiariesStore.isLoading}
                            isFetching={beneficiariesStore.isLoading}
                        >
                          {t('uploadBeneficiariesDrawer.uploadFile')}
                        </ButtonComponent>
                    </div>
                }
              </div>}

          {showResultUploadCsvFile && <>
              <AddBeneficiariesUploadManager beneficiariesUploadedResponse={beneficiariesUploadedResponse}/>
              <div className={beneficiariesByUploadStyles.uploadButtonBlock}>
                  <ButtonComponent
                      style={{ marginLeft: '1rem' }}
                      variant="contained"
                      color="primary"
                      onClick={props.onClose}
                      type="button"
                  >
                    {t('close')}
                  </ButtonComponent>
              </div>
          </>
          }
        </div>
        {(hasError || !isUTF8) && <SnackErrorComponent message={errorMessage
          ? t('errorNaming', { undefinedKey: undefinedKey })
          : t('errorSnackBar')}
        />}
      </>
    );
  });

export default AddBeneficiariesByUploadComponent;
