import React, { FunctionComponent } from 'react';
import { PageBlock } from '../../Style/Style';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import StyledDataGrid from '../../Style/MuiStyles/Datagrid';
import { Button, CircularProgress } from '@material-ui/core';
import { useOrderListBeneficiariesStyles } from './Styles';
import { observer } from 'mobx-react-lite';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { useInstances } from 'react-ioc';
import { toJS } from 'mobx';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { BeneficiaryModel } from '../../assets/models/beneficiaries/Beneficiary.model';
import { workingDayNumberScope } from '../../Utils/Tester/BeneficiaryTester.service';

type LocalStore = [RechargingViewStore, StepperRechargingStore, BeneficiariesStore];

const OrderListBeneficiariesFromCsvComponent: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    stepperRechargingStore,
    beneficiariesStore,
  ]: [
    RechargingViewStore,
    StepperRechargingStore,
    BeneficiariesStore
  ] = useInstances<LocalStore>(RechargingViewStore, StepperRechargingStore, BeneficiariesStore);

  const beneficiariesToRecharge: BeneficiaryModel[] = toJS(beneficiariesStore.beneficiariesToRecharge);
  const { t } = useTranslation('reloading');
  const orderListBeneficiariesStyles: ClassNameMap<'input'> = useOrderListBeneficiariesStyles();

  const listBeneficiariesGrid: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'Collaborateur',
      flex: 2,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{`${params.row.firstName} ${params.row.lastName}`} </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Adresse Email',
      flex: 2,
    },
    {
      field: 'ibanLastNumbers',
      headerName: 'IBAN',
      flex: 1,
      renderCell: function birthdateCell(params: GridCellParams) {
        return (
          <div>
            ***********{params.value}
          </div>
        );
      },
    },
    {
      field: 'numberOfWorkingDays',
      headerName: 'Jours travaillés',
      flex: 1,
      sortable: false,
      renderCell: function actionCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            <input
              type="number"
              className={orderListBeneficiariesStyles.input}
              pattern="[0-9]{2}"
              value={params.value as number ?? 0}
              max={workingDayNumberScope.min}
              min={workingDayNumberScope.max}
              onChange={(e) => {
                const objIndex = beneficiariesToRecharge.findIndex(obj => obj.uid === params.row.id);
                beneficiariesToRecharge[objIndex].numberOfWorkingDays = Number.parseFloat(e.target.value);
                beneficiariesStore.setBeneficiariesToRecharge([...beneficiariesToRecharge]);
              }}
            />

          </div>
        );
      },
    },
    {
      field: 'isActive',
      headerName: 'Actif',
      flex: 1,
      renderCell: function isActiveCell() {
        return (
          <div style={{
            width: 8,
            height: 8,
            borderRadius: 8,
            backgroundColor: '#8CD867',// params.value ? '#8CD867' : '#E94B32'
          }}/>
        );
      },
    },
  ];

  return (
    <PageBlock>
      {beneficiariesStore.isLoading
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: '14px', marginBottom: '54px' }}>
              Renseignez ici le nombre de jours travaillés pour chacun de vos collaborateurs.
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => rechargingViewStore.setOpenBeneficiaryDetails(true)}
              type="button">
              {t('addBeneficiary')}
            </Button>
          </div>
          <StyledDataGrid
            rows={beneficiariesToRecharge}
            columns={listBeneficiariesGrid}
            showColumnRightBorder={false}
            showCellRightBorder={false}
            disableSelectionOnClick
          />
          <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: '40px' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => stepperRechargingStore.goToPreviousStep()}
              type="button">
              {t('cancel')}
            </Button>
            <Button
              style={{ marginLeft: '40px' }}
              variant="contained"
              color="primary"
              disabled={beneficiariesToRecharge.findIndex((d) => isNaN(d.numberOfWorkingDays)) !== -1}
              onClick={() => stepperRechargingStore.goToNextStep()}
              type="button">
              {t('nextStep')}
            </Button>
          </div>
        </>
      }
    </PageBlock>
  );
});

export default OrderListBeneficiariesFromCsvComponent;
