import {
  BeneficiaryProvision,
  GetAgencyOrderProvisionResponse,
} from '../assets/responses/orders/GetAgencyOrderProvision.response';
import { getAgencyOrderProvision } from './ordersAPI.service';
import { compareBeneficiaries } from '../Utils/Beneficiaries.utils';
import { errorStore } from '../Stores/Error.store';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';

type AgencyOrderProvisionViewModel = {
  uid: string;
  beneficiary: BeneficiaryModel;
  nbDays: number;
  amount: number;
};

export async function getAgencyOrderProvisionVM (agencyId: string, orderId: string): Promise<AgencyOrderProvisionViewModel[]> {
  let agencyOrderProvision: GetAgencyOrderProvisionResponse | null = null;

  try {
    agencyOrderProvision = await getAgencyOrderProvision(agencyId, orderId);
  } catch(error) {
    errorStore.dismiss(error);
    return null;
  }

  if (!agencyOrderProvision) {
    return [];
  }

  const { beneficiaryProvisions } = agencyOrderProvision;

  return beneficiaryProvisions
    .sort((provisionA: BeneficiaryProvision, provisionB: BeneficiaryProvision) => {
      return compareBeneficiaries(provisionA.beneficiary, provisionB.beneficiary);
    })
    .map((item: BeneficiaryProvision) => {
      return {
        uid: `${agencyOrderProvision.orderUid}-${item.beneficiaryUid}`,
        beneficiary: item.beneficiary,
        nbDays: item.numberOfRechargingDays,
        amount: agencyOrderProvision.invoice.contractAgencyPart,
      };
    });
}
