export function isSunday(date: Date): boolean {
  return date.getDay() === 0;
}

export function getYear(date: Date): number {
  return date.getFullYear();
}

export function getMonth(date: Date): number {
  return date.getMonth() + 1;
}

export function getDate(date: Date): number {
  return date.getDate();
}

export function toISOStringDate(date: Date): string {
  return date.toISOString().substring(0, 10);
}

export function getFormattedStringMonth(date: Date): string {
  return String(getMonth(date)).padStart(2, '0');
}

export function getFormattedStringDay(date: Date): string {
  return String(getDate(date)).padStart(2, '0');
}
