import { callable } from './httpWrapper.service';
import { GetAgencyOrderProvisionResponse } from '../assets/responses/orders/GetAgencyOrderProvision.response';
import { CreateAgencyOrderRequest } from '../assets/requests/orders/CreateAgencyOrder.request';
import { ConfirmAgencyOrderResponse } from '../assets/responses/orders/ConfirmAgencyOrder.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { GetBeneficiaryProvisionResponse } from '../assets/responses/orders/GetBeneficiaryProvisions.response';

export async function getAgencyOrderProvision (agencyId: string, orderId: string): Promise<GetAgencyOrderProvisionResponse> {
  return callable(
    'getAgencyOrderProvision',
    { agencyId, orderId },
  );
}

export async function createAgencyOrder (requestBody: CreateAgencyOrderRequest): Promise<ConfirmAgencyOrderResponse> {
  return callable(
    'createAgencyOrder',
    requestBody,
  );
}

export async function getBeneficiaryProvisions(agencyId: string, beneficiaryId: string, requestBody: OptionalTimePeriodRequest): Promise<GetBeneficiaryProvisionResponse[]> {
  return callable(
    'getBeneficiaryProvisions',
    { agencyId, beneficiaryId },
    requestBody,
  );
}
