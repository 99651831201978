import { AppBar, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import close from '../../../assets/close.svg';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesDialogViewStore } from '../../../Stores/viewStore/BeneficiariesDialogView.store';
import BeneficiaryAccountInformationsComponent
  from '../BeneficiaryAccountInformations/BeneficiaryAccountInformationsComponent';
import BeneficiaryPaybackManagedComponent from '../BeneficiaryPaybackManaged/BeneficiaryPaybackManagedComponent';
import BeneficiaryProvisionsComponent from '../BeneficiaryProvisions/BeneficiaryProvisionsComponent';
import { useBeneficiariesDetailsStyles } from './BeneficiaryDetailsStyles';
import BeneficiaryTakeOutComponent from '../BeneficiaryTakeOut/BeneficiaryTakeOut';

export interface IBeneficiariesDetailsComponentProps {
  onClose: () => void;
  beneficiary?: BeneficiaryModel;
}

type LocalStore = [AgenciesStore, BeneficiariesDialogViewStore];

const BeneficiaryDetailsComponent: FunctionComponent<IBeneficiariesDetailsComponentProps> = provider(
  BeneficiariesDialogViewStore,
)(observer((props: IBeneficiariesDetailsComponentProps) => {
  const [
    agenciesStore,
    beneficiariesDialogViewStore,
  ]: [AgenciesStore, BeneficiariesDialogViewStore] = useInstances<LocalStore>(AgenciesStore, BeneficiariesDialogViewStore);
  const tabNumber = beneficiariesDialogViewStore.tabNumber;
  const beneficiariesDetailsClasses = useBeneficiariesDetailsStyles(props);
  const { t } = useTranslation('beneficiaries');

  return (
    <>
      <div className={beneficiariesDetailsClasses.block}>
        <div className={beneficiariesDetailsClasses.header}>
          <h1>{props.beneficiary === null
            ? t('addBeneficiary')
            : `${t('detailsTitle')} ${capitalizeFirstLetter(props.beneficiary?.firstName)} ${props.beneficiary?.lastName.toUpperCase()}`}</h1>
          <img className={beneficiariesDetailsClasses.imgClose} alt="close" src={close} onClick={props.onClose}/>
        </div>
        {props.beneficiary !== null &&
          <>
            <span>Vous pouvez consulter ici les données du collaborateur</span>
            <AppBar position="static" elevation={0} className={beneficiariesDetailsClasses.appBar}>
              <Tabs variant="fullWidth" value={tabNumber}
                    onChange={(event, tabNumber) => beneficiariesDialogViewStore.setTabNumber(tabNumber)}
                    aria-label="détail bénéficiaire">
                <Tab className={beneficiariesDetailsClasses.tab} label="Compte"/>
                <Tab className={beneficiariesDetailsClasses.tab} label="Droits attribués"/>
                <Tab className={beneficiariesDetailsClasses.tab} label="Dépenses validées"/>
                <Tab className={beneficiariesDetailsClasses.tab} label="Sortie dispositif"/>
              </Tabs>
            </AppBar>
          </>
        }
        <div>
          {(tabNumber === 0 || props.beneficiary === null) &&
            <BeneficiaryAccountInformationsComponent onClose={props.onClose} beneficiary={props.beneficiary}/>
          }
          {tabNumber === 1 &&
            <BeneficiaryProvisionsComponent beneficiary={props.beneficiary} onClose={props.onClose}/>
          }
          {tabNumber === 2 && agenciesStore.currentAgency?.isManagedPaymentMode &&
            <BeneficiaryPaybackManagedComponent beneficiary={props.beneficiary} onClose={props.onClose}/>
          }
          {
            tabNumber == 3 &&
            <BeneficiaryTakeOutComponent beneficiary={props.beneficiary} onClose={props.onClose}/>
          }
        </div>
      </div>
    </>
  );
}));

export default BeneficiaryDetailsComponent;
