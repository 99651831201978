import { makeStyles } from '@material-ui/core';

export const useOrderCardStyles = makeStyles(() => ({
  block: {
    backgroundColor: '#F7F7F8',
    border: '1px solid #D5D9DC',
    borderRadius: '20px',
    height: '100%',
    minHeight: '320px',
    padding: '20px 8.5px 12.5px 8.5px',
    width: '218px'
  },
  label: {
    fontSize: '14px'
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '0.75rem',
    textTransform: 'capitalize',
    wordBreak: 'break-all',
  },
}));
