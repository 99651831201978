import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { GridColDef, GridRowModel, GridValueGetterParams } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';
import beneficiary from '../i18n/fr/beneficiaries';
import { COLORS } from '../Style/Style';
import Button from '../Style/MuiStyles/Button';
import StyledDataGrid from '../Style/MuiStyles/Datagrid';
import capitalizeFirstLetter from '../Function/CapitalizeFirstLetter';
import close from '../assets/close.svg';
import arrow from '../assets/arrow.svg';
import { getAgencyOrderProvisionVM } from '../Services/ordersVM.service';
import { formatCurrencyToEuro, formatDateToLocaleString } from '../Utils/format.utils';
import { compareBeneficiaries } from '../Utils/sortBeneficiaryList';
import { OrderInvoices } from '../Stores/AgencyInvoice.store';

const useStyles = makeStyles({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px',
  },
  amount: {
    fontSize: '20px',
    margin: 0,
    color: COLORS.textPrimary,
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px',
  },
  title: {
    margin: '0',
    letterSpacing: '2px',
    padding: '40px 50px 40px 0px',
    color: COLORS.textPrimary,
  },
  imgClose: {
    cursor: 'pointer',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '1rem',
  },
  downloadsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
    gap: '1rem',
  },
  download: {
    marginRight: '1rem',
    textDecoration: 'none'
  },
});

export interface IReloadingDetailsComponentProps {
  orderInvoices: OrderInvoices;
  onClose: () => void;
}

const dataGridColumnsDefinition: GridColDef[] = [
  {
    field: 'beneficiary',
    headerName: 'Bénéficiaire',
    flex: 1,
    valueGetter: ({ value }: { value: any }) => `${value.lastName} ${capitalizeFirstLetter(value.firstName)}`,
    sortComparator: compareBeneficiaries,
  },
  {
    field: 'nbDays',
    headerName: 'Jours travaillés',
    flex: 1,
  },
  {
    field: 'amountPerDay',
    headerName: 'Droit quotidien',
    flex: 1,
    valueFormatter: (params: GridValueGetterParams) => formatCurrencyToEuro(params.row.amount),
  },
  {
    field: 'totalAmount',
    headerName: 'Montant total',
    flex: 1,
    valueFormatter: ({ row }: { row: GridRowModel }) => formatCurrencyToEuro(row.nbDays * row.amount),
  },
];

const OrderDetailsComponent: FunctionComponent<IReloadingDetailsComponentProps> = (props: IReloadingDetailsComponentProps) => {
  const { orderInvoices: { agencyUid, orderUid, paymentMethod, orderReference, creationDate, mainInvoice, feesInvoice }, onClose } = props;
  const classes = useStyles();
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [beneficiaryProvisionList, setBeneficiaryProvisionList] = useState([]);
  const { t } = useTranslation(['reloading', 'common']);

  useEffect(() => {
    const isDateAfterThreshold: boolean = creationDate >= new Date('2022/09/14').getTime();
    setIsDataAvailable(isDateAfterThreshold);
    setIsLoading(isDateAfterThreshold);

    if (isDateAfterThreshold) {
      (async () => {
        const list = await getAgencyOrderProvisionVM(agencyUid, orderUid);
        setBeneficiaryProvisionList(list);
        setIsLoading(false);
      })();
    }
  }, [creationDate, agencyUid, orderUid]);

  return (
    <div className={classes.block}>

      <div className={classes.header}>
        <h1>{`Détail du rechargement N°${orderReference} du ${formatDateToLocaleString(creationDate)}`}</h1>
        <img className={classes.imgClose} alt="close" src={close} onClick={onClose}/>
      </div>

      <span>Vous pouvez consulter, ici, la liste des rechargements effectués sur chacun des bénéficiaires concernés</span>

      <p className={classes.title}>
        LISTE DES DROITS ATTRIBUÉS
      </p>

      {isDataAvailable &&
          <StyledDataGrid
              rows={beneficiaryProvisionList}
              columns={dataGridColumnsDefinition}
              showColumnRightBorder={false}
              showCellRightBorder={false}
              disableSelectionOnClick
              loading={isLoading}
          />
      }

      {!isDataAvailable &&
          <Box textAlign="center" fontSize="body2.fontSize" marginTop={30}>
              Nous sommes désolés, nous ne sommes pas en mesure d'afficher<br/>
              le détail des rechargements effectués avant le 14/09/2022.
          </Box>
      }

      <div className={classes.downloadsContainer}>
        { paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && feesInvoice?.invoiceUrl
          ? <a href={feesInvoice.invoiceUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary">
              <>
                <span className={classes.download}>Facture abonnement</span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          </a>
          : null
        }

        {mainInvoice.invoiceUrl
          ? <a href={mainInvoice.invoiceUrl} target="_blank" rel="noreferrer">
            <Button
              variant="contained"
              color="secondary">
              <>
                <span className={classes.download}>
                  {paymentMethod === 'DISSOCIATED_BANK_TRANSFER'
                    ? 'Relevé de provision'
                    : 'Télécharger la facture'
                  }
                </span>
                <img width={12} src={arrow} alt="down-arrow"/>
              </>
            </Button>
          </a>
          : null
        }
      </div>

      <div className={classes.buttonContainer}>
        <Button
          onClick={onClose}
          variant="contained"
          color="primary"
        >
          {t('common:close')}
        </Button>
      </div>

    </div>
  );
};

export default OrderDetailsComponent;
