import { action, makeAutoObservable } from 'mobx';

export class AccountingViewStore {
  search: string = '';
  openDialog: boolean = false;
  sinceDate: string = '0';

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setSearch (str: string) {
    this.search = str;
  }

  @action setOpenDialog (isOpen: boolean) {
    this.openDialog = isOpen;
  }

  @action setSinceDate (newSinceDate: string) {
    this.sinceDate = newSinceDate;
  }
};
