import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { agencyTitle, agencyInputContainer, agencyInputCol4, root } from '../AgencyStyle';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';

const OrderInformationComponent: FunctionComponent = observer(() => {
  const agenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const { t } = useTranslation('agency');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  return (
    <div className={root}>
      <div className={agencyTitle}>{t('orderTitle')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('startDate')}
            value={moment(agenciesStore.currentAgency?.contractStartDate)?.format('DD/MM/yyyy')}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('dailyAmount')}
            fullWidth
            value={
              agenciesStore.currentAgency?.contractFacialValue
                ? currencyFormat.format(agenciesStore.currentAgency?.contractFacialValue)
                : t('notSpecified')
            }
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('companyQuota')}
            fullWidth
            value={
              agenciesStore.currentAgency?.contractAgencyPart
                ? currencyFormat.format(agenciesStore.currentAgency?.contractAgencyPart)
                : t('notSpecified')
            }
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('isManagedPaymentMode')}
            value={agenciesStore.currentAgency?.isManagedPaymentMode ? t('allInclusive') : t('autonomous')}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('priceAmountPerBenificiary')}
            value={
              agenciesStore.currentAgency?.contractSubscriptionFee
                ? t('priceAmountPerBenificiaryValue', {count: Number(agenciesStore.currentAgency?.contractSubscriptionFee)})
                : t('notSpecified')
          }
            fullWidth
          />
        </div>
      </div>
      <div className={agencyTitle}>{t('contractRepresentative')}</div>
      <div className={agencyInputContainer}>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('firstName')}
            value={agenciesStore.currentAgency?.contractSignatoryFirstName}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('lastName')}
            value={agenciesStore.currentAgency?.contractSignatoryLastName}
            fullWidth
          />
        </div>
        <div className={agencyInputCol4}>
          <StyledTextField
            color='secondary'
            label={t('email')}
            value={agenciesStore.currentAgency?.contractSignatoryEmail}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
});

export default OrderInformationComponent;
