import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import downArrowIcon from '../../../assets/downArrow-red.svg';
import warningIcon from '../../../assets/warning.svg';
import { COLORS } from '../../../Style/Style';
import { FieldTestReport } from '../../../Utils/Tester/BaseTester.service';
import {
  BeneficiaryRowTestResult,
  CsvBeneficiary,
  uploadBeneficiaryKeysMap,
} from '../../../Utils/Tester/BeneficiaryTester.service';

export const useAddBeneficiariesAnalysisManager = makeStyles(() => {
  return ({
    block: {
      width: '683px',
    },
    accordionBlockError: {
      backgroundColor: COLORS.lightError,
      borderRadius: 5,
      boxShadow: 'none',
      marginTop: 5,
      '&:before': {
        height: 0,
      },
      paddingBottom: 20,
    },
    accordion: {
      borderBottomRightRadius: 10,
      borderBottomLeftRadius: 10,
      padding: '5px 16px',
      border: 'none',
    },
    typographyError: {
      fontSize: '14px',
      display: 'flex',
    },
    typographyErrorUtf8: {
      fontSize: '14px',
    },
    registrationNumberError: {
      padding: '.5rem',
      fontSize: '14px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    titleTextError: {
      color: COLORS.error,
      paddingLeft: 10,
      fontWeight: 'bold',
    },
    textError: {
      color: COLORS.error,
      paddingLeft: 100,
      fontWeight: 'normal',
    },
    textErrorUtf8: {
      color: COLORS.error,
      paddingLeft: '1.8rem',
      paddingBottom: '1rem',
      fontWeight: 'normal',
    },
    textHelpUtf8: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '8px',
      opacity: 1,
      backgroundColor: 'rgba(247,247,248, 1)',
      padding: '1rem 1.5rem',
    },
    helpUtf8BoxImg: {
      width: '400px',
      padding: '1rem 0',
      paddingLeft: '1.8rem',
    },
    helpUtf8Img: {
      width: '100%',
      objectFit: 'contain',
    },
    warningUtf8: {
      width: '1rem',
      height: '1rem',
      marginRight: '0.5rem',
    }
  });
});

interface IAddBeneficiariesFromCsvResult {
  fileTestResult: BeneficiaryRowTestResult[];
  hasErrorOfNbColumn: boolean;
}

const entityStartLine = 2;

const AddBeneficiariesFromCsvError: FunctionComponent<IAddBeneficiariesFromCsvResult> = (props: IAddBeneficiariesFromCsvResult) => {
  const { t } = useTranslation('beneficiariesfromcsv');
  const analysisManagerStyles = useAddBeneficiariesAnalysisManager();
  const beneficiaryWithErrorList: BeneficiaryRowTestResult[] = useMemo(() => {
    return props.fileTestResult.filter(row => row.errors.length > 0);
  }, [props.fileTestResult]);

  return (
    <>
      {props.hasErrorOfNbColumn &&
          <Accordion className={analysisManagerStyles.accordionBlockError}>
              <AccordionSummary
                  expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={analysisManagerStyles.accordion}
              >
                  <Typography className={analysisManagerStyles.typographyError}>
                      <img src={warningIcon} alt="warning"/>
                      <span className={analysisManagerStyles.titleTextError}>
                    Erreur sur le nombre de colonne présentes. Il doit y avoir 14 colonnes.
              </span>
                  </Typography>
              </AccordionSummary>
          </Accordion>}
      {
        beneficiaryWithErrorList?.map((row: BeneficiaryRowTestResult, index: number) => {
          return (
            <Accordion key={index} className={analysisManagerStyles.accordionBlockError}>
              <AccordionSummary
                expandIcon={<img src={downArrowIcon} alt="downArrow"/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={analysisManagerStyles.accordion}
              >
                <Typography className={analysisManagerStyles.typographyError}>
                  <img src={warningIcon} alt="warning"/>
                  <span className={analysisManagerStyles.titleTextError}>
                    {t('titleLineError', { count: row.errors.length, lineNumber: row.rowIndex + entityStartLine })}
                  </span>
                </Typography>
              </AccordionSummary>
              {row.errors.map((fieldError: FieldTestReport<CsvBeneficiary>, index: number) => {
                return (
                  <AccordionDetails key={index} className={analysisManagerStyles.accordion}>
                    <Typography className={analysisManagerStyles.typographyError}>
                        <span className={analysisManagerStyles.textError}>
                          {t(
                            fieldError.errorMessage,
                            {
                              field: uploadBeneficiaryKeysMap[fieldError.fieldName],
                              minValue: fieldError.minValue,
                              maxValue: fieldError.maxValue,
                              minLength: fieldError.minLength,
                              maxLength: fieldError.maxLength,
                              lowerRange: fieldError.lowerRange,
                              upperRange: fieldError.upperRange,
                              expectedValue: fieldError.expectedValue,
                            },
                          )}
                        </span>
                    </Typography>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          );
        })
      }
    </>
  );
};

export default AddBeneficiariesFromCsvError;
