import { InvoiceModel, InvoiceStatus } from '../../models/invoices/invoice.model';
import { manageError } from '../errors';
import { getProvisionDailyAmount } from './order.util';

function assertInvoice(invoice: InvoiceModel): void {
  if (!invoice) {
    manageError(
      { code: 'failed-precondition', message: 'invoice should not be empty' });
  }
}

export function getExcludingTaxesAmount(invoice: InvoiceModel): number {
  assertInvoice(invoice);

  if (typeof invoice.contractSubscriptionFee !== 'number' || typeof invoice.subscriptionsBeneficiaryCount !== 'number') {
    manageError(
      {
        code: 'failed-precondition',
        message: 'invoice should have contractSubscriptionFee and subscriptionsBeneficiaryCount values',
      },
    );
  }

  return invoice.subscriptionsBeneficiaryCount * invoice.contractSubscriptionFee;
}

export function getVatAmount(invoice: InvoiceModel): number {
  if (typeof invoice.subscriptionVatRate !== 'number') {
    manageError(
      { code: 'failed-precondition', message: 'invoice should have a subscriptionVatRate value' },
    );
  }

  return getExcludingTaxesAmount(invoice) * (invoice.subscriptionVatRate / 100);
}

export function getIncludingTaxesAmount(invoice: InvoiceModel): number {
  return getExcludingTaxesAmount(invoice) + getVatAmount(invoice);
}

export function getProvisionAmount(invoice: InvoiceModel): number {
  assertInvoice(invoice);

  if (typeof invoice.provisionTotalDays !== 'number') {
    manageError(
      { code: 'failed-precondition', message: 'invoice should have provisionTotalDays value' },
    );
  }

  return getProvisionDailyAmount(invoice) * invoice.provisionTotalDays;
}

export function getNetAmountPayable(invoice: InvoiceModel): number {
  const provisionAmount: number = getProvisionAmount(invoice);
  const includingTaxesAmount: number = getIncludingTaxesAmount(invoice);

  switch (invoice.invoiceType) {
    case 'STANDARD':
      return provisionAmount + includingTaxesAmount;
    case 'DISSOCIATED_PROVISIONS':
      return provisionAmount;
    case 'DISSOCIATED_FEES':
      return includingTaxesAmount;
    default:
      return provisionAmount + includingTaxesAmount;
  }
}

export function getMessageFromInvoiceStatus(invoice: InvoiceModel, isDissociatedPayment: boolean = false, isProvision: boolean = false): string {
  if (!invoice.status) {
    return 'STATUT INCONNU';
  }
  const invoiceStatus: InvoiceStatus = invoice.status;
  switch (invoiceStatus) {
    case 'CREATED':
      return `EN ATTENTE DE PAIEMENT ${isDissociatedPayment ? 'PAR VIREMENT' : ''}`;
    case 'PENDING':
      return `EN ATTENTE DE PAIEMENT ${isDissociatedPayment ? 'PAR VIREMENT' : ''}`;
    case 'SUCCEEDED':
      return `PAYE${!isProvision ? 'E' : ''}`;
    case 'FAILED':
      return 'EN ERREUR';
    case 'REFUND_CREATED':
      return 'REMBOURSEMENT EN ATTENTE';
    case 'REFUND_SUCCEEDED':
      return 'REMBOURSEMENT REALISE';
    case 'REFUND_FAILED':
      return 'REMBOURSEMENT EN ERREUR';
    case 'REPUDIATION_CREATED':
      return 'REPUDIATION EN ATTENTE';
    case 'REPUDIATION_SUCCEEDED':
      return 'REPUDIATION REALISEE';
    case 'REPUDIATION_FAILED':
      return 'REPUDIATION EN ERREUR';
    case 'UNKNOWN':
      return 'STATUT INCONNU';
    default:
      return 'STATUT INCONNU';
  }
}
