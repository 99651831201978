import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../../Style/Style';

export const useDashboardRecapStyles = makeStyles((theme) => ({
  root: {
    flex: '0 0 auto'
  },
  pageBlock: {
    color: COLORS.textPrimary,
    fontFamily: 'Inter',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: '2rem'
  },
  block: {
    flex: '0 0 100%',
    minWidth: '0px',
    padding: '0 2rem',
    minHeight: '90px',
    [theme.breakpoints.up('md')]: {
      '&:nth-child(2n-1)': {
        borderRight: '1px solid #E6E1E3'
      },
      flex: '0 0 50%'
    },
    [theme.breakpoints.up('lg')]: {
      borderBottom: 'none',
      borderRight: '1px solid #E6E1E3',
      flex: '0 0 25%',
      '&:last-of-type': {
        borderRight: 'none'
      }
    }
  },
  hello: {
    fontWeight: 'bold',
    fontSize: '30px'
  },
  blockContent: {
    fontSize: '36px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginLeft: '1rem'
  },
  flex: {
    display: 'flex'
  },
  backDrop: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0,0,30,0.4)'
  },
  link: {
    color: COLORS.textSecondary,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
