import { makeStyles } from '@material-ui/core';

export const useRechargingStyles = makeStyles(() => ({
  title: {
    paddingBottom: 30
  },
  newRechargingCard: {
    backgroundColor: 'white',
    border: '2px dashed #D5D9DC',
    borderRadius: '20px',
    height: '100%',
    minHeight: '320px',
    padding: '20px 8.5px 12.5px 8.5px',
    width: '218px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  newReloadingBlock: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  invoices: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '50px'
  },
  addIcon: {
    height: '60px',
    width: '60px',
    border: 1,
    borderStyle: 'solid',
    borderColor: '#EDEFF0',
    borderRadius: 50,
    backgroundColor: '#F7F7F8',
    display: 'flex',
    justifyContent: 'center',
  }
}));