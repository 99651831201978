import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';
import { GetAgencyInvoiceOutputDto } from '../../app/api/mapper';

export const useInvoiceDetailsStyles = makeStyles(() => ({
  block: {
    height: '100vh',
    color: COLORS.textPrimary,
    padding: '40px'
  },
  circle: (props: any) => ({
    width: '8px',
    height: '8px',
    backgroundColor: props.invoice?.status === GetAgencyInvoiceOutputDto.status.SUCCEEDED ? '#8CD867' : '#E94B32',
    borderRadius: '8px',
    alignSelf: 'center',
    marginRight: '5px'
  }),
  amount: {
    margin: 0,
    color: COLORS.textPrimary,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '15px'
  },
  imgClose: {
    cursor: 'pointer'
  },
  subtitle: {
    marginBottom: '84px'
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '44px'
  },
  title2: {
    fontWeight: 'bold',
    marginBottom: '18px'
  },
  month: {
    textTransform: 'capitalize'
  },
  secondBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  provision: {
    marginBottom: '50px'
  },
  subscription: {
    marginBottom: '5px'
  },
  transaction: {
    color: '#A3AAB2',
    marginBottom: '40px'
  },
  paymentBlock: {
    width: '100%',
    display: 'flex',
    placeItems: 'flex-end',
    flexDirection: 'column',
    margin: '34px 0px 76px 0px'
  },
  excl: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px'
  },
  vat: {
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px'
  },
  subTotal: (props: any) => ({
    width: '350px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: props.invoice?.invoiceType === 'STANDARD' ? 'initial' : 'bold'
  }),
  total: {
    width: '620px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  buttonBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '1rem'
  },
  download: {
    marginRight: '1rem',
    textDecoration: 'none'
  },
  expectedDescriptor: {
    marginLeft: '1rem',
    fontWeight: 'bold'
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));
