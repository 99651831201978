import { makeStyles } from '@material-ui/core';
import { GetAgencyInvoiceOutputDto } from '../../app/api/mapper';

export const useInvoiceCardStyles = makeStyles(() => ({
  block: {
    backgroundColor: '#F7F7F8',
    border: '1px solid #D5D9DC',
    borderRadius: '20px',
    minHeight: '380px',
    padding: '20px 12.5px 12.5px 12.5px',
    width: '218px',
    maxWidth: '218px',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  statusBlock: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1.5rem'
  },
  status: (props: any) => ({
    width: '8px',
    height: '8px',
    backgroundColor: props.invoice.status === GetAgencyInvoiceOutputDto.status.SUCCEEDED ? '#8CD867' : '#E94B32',
    borderRadius: '8px',
    alignSelf: 'center',
    marginRight: '5px'
  }),
  label: {
    fontSize: '14px'
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1.25rem',
    wordBreak: 'break-all',
  },
  date: {
    textTransform: 'capitalize'
  }
}));
