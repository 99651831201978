import React, { FunctionComponent, useEffect } from 'react';
import { PageBlock, PageSubtitle } from '../Style/Style';
import AgencyInformationComponent from './tabs/AgencyInformationComponent';
import OrderInformationComponent from './tabs/OrderInformationComponent';
import PaymentInformation from './tabs/PaymentInformation';
import { AppBar, CircularProgress, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { provider, useInstances } from 'react-ioc';
import { AgenciesStore, AgencyDetailsStore } from '../Stores/Agencies.store';
import { useAgencyComponentStyles } from './AgencyStyle';
import { AgencyViewStore } from '../Stores/viewStore/AgencyView.store';

type LocalStore = [AgenciesStore, AgencyDetailsStore, AgencyViewStore];

const AgencyComponent: FunctionComponent = provider(
  AgencyDetailsStore,
  AgencyViewStore,
)(observer(() => {
  const [
    agenciesStore,
    agencyDetailsStore,
    agencyViewStore,
  ]: [AgenciesStore, AgencyDetailsStore, AgencyViewStore] = useInstances<LocalStore>(AgenciesStore, AgencyDetailsStore, AgencyViewStore);
  const tabNumber: number = agencyViewStore.tabNumber;
  const agencyComponentClasses = useAgencyComponentStyles();
  const { t } = useTranslation('agency');

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      agencyDetailsStore.getOneAgency(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency]);

  return (
    <PageBlock>
      {agencyDetailsStore.isAgencyLoaded || !agencyDetailsStore.agencyDetails
        ? <CircularProgress color={'primary'}/>
        : <>
          <h1>{agencyDetailsStore.agencyDetails.agencyName}</h1>
          <PageSubtitle>
            {`${t('subtitle')} ${agencyDetailsStore.agencyDetails.agencyName}`}
          </PageSubtitle>
          <AppBar
            position='static'
            elevation={0}
            className={agencyComponentClasses.appBar}>
            <Tabs
              value={tabNumber}
              onChange={(event, newTabNumber) => agencyViewStore.setTabNumber(newTabNumber)}
              aria-label={t('subtitle')}>
              <Tab label={t('tab1')} className={agencyComponentClasses.tab}/>
              <Tab label={t('tab2')} className={agencyComponentClasses.tab}/>
              <Tab label={t('tab3')} className={agencyComponentClasses.tab}/>
            </Tabs>
          </AppBar>
          {tabNumber === 0 &&
              <AgencyInformationComponent/>
          }
          {tabNumber === 1 &&
              <OrderInformationComponent/>
          }
          {tabNumber === 2 &&
              <PaymentInformation/>
          }
        </>
      }
    </PageBlock>
  );
}));

export default AgencyComponent;
