import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../Style/Style';

export const useOrderListBeneficiariesStyles = makeStyles(() => ({
  input: {
    border: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'textfield',
    color: COLORS.textSecondary,
    backgroundColor: 'white',
    borderColor: 'grey',
    fontSize: '17px',
    width: '56px',
    textAlign: 'center',
    padding: '0.5rem 1rem',
    borderRadius: '12px',
    '&:hover': {
      outline: 'none', boxShadow: '0 0 0 1pt #79BEDB'
    },
    '&:focus': {
      outline: 'none', boxShadow: '0 0 0 1pt #79BEDB'
    }
  }
}));
