import {
  FormControl,
  FormControlLabel, FormHelperText,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import Button from 'Style/MuiStyles/Button';
import { AgencyModel, RestitutionMethod } from '../../assets/models/agencies/Agency.model';
import { AgenciesStore, AgencyDetailsStore } from '../../Stores/Agencies.store';
import { MandateStore } from '../../Stores/Mandate.store';
import StyledTextField from '../../Style/MuiStyles/TextField';
import {
  agencyInputCol6,
  agencyInputContainer,
  agencyTitle,
  root,
  input,
  restitutionMethodContainer, container, restitutionMethodContainerButtons,
} from '../AgencyStyle';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';
import { FormikHelpers, useFormik } from 'formik';
import Yup from '../../i18n/validation';
import isValidIBANNumber from '../../Function/IbanValidator';
import {
  BeneficiaryTextFieldProps,
} from '../../Beneficiaries/BeneficiaryDetails/BeneficiaryAccountInformations/BeneficiaryAccountInformationsComponent';
import { obfuscateIbanNumbersUtil } from '../../assets/utils/databank/obfuscateIbanNumbers.util';
import { COLORS } from '../../Style/Style';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import SnackErrorComponent from 'Component/SnackbarError/SnackErrorComponent';

const useOrderInformationsStyles = makeStyles(() => ({
  link: {
    width: 'fit-content',
  },
}));

type LocalStore = [MandateStore, AgencyDetailsStore, AgenciesStore];

export type AgencyTextFieldProps = BeneficiaryTextFieldProps

interface RestitutionFormFields extends Omit<UpdateAgencyRestitutionRequest, 'iban'> {
  restitutionIban: string;
}

const AgencyTextField = ({ id, formik, countryFlag, ...props }: AgencyTextFieldProps) => {
  const { t } = useTranslation('agency');
  return (<div style={{ position: 'relative' }}>
    <StyledTextField
      className={input}
      id={id}
      name={id}
      label={t(id)}
      value={formik.values[id]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors[id]) && formik.touched[id]}
      errormessage={formik.errors[id]}
      placeholder={t(`${id}Placeholder`)}
      fullWidth
      {...props}
    />
    {countryFlag &&
      <span
        style={{
          marginLeft: '50%',
          position: 'absolute',
          top: 48,
          left: -22,
        }}
        className={`fi fi-${countryFlag}`}
      />
    }
  </div>);
};

const PaymentInformationComponent: FunctionComponent = observer(() => {
  const [mandateStore, agencyDetailsStore, agenciesStore]: [MandateStore, AgencyDetailsStore, AgenciesStore] = useInstances<LocalStore>(MandateStore, AgencyDetailsStore, AgenciesStore);
  const { restitutionMethod, restitutionIban } = agenciesStore;
  const [radioValue, setRadioValue] = useState<RestitutionMethod>(restitutionMethod || 'PROVISIONS_WALLET_DEPOSIT');
  const [isButtonContainerVisible, setIsButtonContainerVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const agencyDetails: AgencyModel = agencyDetailsStore.agencyDetails;
  const orderInformationsClasses: ClassNameMap<string> = useOrderInformationsStyles();
  const { t } = useTranslation(['agency', 'country']);

  const obfuscateIbanNumbers = (iban: string | null | undefined) => iban ? obfuscateIbanNumbersUtil(iban) : '';

  useEffect(() => {
    if (agencyDetails.paymentMethod !== 'DIRECT_DEBIT') {
      void agenciesStore.getAgencyBankAccountAlias();
    }
  }, [agencyDetails]);

  Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

  const validationSchema = Yup.object().shape({
    restitutionMethod: Yup
      .string()
      .required(),
    restitutionIban: radioValue === 'RESTITUTION_IBAN_PAYOUT'
      ? Yup
        .string()
        .required()['isValidIBANNumber']()
      : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      restitutionMethod,
      restitutionIban: obfuscateIbanNumbers(restitutionIban),
    },
    validationSchema,
    validateOnBlur: true,
    onSubmit: updateRestitution,
  });

  useEffect(() => {
    formik.setFieldValue('restitutionMethod', restitutionMethod);
    formik.setFieldValue('restitutionIban', obfuscateIbanNumbers(restitutionIban || ''));
  }, [restitutionMethod, restitutionIban]);

  const handleRadioChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    e.preventDefault();
    e.stopPropagation();

    const restitutionMethodValue: RestitutionMethod = e.target.value as RestitutionMethod;
    formik.setFieldValue('restitutionMethod', restitutionMethodValue);
    setRadioValue(restitutionMethodValue);
  };

  const handleIbanChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newIbanValue: string = event.target.value.trim().replace(/\s+/g, '');
    formik.setFieldValue(event.target.name, newIbanValue);
  };

  useEffect(() => {
    const ibanValue: string = formik.values.restitutionIban;
    const restitutionMethodValue: string = formik.values.restitutionMethod;
    const valuesHaveChanged: boolean = restitutionMethodValue !== restitutionMethod
      || (
        restitutionMethodValue === 'RESTITUTION_IBAN_PAYOUT'
        && ibanValue !== restitutionIban
        && ibanValue !== obfuscateIbanNumbers(restitutionIban)
      );

    setIsButtonContainerVisible(valuesHaveChanged);
  }, [restitutionIban, restitutionMethod, formik.values.restitutionIban, formik.values.restitutionMethod]);

  useEffect(() => {
  }, [restitutionMethod]);

  const cancelRestitutionUpdate = () => {
    formik.resetForm();
    setRadioValue(restitutionMethod);
    setIsButtonContainerVisible(false);
    setErrorMessage('');
  };

  async function updateRestitution(restitutionFields: RestitutionFormFields, {
    setSubmitting,
  }: FormikHelpers<UpdateAgencyRestitutionRequest>): Promise<void> {
    try {
      setErrorMessage('');
      setSubmitting(true);
      const request: UpdateAgencyRestitutionRequest = { restitutionMethod: restitutionFields.restitutionMethod };
      if (restitutionFields.restitutionMethod === 'RESTITUTION_IBAN_PAYOUT') {
        request.iban = restitutionFields.restitutionIban;
      }

      await agenciesStore.updateRestitutionMethod(request);
      if (agenciesStore.updateRestitutionStatus === 'SUCCESS') {
        formik.resetForm({
          values: {
            restitutionMethod: request.restitutionMethod,
            restitutionIban: obfuscateIbanNumbers(request.iban),
          },
        });
        setRadioValue(request.restitutionMethod);
      } else if (agenciesStore.updateRestitutionStatus === 'ERROR_MANGOPAY') {
        setErrorMessage('Impossible de créer un bankaccount chez notre fournisseur');
        formik.resetForm();
        setRadioValue(restitutionMethod);
      }
    } catch (e) {
      formik.resetForm();
      setRadioValue(restitutionMethod);
    } finally {
      setSubmitting(false);
    }
  }

  const onSubmitForm = useCallback(() => {
    formik.handleSubmit();
  }, []);

  const getFlag: (iban: string) => string = useCallback((iban: string) => {
    const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (!sanitizedIban || sanitizedIban.length < 2) {
      return '';
    }
    const countryCode: string = iban.slice(0, 2);
    return countryCode.toLowerCase();
  }, []);

  return (
    <div className={container}>
      <div className={agencyTitle}>
        {t('paymentTitle')}
      </div>

      {mandateStore.mandate && (
        <div className={orderInformationsClasses.link}>
          <a href={mandateStore.mandate.documentUrl} target="_blank" rel="noreferrer">
            <Button variant="contained" color="primary">
              {t('seeMandate')}
            </Button>
            <br/>
          </a>
        </div>
      )}

      <div className={root}>
        {agencyDetails.paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && (
          <div className={agencyInputContainer}>
            <div className={agencyInputCol6}>
              <StyledTextField
                color="secondary"
                label={t('ibanSubscriptionDissociatedMain')}
                loading={agenciesStore.isBankAccountsLoading}
                fullWidth
                value={!agenciesStore.isBankAccountsLoading
                  ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
                  : ''
                }
              />
            </div>

            <div className={agencyInputCol6}>
              <StyledTextField
                color="secondary"
                label={t('ibanSubscriptionDissociatedFees')}
                loading={agenciesStore.isBankAccountsLoading}
                fullWidth
                value={!agenciesStore.isBankAccountsLoading
                  ? agenciesStore.paymentBankAccountFees?.iban || t('notSpecified')
                  : ''
                }
              />
            </div>
          </div>
        )}

        <div className={agencyInputContainer}>
          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('holderFullName')}
              value={agencyDetails.paymentBankTitular}
              fullWidth
            />
          </div>

          <div className={agencyInputCol6}>
            {agencyDetails.paymentMethod === 'BANK_TRANSFER' && (
              <StyledTextField
                color="secondary"
                label={t('ibanSubscriptionBankTransfer')}
                loading={agenciesStore.isBankAccountsLoading}
                fullWidth
                value={!agenciesStore.isBankAccountsLoading
                  ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
                  : ''
                }
              />
            )}
            {agencyDetails.paymentMethod === 'DIRECT_DEBIT' && (
              <StyledTextField
                color="secondary"
                label={t('ibanSubscription')}
                fullWidth
                value={
                  agencyDetails.iban
                    ? `${obfuscateIbanNumbers(agencyDetails.iban)}`
                    : t('notSpecified')
                }
              />
            )}
          </div>

          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('address')}
              value={agencyDetails.paymentBankAddress}
              fullWidth
            />
          </div>

          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('additionalAddress1')}
              value={agencyDetails.paymentBankAdditionalAddress || t('notSpecified')}
              fullWidth
            />
          </div>

          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('zipcode')}
              value={agencyDetails.paymentBankZipCode}
              fullWidth
            />
          </div>

          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('city')}
              value={agencyDetails.paymentBankCity}
              fullWidth
            />
          </div>

          <div className={agencyInputCol6}>
            <StyledTextField
              color="secondary"
              label={t('country')}
              value={t(`country:${agencyDetails.paymentBankCountryCode}`)}
              fullWidth
            />
          </div>
        </div>
      </div>

      <div className={agencyTitle} style={{ textTransform: 'uppercase' }}>
        {t('restitutionTitle')}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={restitutionMethodContainer}>

          <FormControl>
            <RadioGroup
              id="restitutionMethod"
              name="resitution-radio-buttons-group"
              value={formik.values.restitutionMethod}
              onChange={handleRadioChanges}
            >
              <FormControlLabel
                value="PROVISIONS_WALLET_DEPOSIT"
                control={<Radio style={{ color: COLORS.textPrimary }}></Radio>}
                label={t('PROVISIONS_WALLET_DEPOSIT')}
              />
              <FormHelperText style={{ paddingLeft: '33px', marginTop: '-6px' }}>
                {t('PROVISIONS_WALLET_DEPOSIT_HELPER_TEXT')}
              </FormHelperText>

              {agencyDetails.paymentMethod === 'DIRECT_DEBIT' && (<>
                <FormControlLabel
                  value="DIRECT_DEBIT_IBAN_PAYOUT"
                  control={<Radio style={{ color: COLORS.textPrimary }}/>}
                  label={t('DIRECT_DEBIT_IBAN_PAYOUT')}
                  style={{ marginTop: '32px' }}
                />
                <FormHelperText style={{ paddingLeft: '33px', marginTop: '-6px' }}>
                  {t('DIRECT_DEBIT_IBAN_PAYOUT_HELPER_TEXT')}
                </FormHelperText>
              </>)}

              <FormControlLabel
                value="RESTITUTION_IBAN_PAYOUT"
                control={<Radio style={{ color: COLORS.textPrimary }}/>}
                label={t('RESTITUTION_IBAN_PAYOUT')}
                style={{ marginTop: '32px' }}
              />
              <FormHelperText style={{ paddingLeft: '33px', marginTop: '-6px' }}>
                {t('RESTITUTION_IBAN_PAYOUT_HELPER_TEXT')}
              </FormHelperText>

            </RadioGroup>
          </FormControl>

          {formik.values.restitutionMethod === 'RESTITUTION_IBAN_PAYOUT' && (
            <FormControl>
              <AgencyTextField
                id="restitutionIban"
                formik={formik}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleIbanChange(event)}
                countryFlag={getFlag(formik.values.restitutionIban)}
                required
              />
            </FormControl>
          )}

        </div>

        {isButtonContainerVisible && (
          <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginBottom: '40px' }}>
            <Button
              className={restitutionMethodContainerButtons}
              onClick={cancelRestitutionUpdate}
              type="button"
              disabled={agenciesStore.isUpdatingRestitution}
              variant="contained"
              color="secondary"
            >
              {t('cancel')}
            </Button>

            <Button
              className={restitutionMethodContainerButtons}
              onClick={onSubmitForm}
              type="button"
              isFetching={agenciesStore.isUpdatingRestitution}
              variant="contained"
              color="primary"
            >
              {t('updateRestitution')}
            </Button>
          </div>
        )}

        {errorMessage && (<SnackErrorComponent message={errorMessage}/>)}
      </form>
    </div>
  );
});

export default PaymentInformationComponent;
