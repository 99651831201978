import { manageError } from '../errors';
import { MillesimeMetadataModel } from '../../models/millesime/Millesime.model';
import { AgencyModel } from '../../models/agencies/Agency.model';
import { getMonth, getYear } from '../dates/dateTime.util';
import { getTimeStampEndOfDay, toDate } from '../dates/getParsedDate.util';

// region RightsReconductionType
export function isAllowedToUpdateRightsReconductionType(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { rightsReconductionUpdateLimit, scriptExecutionStatus } = millesimeMetadata;

  if (!rightsReconductionUpdateLimit || !scriptExecutionStatus) {
    manageError({
      code: 'failed-precondition', message: 'RightsReductionUpdateLimit or ScriptExecutionStatus can not be undefined',
    });
  }

  const now: number = new Date().getTime();

  return (now <= rightsReconductionUpdateLimit) && (scriptExecutionStatus === 'TODO');
}

// endregion

// region Emails & EmailContacts
export function isAllowedToAddAllEmailContacts(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { emailContactsStatus, addedAgenciesUids } = millesimeMetadata;

  if (!emailContactsStatus || !addedAgenciesUids) {
    manageError({
      code: 'failed-precondition', message: 'EmailContactsStatus or addedAgenciesUids can not be undefined',
    });
  }

  return emailContactsStatus === 'TODO' && !addedAgenciesUids.length;
}

export function isAllowedToAddEmailContactsForCurrentAgency(millesimeMetadata: MillesimeMetadataModel, currentAgency: AgencyModel): boolean {
  const { emailContactsStatus, initialAgenciesUids, addedAgenciesUids } = millesimeMetadata;
  const { uid } = currentAgency;

  if (!emailContactsStatus || !initialAgenciesUids || !addedAgenciesUids) {
    manageError({
      code: 'failed-precondition',
      message: 'EmailContactsStatus, initialAgenciesUids or addedAgenciesUids can not be undefined',
    });
  }

  const existInWishList = initialAgenciesUids.includes(uid) && !addedAgenciesUids.includes(uid);

  return emailContactsStatus === 'TODO' && isAgencyContractAuthorized(currentAgency) && existInWishList ;
}

export function isAllowedToUpdateAllEmailContacts(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { emailContactsStatus } = millesimeMetadata;

  if (!emailContactsStatus) {
    manageError({
      code: 'failed-precondition', message: 'EmailContactsStatus can not be undefined',
    });
  }

  return emailContactsStatus !== 'TODO';
}

// region Beneficiary Usage Information Email
export function isAllowedToUpdateUsageInfoForAllEmailContacts(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { emailContactsStatus, isUsageInformationEmailSent } = millesimeMetadata;

  if (!emailContactsStatus || (typeof isUsageInformationEmailSent !== 'boolean')) {
    manageError({
      code: 'failed-precondition', message: 'EmailContactsStatus or isUsageInformationEmailSent can not be undefined',
    });
  }

  const isAcceptedStatusToUpdate = (emailContactsStatus === 'CONTACTS_ADDED') || (emailContactsStatus === 'USAGE_INFOS_UPDATED');

  return isAcceptedStatusToUpdate && !isUsageInformationEmailSent;
}

export function isAllowedToUpdateUsageInfoForEmailContactsOfCurrentAgency(millesimeMetadata: MillesimeMetadataModel, currentAgency: AgencyModel): boolean {
  const { initialAgenciesUids, updatedAgenciesUidsWithUsageInfo } = millesimeMetadata;
  const { uid } = currentAgency;

  if (!initialAgenciesUids || !updatedAgenciesUidsWithUsageInfo) {
    manageError({
      code: 'failed-precondition',
      message: 'InitialAgenciesUids or updatedAgenciesUidsWithUsageInfo can not be undefined',
    });
  }

  const existInWishList = initialAgenciesUids.includes(uid) && !updatedAgenciesUidsWithUsageInfo.includes(uid);

  return isAllowedToUpdateUsageInfoForAllEmailContacts(millesimeMetadata) && isAgencyContractAuthorized(currentAgency) && existInWishList ;
}

export function isAllowedToSendUsageInformationEmail(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { isUsageInformationEmailSent, emailContactsStatus } = millesimeMetadata;

  if (typeof isUsageInformationEmailSent !== 'boolean' || !emailContactsStatus) {
    manageError({
      code: 'failed-precondition',
      message: 'UsageInformationEmailSendingStatus or EmailContactsStatus can not be undefined',
    });
  }

  return !isUsageInformationEmailSent && (emailContactsStatus === 'USAGE_INFOS_UPDATED');
}

// endregion

// region Beneficiary Balance Regulation Report Email
export function isAllowedToUpdateBalanceRegulationReportForAllEmailContacts(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { emailContactsStatus, isUsageInformationEmailSent, isBalanceRegulationReportEmailSent } = millesimeMetadata;

  if (!emailContactsStatus || (typeof isUsageInformationEmailSent !== 'boolean') || (typeof isBalanceRegulationReportEmailSent !== 'boolean')) {
    manageError({
      code: 'failed-precondition',
      message: 'EmailContactsStatus, isUsageInformationEmailSent or isBalanceRegulationReportEmailSent can not be undefined',
    });
  }

  const isAcceptedStatusToUpdate = (emailContactsStatus === 'USAGE_INFOS_UPDATED') || (emailContactsStatus === 'BALANCE_REGULATION_INFOS_UPDATED');

  return isAcceptedStatusToUpdate && isUsageInformationEmailSent && !isBalanceRegulationReportEmailSent;
}

export function isAllowedToUpdateBalanceRegulationReportForEmailContactsOfCurrentAgency(millesimeMetadata: MillesimeMetadataModel, currentAgency: AgencyModel): boolean {
  const { initialAgenciesUids, updatedAgenciesUidsWithBalanceRegulationInfo } = millesimeMetadata;
  const { uid } = currentAgency;

  if (!initialAgenciesUids || !updatedAgenciesUidsWithBalanceRegulationInfo) {
    manageError({
      code: 'failed-precondition',
      message: 'InitialAgenciesUids or updatedAgenciesWithBalanceRegulationInfo can not be undefined',
    });
  }

  const existInWishList = initialAgenciesUids.includes(uid) && !updatedAgenciesUidsWithBalanceRegulationInfo.includes(uid);

  return isAllowedToUpdateBalanceRegulationReportForAllEmailContacts(millesimeMetadata) && isAgencyContractAuthorized(currentAgency) && existInWishList ;
}

export function isAllowedToSendBalanceRegulationReportEmail(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { isBalanceRegulationReportEmailSent, emailContactsStatus } = millesimeMetadata;

  if ((typeof isBalanceRegulationReportEmailSent !== 'boolean') || !emailContactsStatus) {
    manageError({
      code: 'failed-precondition',
      message: 'BalanceRegulationReportEmailSendingStatus and ScriptExecutionStatus can not be undefined',
    });
  }

  return !isBalanceRegulationReportEmailSent && (emailContactsStatus === 'BALANCE_REGULATION_INFOS_UPDATED');
}

// endregion
// endregion

// region Millesime Script
export function isAllowedToExecuteScript(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { scriptExecutionStatus, scriptExecutionAvailabilityDate } = millesimeMetadata;

  if (!scriptExecutionStatus || !scriptExecutionAvailabilityDate) {
    manageError({
      code: 'failed-precondition',
      message: 'ScriptExecutionStatus and ScriptExecutionAvailabilityDate can not be undefined',
    });
  }

  const now: number = new Date().getTime();

  return (now >= scriptExecutionAvailabilityDate) && (scriptExecutionStatus === 'TODO');
}

export function isAllowedToExportScriptExecutionReport(millesimeMetadata: MillesimeMetadataModel): boolean {
  const { scriptExecutionStatus } = millesimeMetadata;

  if (!scriptExecutionStatus) {
    manageError({
      code: 'failed-precondition', message: 'ScriptExecutionStatus can not be undefined',
    });
  }

  return scriptExecutionStatus === 'DONE';
}
// endregion

// region Agency Validity
export function isAgencyContractAuthorized(agency: AgencyModel): boolean {
  if (!agency) {
    manageError({ code: 'failed-precondition', message: 'Agency object cannot be undefined' });
  }

  return agency.contractType !== 'ONE_SHOT';
}
//endregion

export function endDateMillesime(): number {
  const nextYear: number = new Date().getFullYear() + 1;
  const endMillesimeDate: Date = new Date(nextYear, 2, 1);

  return new Date(endMillesimeDate.setDate(endMillesimeDate.getDate() - 1)).setHours(23, 59, 59, 999);
}

export function getEndOfMillesimeDate(date: Date): Date {
  const millesimeYear = getMonth(date) > 2 ? getYear(date) + 1 : getYear(date);
  const nextMillesimeStartingDate: Date = new Date(millesimeYear, 2, 1);
  const endOfMillesimeDate: Date = toDate(nextMillesimeStartingDate.setDate(nextMillesimeStartingDate.getDate() - 1));

  return toDate(getTimeStampEndOfDay(endOfMillesimeDate));
}
