import React, { FunctionComponent, useEffect, useState } from 'react';
import { BeneficiariesCountResponse } from '../../../assets/responses/beneficiaries/BeneficiariesCount.response';
import { AgencyAdminStore } from '../../../Stores/AgencyAdmin.store';
import { PageBlock } from '../../../Style/Style';
import exclamation from '../../../assets/exclamation.svg';
import forkAndSpoon from '../../../assets/fork-spoon.svg';
import userSvg from '../../../assets/user.svg';
import graph from '../../../assets/graph.svg';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDashboardRecapStyles } from './DashboardRecapStyles';
import { observer } from 'mobx-react-lite';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore } from '../../../Stores/Beneficiaries.store';
import { getAgencyParticipationPercent } from '../../../Utils/AgencyContractValues.service';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '../../../assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import SnackErrorComponent from '../../../Component/SnackbarError/SnackErrorComponent';

export interface IDashboardRecapComponentComponentProps {
}

type LocalStore = [AgenciesStore, AgencyAdminStore, BeneficiariesStore];

const DashboardRecapComponent: FunctionComponent<IDashboardRecapComponentComponentProps> = observer(() => {
  const [agenciesStore, agencyAdminStore, beneficiariesStore]: LocalStore = useInstances<LocalStore>(AgenciesStore, AgencyAdminStore, BeneficiariesStore);
  const [activeBeneficiariesCount, setActiveBeneficiariesCount] = useState<number>(0);
  const [unOnboardedBeneficiariesCount, setUnOnboardedBeneficiariesCount] = useState<number>(0);
  const [isFetchingBeneficiariesCount, setIsFetchingBeneficiariesCount] = useState<boolean>(true);
  const [isFetchingSendEmail, setIsFetchingSendEmails] = useState<boolean>(false);
  const [reportMessage, setReportMessage] = useState<string>('');

  const dashboardRecapClasses = useDashboardRecapStyles();
  const { t } = useTranslation('dashboard');

  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const successMessage: string = 'Un mail de relance a bien été envoyé à vos collaborateurs concernés';
  const errorMessage: string = 'Nous avons rencontré un problème et nous n’avons pas pu envoyer le mail de relance à vos collaborateurs concernés';

  async function getBeneficiariesCounter(): Promise<void> {
    setIsFetchingBeneficiariesCount(true);

    const beneficiariesCount: BeneficiariesCountResponse = await beneficiariesStore.fetchBeneficiariesCountByAgencyId(agenciesStore.currentAgency.uid);
    setActiveBeneficiariesCount(beneficiariesCount.activeBeneficiariesCount);
    setUnOnboardedBeneficiariesCount(beneficiariesCount.unOnboardedBeneficiariesCount);

    setIsFetchingBeneficiariesCount(false);
  }

  useEffect(() => {
    setReportMessage('');
    if (agenciesStore.currentAgency) {
      (async () => await getBeneficiariesCounter())();
    }
    return setReportMessage('');
  }, [agenciesStore.currentAgency]);

  const sendEmails = async () => {
    setIsFetchingSendEmails(true);
    try {
      const report: SendMailsToUnboardedBeneficiariesResponse = await beneficiariesStore.sendBatchedMailsToUnOnboardedBeneficiaries({ agencyId: agenciesStore.currentAgency.uid });

      if (report.mailsFailed.length) {
        setReportMessage(errorMessage);
      } else if (report.mailsSent.length) {
        setReportMessage(successMessage);
      }
    } catch (e) {
      setReportMessage(errorMessage);
    } finally {
      setIsFetchingSendEmails(false);
    }
  };

  return (
    <div className={dashboardRecapClasses.root}>
      <PageBlock className={dashboardRecapClasses.pageBlock}>
        <div className={dashboardRecapClasses.block}>
          <div className={dashboardRecapClasses.hello}>👋 Hello,</div>
          <div className={dashboardRecapClasses.hello}> {agencyAdminStore.agencyAdminSelected?.firstName}
            <img alt="exclamation logo" src={exclamation}/>
          </div>
        </div>
        <div className={dashboardRecapClasses.block}>
          {isFetchingBeneficiariesCount
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <img width={32} alt="user logo" src={userSvg}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {!isFetchingBeneficiariesCount && activeBeneficiariesCount}
                </span>
              </div>
              <div>
                {t('beneficiariesPartOne', { count: activeBeneficiariesCount })}
                {unOnboardedBeneficiariesCount !== 0 && <>
                    , 🔥 {t('beneficiariesPartTwo', { count: unOnboardedBeneficiariesCount })}&nbsp;
                  {isFetchingSendEmail
                    ? <CircularProgress color={'primary'}/>
                    : <>  <span onClick={() => sendEmails()} className={dashboardRecapClasses.link}>
                    {t('beneficiariesPartThree', { count: unOnboardedBeneficiariesCount })}
                  </span>&nbsp;?</>}
                </>
                }
              </div>
            </>
          }
        </div>
        <div className={dashboardRecapClasses.block}>
          {!agenciesStore.currentAgency
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <img width={32} alt="forkAndSpoon logo" src={forkAndSpoon}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {agenciesStore.currentAgency?.contractFacialValue
                    ? currencyFormat.format(agenciesStore.currentAgency?.contractFacialValue)
                    : '-'
                  }
                </span>
              </div>
              <div>
                {t('dailyAmount')}
              </div>
            </>
          }
        </div>
        <div className={dashboardRecapClasses.block}>
          {!agenciesStore.currentAgency
            ? <CircularProgress color={'primary'}/>
            : <>
              <div className={dashboardRecapClasses.flex}>
                <img width={32} alt="graph logo" src={graph}/>
                <span className={dashboardRecapClasses.blockContent}>
                  {agenciesStore.currentAgency.contractAgencyPart
                    ? currencyFormat.format(agenciesStore.currentAgency.contractAgencyPart)
                    : '-'
                  }
                </span>
              </div>
              {(agenciesStore.currentAgency.contractAgencyPart && agenciesStore.currentAgency.contractFacialValue) &&
                  <div>
                <span>
                  {t(
                    'contribution',
                    { companyQuota: (getAgencyParticipationPercent(agenciesStore.currentAgency).toFixed(2)) },
                  )}
                </span>
                  </div>
              }
            </>
          }
        </div>
      </PageBlock>
      {reportMessage && <SnackErrorComponent message={reportMessage}/>}
    </div>
  );
});

export default DashboardRecapComponent;
