import { action, makeAutoObservable, runInAction } from 'mobx';
import {
  getAgencyBankAccountAlias,
  getAgencyByAgencyId,
  getAllAgenciesOfAgencyAdmin, updateAgencyRestitution,
} from '../Services/agenciesAPI.service';
import { AgencyModel, RestitutionMethod } from '@assets/models/agencies/Agency.model';
import { PaymentBankAccountAlias } from '@assets/models/payments/PaymentBankAccountAlias.model';
import { PaymentBankAccountsModel } from '@assets/models/payments/PaymentBankAccounts.model';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';
import { errorStore } from './Error.store';

type Status = 'PENDING' | 'SUCCESS' | 'ERROR' | 'ERROR_MANGOPAY';

export class AgenciesStore {
  isAgenciesLoaded: string = 'pending';
  updateRestitutionStatus: Status = 'PENDING';
  isCurrentAgencyLoaded: boolean = false;
  isBankAccountsLoading: boolean = false;
  private _isUpdatingRestitution: boolean = false;
  agenciesList: AgencyModel[] = [];
  currentAgency: AgencyModel | null = null;
  currentAgencyBankAccounts: PaymentBankAccountsModel | null = null;
  private currentRestitutionIban = '';
  private currentRestitutionMethod: RestitutionMethod | null = null;

  get paymentBankAccountMain(): PaymentBankAccountAlias {
    return this.currentAgencyBankAccounts?.main;
  }

  get paymentBankAccountFees(): PaymentBankAccountAlias | null {
    return this.currentAgencyBankAccounts?.fees;
  }

  get hasFeesCustomerReferenceArea(): boolean {
    return this.isDissociatedPayment && this.hasCustomerReferenceArea;
  }

  get hasCustomerReferenceArea(): boolean {
    return !!this.currentAgency?.customerReferenceArea;
  }

  get hasInternalOrderNumberArea(): boolean {
    return !!this.currentAgency?.internalOrderNumberArea;
  }

  get hasMarketAndCommitmentNumberArea(): boolean {
    return !!this.currentAgency?.marketAndCommitmentNumberArea;
  }

  get isDissociatedPayment(): boolean {
    return this.currentAgency?.paymentMethod === 'DISSOCIATED_BANK_TRANSFER';
  }

  get hasInvoiceInformation(): boolean {
    return this.hasMarketAndCommitmentNumberArea || this.hasInternalOrderNumberArea || this.hasCustomerReferenceArea;
  }

  get restitutionMethod(): RestitutionMethod | null {
    return this.currentRestitutionMethod;
  }

  get restitutionIban(): string {
    return this.currentRestitutionIban;
  }

  get isUpdatingRestitution(): boolean {
    return this._isUpdatingRestitution;
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset(): void {
    this.isAgenciesLoaded = 'pending';
    this.updateRestitutionStatus = 'PENDING';
    this.isCurrentAgencyLoaded = false;
    this._isUpdatingRestitution = false;
    this.agenciesList = null;
    this.currentAgency = null;
    this.currentAgencyBankAccounts = null;
    this.currentRestitutionIban = '';
    this.currentRestitutionMethod = null;
    localStorage.removeItem('currentAgency');
  }

  @action setIsUpdatingRestitution(value: boolean) {
    this._isUpdatingRestitution = value;
  }

  @action setCurrentAgency(agency: AgencyModel): void {
    this.currentAgency = agency;
    this.setCurrentRestitutionMethod(agency.restitutionMethod);
    this.setCurrentRestitutionIban(agency.restitutionIban || '');
    localStorage.setItem('currentAgency', JSON.stringify(agency));
  }

  @action setPendingCurrentAgency(): void {
    const jsonStringCurrentAgency = localStorage.getItem('currentAgency');
    const pendingCurrentAgency = JSON.parse(jsonStringCurrentAgency);

    if (pendingCurrentAgency) {
      this.setCurrentAgency(pendingCurrentAgency);
    }
  }

  @action setCurrentRestitutionMethod(method: RestitutionMethod) {
    this.currentRestitutionMethod = method;
  }

  @action setCurrentRestitutionIban(iban: string) {
    this.currentRestitutionIban = iban;
  }

  async fetchAllAgenciesOfConnectingAdmin(companyId: string, agencyAdminId: string) {
    try {
      const agenciesList: AgencyModel[] = await getAllAgenciesOfAgencyAdmin(companyId, agencyAdminId);
      runInAction(() => {
        if (agenciesList) {
          this.agenciesList = agenciesList;
          this.isAgenciesLoaded = 'success';
          this.isCurrentAgencyLoaded = true;
          if (!this.currentAgency) {
            this.setCurrentAgency(agenciesList[0]);
          }
        }
      });
    } catch (e) {
      this.isAgenciesLoaded = 'error load agencies';
    }
  }

  async getAgencyBankAccountAlias(): Promise<PaymentBankAccountAlias> {
    const { companyId, uid } = this.currentAgency;
    this.isBankAccountsLoading = true;
    const bankAccounts: PaymentBankAccountsModel = await getAgencyBankAccountAlias(companyId, uid);
    runInAction(() => {
      if (bankAccounts) {
        this.currentAgencyBankAccounts = bankAccounts;
      }

      this.isBankAccountsLoading = false;
    });
    return bankAccounts.main;
  }

  async updateRestitutionMethod(request: UpdateAgencyRestitutionRequest): Promise<void> {
    const { companyId, uid: agencyId } = this.currentAgency;
    const { restitutionMethod, iban } = request;
    try {
      this.setIsUpdatingRestitution(true);
      const confirmationResponse: ConfirmationResponse = await updateAgencyRestitution(companyId, agencyId, request);
      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.setCurrentRestitutionMethod(restitutionMethod);
          this.setCurrentRestitutionIban(iban ?? '');
          if (restitutionMethod !== 'RESTITUTION_IBAN_PAYOUT') {
            this.setCurrentRestitutionIban('');
          }
          this.updateRestitutionStatus = 'SUCCESS';
        } else {
          this.updateRestitutionStatus = 'ERROR';
        }
      });
    } catch (e) {
      this.updateRestitutionStatus = 'ERROR';
      if (errorStore.messageToDisplay === 'Error while creating restitution banking account on MANGOPAY side') {
        this.updateRestitutionStatus = 'ERROR_MANGOPAY';
        errorStore.dismiss(e);
      }
    } finally {
      this.setIsUpdatingRestitution(false);
    }
  }
}

export class AgencyDetailsStore {
  isAgencyLoaded: boolean = false;
  agencyDetails: AgencyModel = null;
  agencyError: string;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action reset() {
    this.isAgencyLoaded = false;
    this.agencyDetails = null;
    this.agencyError = null;
  }

  async getOneAgency(agencyId: string): Promise<AgencyModel> {
    try {
      this.isAgencyLoaded = true;
      const agencyDetailsRes = await getAgencyByAgencyId(agencyId);
      runInAction(() => {
        this.agencyDetails = agencyDetailsRes;
        this.isAgencyLoaded = false;
      });
      return agencyDetailsRes;
    } catch (e) {
      this.isAgencyLoaded = false;
      this.agencyError = 'error agency loaded';
    }
  }
}
