import React from 'react';
import { GridCellParams, GridCellValue, GridColDef } from '@material-ui/data-grid';
import { useTranslation } from 'react-i18next';
import capitalizeFirstLetter from '../Function/CapitalizeFirstLetter';

export const columnsBeneficiaries: GridColDef[] = [
  {
    field: 'registrationNumber',
    headerName: 'Matricule',
    flex: 1,
  },
  {
    field: 'title',
    headerName: 'Titre',
    flex: 1,
    renderHeader: function titleHeader() {
      return (
        <div className={'MuiDataGrid-colCellTitle'} style={{ width: '100%' }}>Titre</div>
      );
    },
    renderCell: function titleCell(params: GridCellParams) {
      const { t } = useTranslation('beneficiaries');
      return (
        <div style={{ width: '100%' }}>{t(params.value.toString())} </div>
      );
    },
  },
  {
    field: 'lastName',
    headerName: 'Nom',
    flex: 2,
    renderCell: function titleCell(params: GridCellParams) {
      return (
        <div style={{ width: '100%' }}>{params.value.toString().toUpperCase()} </div>
      );
    },
  },
  {
    field: 'firstName',
    headerName: 'Prénom',
    flex: 2,
    renderCell: function titleCell (params: GridCellParams) {
      return (
        <div style={{ width: '100%' }}>{capitalizeFirstLetter(params.value.toString())} </div>
      );
    },
  },
  {
    field: 'email',
    headerName: 'Adresse Email',
    flex: 2,
  },
  {
    field: 'isActive',
    headerName: 'Actif',
    flex: 1,
    sortComparator: (value: boolean) => value ? 1 : -1,
    renderCell: function isActiveCell(params: GridCellParams) {
      return (
        <div style={{
          width: 8,
          height: 8,
          borderRadius: 8,
          backgroundColor: params.value ? '#8CD867' : '#E94B32',
        }}/>
      );
    },
  },
];
