import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useNavigate } from 'react-router';
import logoText from '../assets/logo-text-new.svg';
import MailSvg from '../assets/mail';
import resetSuccess from '../assets/reset-pass-success.svg';
import { UserConnectStore } from '../Stores/UserConnect.store';
import { LoginViewStore } from '../Stores/viewStore/LoginView.store';
import Button from '../Style/MuiStyles/Button';
import StyledTextField from '../Style/MuiStyles/TextField';
import { validationEmail } from '../Utils/Login.service';
import { useConnectionStyles } from './Login/Connection/ConnectionStyles';
import { useLoginComponentStyles } from './Login/LoginStyles';
import { requestPasswordChangeForAgencyAdmin } from '../Services/authAPI.service';
import {sanitizeEmail} from "../assets/utils/functions/sanitizeEmail.util";

type LocalStore = [UserConnectStore, LoginViewStore];
type LocalFormValue = {
  email: string
};

const LoginView = observer(() => {

  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');
  const loginComponentClasses = useLoginComponentStyles();
  const connectionClasses = useConnectionStyles();
  const search = location.search;
  const params: URLSearchParams = new URLSearchParams(search);
  const [email, setEmail] = useState(params.get('email') ?? '');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userConnectStore.isConnected) {
      navigate('/dashboard');
    }
  }, [userConnectStore.isConnected]);

  async function loginEmail({ email }: LocalFormValue, setSubmitting: (isSubmitting: boolean) => void) {
    setSubmitting(true);
    try {
      const sanitizedEmail: string = sanitizeEmail(email);
      loginViewStore.setEmail(sanitizedEmail);
      const { imported, hasAlreadyChangedPassword } = await userConnectStore.hasAlreadyChangedPassword(sanitizedEmail);

      if (!imported && !hasAlreadyChangedPassword) {
        loginViewStore.openFirstConnexionScreen();
      } else if (imported && !hasAlreadyChangedPassword) {
        await userConnectStore.requestPasswordChange(email);
        loginViewStore.openHasChangedPasswordScreen();
      } else {
        navigate('/login/password');
      }
    } catch (e) {
      navigate('/login/password');
    }

    setSubmitting(false);
  }

  async function handleResetPasswordRequest({ email }: LocalFormValue): Promise<void> {
    try {
      setIsLoading(true);
      await requestPasswordChangeForAgencyAdmin({ email });
      setIsLoading(false);
      loginViewStore.hasChangedPasswordScreen = true;
      loginViewStore.firstConnexionScreen = false;
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: validationEmail,
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => loginEmail(values, setSubmitting),
  });

  return (
    <div className={loginComponentClasses.root}>
      <div className={loginComponentClasses.leftBlock}>
        {t('hello')}<br/>
        {t('hello2')}
      </div>
      <div className={loginComponentClasses.rightBlock}>
        <div className={loginComponentClasses.content}>
          {(loginViewStore.hasChangedPasswordScreen === false && loginViewStore.firstConnexionScreen === false) &&
              <>
                  <div className={loginComponentClasses.textAlignCenter}>
                      <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
                      <p style={{ fontWeight: 'bold' }}>{t('subtitle')}</p>
                  </div>
                  <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit} noValidate>
                      <StyledTextField
                          className={connectionClasses.input}
                          id="email"
                          name="email"
                          label={t('email')}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.errors.email) && formik.touched.email}
                          InputProps={{ endAdornment: <MailSvg style={{ marginTop: '20px' }}/> }}
                          fullWidth
                          required
                          placeholder={t('emailPlaceholder')}
                          errormessage={formik.errors.email}
                          autoFocus
                          autoComplete="email"
                      />
                      <Button
                          variant="contained"
                          style={{ float: 'right' }}
                          color="primary"
                          isFetching={formik.isSubmitting}
                          disabled={formik.isSubmitting}
                          type="submit"
                      >
                        {t('next')}
                      </Button>
                  </form>
              </>}
          {loginViewStore.hasChangedPasswordScreen === true &&
              <>
                  <div className={loginComponentClasses.imgContent}>
                      <div>
                          <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
                      </div>
                      <div>
                          <img className={loginComponentClasses.logo} alt="logo" width="125px" src={resetSuccess}/>
                      </div>
                      <p style={{ paddingLeft: '1rem' }}>{t('isUserImportedMessage')}</p>
                      <p style={{ paddingLeft: '1rem' }}>{t('isUserImportedMessage_two')}</p>
                  </div>
              </>}
          {loginViewStore.firstConnexionScreen === true &&
              <>
                  <div className={loginComponentClasses.imgContent}>
                      <div>
                          <img className={loginComponentClasses.logo} alt="logo" width="198px" src={logoText}/>
                      </div>
                      <div>
                          <img className={loginComponentClasses.logo} alt="logo" width="125px" src={resetSuccess}/>
                      </div>
                      <p style={{ paddingLeft: '1rem' }}>{t('firstConnexion')}</p>
                      <p style={{ paddingLeft: '1rem' }}>{t('firstConnexion_tow')}</p>
                      <Button
                          variant="contained"
                          color="primary"
                          isFetching={isLoading}
                          disabled={isLoading}
                          type="button"
                          onClick={() => handleResetPasswordRequest({ email: formik.values.email })}
                      >
                        {t('firstConnexion_three')}
                      </Button>
                  </div>
              </>}
        </div>
      </div>
    </div>
  );
});

export default LoginView;