import { makeStyles } from '@material-ui/core';

export const usePaymentRequestStyles = makeStyles(() => ({
  block: {
    backgroundColor: '#F7F7F8',
    border: '1px solid #D5D9DC',
    borderRadius: '20px',
    height: '240px',
    padding: '30px 12.5px 12.5px 12.5px',
    width: '230px',
    maxWidth: '250px',
    flex: '1 1 auto'
  },
  paymentRequests: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '50px'
  },
  icon: {
    width: 'inherit',
    height: 'inherit'
  },
  newPaymentRequests: {
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  }
}));
export const usePaymentRequestBlockStyles = makeStyles(() => ({
  statusBlock: {
    display: 'flex',
    fontSize: '14px',
    flexDirection: 'row',
    marginBottom: '6px',
    width: '100%'
  },
  label: {
    fontSize: '14px'
  },
  value: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '1.25rem'
  },
  date: {
    textTransform: 'capitalize'
  },
  download: {
    backgroundColor: '#86D0EF',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    marginLeft: 'auto',
    placeItems: 'center',
    placeContent: 'center',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      backgroundColor: '#79BEDB'
    }
  }
}));
