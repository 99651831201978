import { Snackbar, SnackbarContent } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { useLocation, useNavigate } from 'react-router-dom';
import closedEye from '../../assets/closed-eye.svg';
import eye from '../../assets/eye.svg';
import logoText from '../../assets/logo-text-new.svg';
import { UserConnectStore } from '../../Stores/UserConnect.store';
import { LoginViewStore } from '../../Stores/viewStore/LoginView.store';
import Button from '../../Style/MuiStyles/Button';
import ButtonComponent from '../../Style/MuiStyles/Button';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { regexPassword } from '../../Utils/regexList';
import { useConnectionStyles } from '../Login/Connection/ConnectionStyles';
import { useResetPasswordStyles } from './ResetPasswordStyles';
import mailSent from '../../assets/mailSent.svg';
import PageLoaderComponent from '../../Component/PageLoader/PageLoaderComponent';

type LocalStore = [UserConnectStore, LoginViewStore];

interface LocalFormValue {
  password: string;
  confirmPassword: string;
}

interface PasswordComplexityReport {
  hasTwelveChar: boolean;
  hasALowerCase: boolean;
  hasAUpperCase: boolean;
  hasADigit: boolean;
  hasASpecial: boolean;
}

function testPassword(password: string): PasswordComplexityReport {
  const specialChars = /[^\da-zA-Z]/;
  const checkDigit = /\d/;

  return {
    hasTwelveChar: password.length > 11,
    hasALowerCase: password.toUpperCase() !== password,
    hasAUpperCase: password.toLowerCase() !== password,
    hasADigit: checkDigit.test(password),
    hasASpecial: specialChars.test(password),
  };
}

const ResetPasswordComponent: FunctionComponent = observer(() => {
  const connectionClasses = useConnectionStyles();

  const [userConnectStore, loginViewStore]: LocalStore = useInstances<LocalStore>(UserConnectStore, LoginViewStore);
  const {isNewUser, isNewUrlSent, isHashExpired, isFetching, isHashValidating}: UserConnectStore = userConnectStore;
  const {isNewPasswordHidden, isNewConfirmPasswordHidden}: LoginViewStore = loginViewStore;

  const [passwordComplexityReport, setPasswordComplexityReport] = useState<PasswordComplexityReport>(testPassword(''));
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const hashedPassword = params.get('data');
  const email = params.get('email');

  const resetPasswordClasses = useResetPasswordStyles();
  const { t } = useTranslation('resetpassword');

  async function getNewUrl() {
    await userConnectStore.requestPasswordChange(email);
  }

  const resetPassword: ({ password }: { password: any }, setSubmitting) => Promise<void> = useCallback(async ({ password }, setSubmitting) => {
    setSubmitting(true);
    await userConnectStore.manageResetPassword(hashedPassword, email, password)
    if (userConnectStore.resetPasswordStatus === 'SUCCESS') {
      navigate(`/login?email=${encodeURI(email)}`);
    } else {
      setShowErrorSnackbar(true);
    }
    setSubmitting(false);
  }, [hashedPassword, email]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: (
      values: LocalFormValue,
      { setSubmitting }: FormikHelpers<LocalFormValue>,
    ) => resetPassword(values, setSubmitting),
  });

  const activeSubmit: (pass: string, confirmPass: string) => boolean = useCallback((pass: string, confirmPass: string) => {
    const testPass = regexPassword.test(pass);
    return testPass && pass === confirmPass;
  }, []);

  useEffect(() => {
    userConnectStore.setLocationPathName(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    userConnectStore.userLogout();
    void userConnectStore.testUrlExpire(email, hashedPassword);
  }, []);

  useEffect(() => {
    setPasswordComplexityReport(testPassword(formik.values.password));
  }, [formik.values.password]);

  if (isHashValidating) {
    return <PageLoaderComponent/>;
  }

  return (
    <div className={resetPasswordClasses.block}>
      <div className={resetPasswordClasses.blockLeft}>
        <div style={{ maxWidth: 578 }}>
          {isNewUser
            ? <span>{t('title')}</span>
            : <span>{t('title2')}</span>
          }
        </div>
      </div>
      <div className={resetPasswordClasses.blockRight}>
        <div className={resetPasswordClasses.blockRightContent}>
          <div className={resetPasswordClasses.blockLogo}>
            <img
              className={resetPasswordClasses.logo}
              alt="logo"
              width="198px"
              src={logoText}
              onClick={() => navigate('/login')}/>
          </div>
          {isHashExpired
            ? <>
              <div className={resetPasswordClasses.subtitle}>
                {isNewUrlSent
                  ? <div>
                      <div>
                        <img alt="logo" width="125px" src={mailSent}/>
                      </div>
                      <p style={{paddingTop: '2rem'}}>{t('newUrlSent')}</p>
                    </div>
                  : t('linkExpired')
                }
              </div>
              {!isNewUrlSent &&
                  <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                      <ButtonComponent
                          variant="contained"
                          color="primary"
                          onClick={getNewUrl}
                          disabled={isFetching}
                          isFetching={isFetching}
                      >
                        {t('expiredUrlButtonLabel')}
                      </ButtonComponent>
                  </div>
              }
            </>
            : <>
              <div className={resetPasswordClasses.subtitle}>
                {isNewUser ? t('subtitle') : t('subtitle_tow')}
              </div>
              <form style={{ paddingTop: '3rem' }} onSubmit={formik.handleSubmit} noValidate>
                <StyledTextField
                  className={connectionClasses.input}
                  id="password"
                  name="password"
                  label={t('inputPassword')}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.password) && formik.touched.password}
                  type={!isNewPasswordHidden ? 'password' : 'text'}
                  InputProps={{
                    endAdornment: <img
                      style={{ marginTop: '20px', cursor: 'pointer' }}
                      alt={'eye'}
                      width="20"
                      src={isNewPasswordHidden ? eye : closedEye}
                      onClick={() => loginViewStore.setIsNewPasswordHidden(!isNewPasswordHidden)}
                    />,
                  }}
                  fullWidth
                  required
                  placeholder={t('inputPasswordPlaceholder')}
                  errormessage={formik.errors.password}
                />
                <StyledTextField
                  className={connectionClasses.input}
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t('inputConfirmPassword')}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.confirmPassword) && formik.touched.confirmPassword}
                  type={!isNewConfirmPasswordHidden ? 'password' : 'text'}
                  InputProps={{
                    endAdornment: <img
                      style={{ marginTop: '20px', cursor: 'pointer' }}
                      alt={'eye'}
                      width="20"
                      src={isNewConfirmPasswordHidden ? eye : closedEye}
                      onClick={() => loginViewStore.setIsNewConfirmPasswordHidden(!isNewConfirmPasswordHidden)}
                    />,
                  }}
                  fullWidth
                  required
                  placeholder={t('inputConfirmPasswordPlaceholder')}
                  errormessage={formik.errors.confirmPassword}
                />
                <div className={resetPasswordClasses.passwordInformations}>
                  {t('passwordInformations')}
                  <div className={passwordComplexityReport.hasTwelveChar ? 'passwordOk' : ''}>- 12 caractères</div>
                  <div className={passwordComplexityReport.hasAUpperCase ? 'passwordOk' : ''}>- 1 majuscule</div>
                  <div className={passwordComplexityReport.hasALowerCase ? 'passwordOk' : ''}>- 1 minuscule</div>
                  <div className={passwordComplexityReport.hasADigit ? 'passwordOk' : ''}>- 1 chiffre</div>
                  <div className={passwordComplexityReport.hasASpecial ? 'passwordOk' : ''}>- 1 caractère spécial ex : #§(@/$*%...</div>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color={activeSubmit(formik.values.password, formik.values.confirmPassword) ? 'primary' : 'secondary'}
                  isFetching={formik.isSubmitting}
                  disabled={!activeSubmit(formik.values.password, formik.values.confirmPassword)}
                  type="submit"
                >
                  {isNewUser ? t('newPassword') : t('resetPassword')}
                </Button>
              </form>
            </>
          }
        </div>

      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={showErrorSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowErrorSnackbar(false)}
      >
        <SnackbarContent className={resetPasswordClasses.snackbar} message={
          <div className={resetPasswordClasses.snackbarMessage}>
            {t('errorSnackBar.resetPassword')}
          </div>
        }/>
      </Snackbar>
    </div>
  );
});

export default ResetPasswordComponent;
