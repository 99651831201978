import { callable } from './httpWrapper.service';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { InvoiceModel } from '../assets/models/invoices/invoice.model';
import {
  GetInvoiceBeneficiariesByDateRequest
} from '../assets/requests/invoices/GetInvoiceBeneficiariesByDate.request';

export async function getAgencyInvoices (agencyId: string, requestBody: OptionalTimePeriodRequest): Promise<InvoiceModel[]> {
  return callable(
    'getAgencyInvoices',
    { agencyId },
    requestBody,
  );
}

export async function getInvoiceBeneficiariesByDate (agencyId: string, requestBody: GetInvoiceBeneficiariesByDateRequest): Promise<string[]> {
  return callable(
    'getInvoiceBeneficiariesByDate',
    { agencyId },
    requestBody,
  );
}
