const agency = {
  subtitle: 'Informations concernant :',
  tab1: 'Fiche d\'identité de l\'entreprise',
  tab2: 'Contrat',
  tab3: 'Paiement et restitution',
  paymentTitle: 'Informations de paiement',
  restitutionTitle: 'Restitution du solde non consommé de vos collaborateurs',
  ibanSubscription: 'IBAN du compte pour le paiement des commandes/abonnements',
  ibanSubscriptionBankTransfer: 'IBAN du compte Open! à utiliser pour les virements liés aux commandes de rechargement/abonnements',
  ibanSubscriptionDissociatedMain: 'IBAN à utiliser pour le paiement des provisions',
  ibanSubscriptionDissociatedFees: 'IBAN à utiliser pour le paiement des abonnements',
  ibanWorkingCapital: 'IBAN du compte pour le fonds de roulement',
  orderTitle: 'Informations du contrat',
  beneficiariesCount: 'Nombre de collaborateurs',
  priceAmountPerBenificiary: 'Tarif de l’abonnement',
  priceAmountPerBenificiaryValue: '{{count}} € / collaborateur',
  ONE_TIME_A_MONTH: 'une fois par mois',
  ONE_TIME_A_WEEK: 'une fois par semaine',
  administrativeDataTitle: 'DONNÉES ADMINISTRATIVES',
  ref: 'N° client ',
  agencyName: 'Raison sociale',
  address: 'Adresse',
  additionalAddress1: 'Complément d\'adresse',
  additionalAddress2: 'Complément d\'adresse 2',
  zipcode: 'Code postal',
  city: 'Ville',
  registrationNumber: 'Numéro de Siret',
  registrationNumber2: 'Numéro de Siren',
  billingAddress: 'ADRESSE DE FACTURATION',
  agencyContactTitle: 'Représentant légal',
  lastName: 'Nom',
  firstName: 'Prénom',
  email: 'Adresse email',
  mobilephone: 'Téléphone',
  billingContact: 'CONTACT FACTURATION',
  notSpecified: 'Non renseigné',
  civility: 'Civilité',
  country: 'Pays de résidence',
  streetAddress: 'Adresse de résidence',
  additionalAddress: 'Complément d\'adresse',
  postalCode: 'Code postal',
  Mr: 'M.',
  Mrs: 'Mme',
  Unknow: '',
  isManagedPaymentMode: 'Mode de fonctionnement',
  allInclusive: 'Délégué',
  autonomous: 'Autonome',
  seeMandate: 'Voir le mandat de prélèvement',
  startDate: 'Date de début du contrat',
  dailyAmount: 'Montant quotidien',
  companyQuota: 'Participation employeur',
  contractRepresentative: 'Signataire du contrat',
  holderFullName: 'Nom du titulaire',
  restitutionIban: 'IBAN à utiliser pour le remboursement',
  restitutionIbanPlaceholder: 'FR9510096000707173325389S73',
  PROVISIONS_WALLET_DEPOSIT: 'Restitution sur le portefeuille Openeat de l’entreprise',
  PROVISIONS_WALLET_DEPOSIT_HELPER_TEXT: 'Les soldes non consommés seront reversés dans le portefeuille utilisé pour le remboursement des collaborateurs. ',
  DIRECT_DEBIT_IBAN_PAYOUT: 'Remboursement sur le compte utilisé pour le prélèvement des commandes ',
  DIRECT_DEBIT_IBAN_PAYOUT_HELPER_TEXT: 'Les soldes non consommés seront remboursés sur le compte utilisé pour le prélèvement des commandes. ',
  RESTITUTION_IBAN_PAYOUT: 'Remboursement par virement',
  RESTITUTION_IBAN_PAYOUT_HELPER_TEXT: 'Les soldes non consommés seront remboursés sur le compte de votre choix. ',
  cancel: 'Annuler',
  updateRestitution: 'Valider',
};

export default agency;
