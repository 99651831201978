import React from 'react';
import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';

const SpinnerAdornment = () => {
  return (
    <CircularProgress
      color='secondary'
      style={{ marginLeft: '5px', color: 'white' }}
      size={20}
    />
  );
};

  interface ButtonComponentProps extends ButtonProps {
    isFetching?: boolean;
  }

const Button = (props:ButtonComponentProps) => {
  const {
    children,
    isFetching,
    ...rest
  } = props;
  return (
    <MuiButton {...rest}>
      {children}
      {isFetching && <SpinnerAdornment {...rest} />}
    </MuiButton>
  );
};

export default Button;
