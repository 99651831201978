import { makeStyles, styled } from '@material-ui/core';
import { DataGrid, DataGridProps, GridPagination } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { COLORS, FONT } from '../Style';

const useStyles = makeStyles({
  datagrid: {
    width: '100%',
    height: (props: any) =>`${170 +  72 * (props.numberOfRowToDisplay || 1) }px`,
  },
});

interface CustomDataGridProps extends DataGridProps {
  idKey?: string;
}

function countRowToDisplay(totalRows: number, currentPageIndex: number, rowsPerPage: number): number {
  const rest = totalRows - (rowsPerPage * currentPageIndex);
  return Math.min(rest, rowsPerPage);
}

const StyledDataGrid = styled(
  (props: CustomDataGridProps) => {
    const rows = props.rows || [];
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageSize, setCurrentPageSize] = useState(25);
    const [numberOfRowToDisplay, setNumberOfRowToDisplay] = useState(currentPageSize);
    const dataGridClasses = useStyles({ numberOfRowToDisplay });

    useEffect(() => {
      const numberOfRowToDisplay = countRowToDisplay(rows.length, currentPage, currentPageSize);
      setNumberOfRowToDisplay(numberOfRowToDisplay);
    }, [currentPage, currentPageSize, rows.length]);

    return (
      <div className={dataGridClasses.datagrid}>
        <DataGrid
          getRowId={(row) => props.idKey ? row[props.idKey] : row.uid}
          localeText={{
            noRowsLabel: 'Pas de résultats',
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                 `${count > 1 ? `Lignes`: `Ligne`} ${from} à ${to}  sur ${count.toLocaleString()}`,
            },
          }}
          components={{
            Header: () => <GridPagination />,
            Footer: () => <GridPagination />,
          }}
          pagination
          pageSize={currentPageSize}
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={setCurrentPageSize}
          onPageChange={setCurrentPage}
          {...props}
        />
      </div>
    )
      ;
  },
)({
  border: 'none  !important',
  '& .MuiDataGrid-cell': {
    display: 'flex',
    borderBottom: '1px solid white !important',
    color: COLORS.textPrimary,
  },
  '& .MuiDataGrid-row': {
    backgroundColor: '#F7F7F8',
    borderRadius: '20px',
    marginBottom: '20px',
    width: '100%',
    cursor: ({ onRowClick }: DataGridProps) => onRowClick ? 'pointer' : 'initial',
  },
  '& .MuiDataGrid-colCellTitle': {
    color: COLORS.textPrimary,
    fontFamily: FONT.primary,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-colCell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none !important',
  },
  '& .MuiDataGrid-columnsContainer': {
    border: 'none !important',
  },
  '& .MuiSvgIcon-root': {
    color: COLORS.primary,
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: '100% !important',
  },
  '& .MuiIconButton-root.Mui-disabled': {
    visibility: 'hidden',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '36px',
  },
  '& .MuiTablePagination-spacer' : {
    display: 'none !important',
  }
});

export default StyledDataGrid;
