import { makeAutoObservable, runInAction } from 'mobx';
import { createAgencyOrder } from '../Services/ordersAPI.service';
import { CreateAgencyOrderRequest } from '../assets/requests/orders/CreateAgencyOrder.request';
import { OrderBankWireReference } from '../assets/models/orders/OrderBankWireReference.model';
import { ConfirmAgencyOrderResponse } from '../assets/responses/orders/ConfirmAgencyOrder.response';


export class PayingServiceStore {
  bankWireReference: OrderBankWireReference = null;

  constructor () {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  reset() {
    this.bankWireReference = null;
  }

  async createOrder (orderToCreate: CreateAgencyOrderRequest) {
    const createOrderResponse: ConfirmAgencyOrderResponse = await createAgencyOrder(orderToCreate);

    if (!createOrderResponse) {
      return;
    }

    runInAction(() => {
      this.bankWireReference = createOrderResponse.bankWireReference;
    });
  }
}
