
export function setCookie(cookieName: string, value: string = '', delay: number = 0, secure: boolean = true): void {
  const expires: Date | null = delay ? new Date(new Date().getTime() + delay) : null;
  const cookie = `${cookieName}=${value};` +
    `path=/;` +
    `${expires ? `expires=${expires.toUTCString()};` : ''}` +
    `${secure ? 'secure': ''}`;

  document.cookie = cookie;
}

export function unsetCookie(cookieName: string): void {
  void setCookie(cookieName, '', -42);
}

export function getCookieValue(cookieName: string): string {
  return document.cookie
    .split(/; ?/)
    .find((row: string) => row.startsWith(cookieName))
    ?.split('=')[1];
}