import { CircularProgress, Dialog, MenuItem, Select } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstances } from 'react-ioc';
import Downarrow from '../../assets/down-arrow';
import magnifyingGlass from '../../assets/magnifying-glass.svg';
import { InvoiceModel } from '../../assets/models/invoices/invoice.model';
import {
  getExcludingTaxesAmount,
  getIncludingTaxesAmount,
} from '../../assets/utils/money/invoice.util';
import InputComponent from '../../Component/InputComponent';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { AccountingViewStore } from '../../Stores/viewStore/AccountingView.store';
import useDialogStyles from '../../Style/MuiStyles/Dialog';
import { StyledSelect } from '../../Style/MuiStyles/Select/Select';
import { PageBlock, PageSubtitle } from '../../Style/Style';
import InvoiceDetailsComponent from '../InvoiceDetails/InvoiceDetailsComponent';
import { useInvoicesStyles } from './InvoicesStyles';
import InvoiceCard from '../../Component/InvoiceCard/InvoiceCard';

type LocalStore = [AgenciesStore, AccountingViewStore, AgencyInvoiceStore];

const InvoicesComponent: FunctionComponent = provider(
  AccountingViewStore,
)(observer(() => {
  const [
    agenciesStore,
    invoicesViewStore,
    agencyInvoiceStore,
  ]: [AgenciesStore, AccountingViewStore, AgencyInvoiceStore] = useInstances<LocalStore>(AgenciesStore, AccountingViewStore, AgencyInvoiceStore);
  const search: string = invoicesViewStore.search;
  const invoicesClasses = useInvoicesStyles();
  const dialogClasses = useDialogStyles();
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(true);
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceModel[]>();
  const [currentInvoiceKey, setCurrentInvoiceKey] = React.useState(0);
  const dateOptions = { year: 'numeric', month: 'long' } as const;
  const { t } = useTranslation('accounting');

  const applySearch = (newSearch: string, newInvoices: InvoiceModel[]) => {
    invoicesViewStore.setSearch(newSearch);
    const filterInvoices = newInvoices.filter((i) => {
      const invoiceDate: Date = new Date(i.invoiceDate);
      const statusDate: Date = new Date(i.statusDate);
      const excludingTaxesAmount: number = getExcludingTaxesAmount(i);
      const includingTaxesAmount: number = getIncludingTaxesAmount(i);

      return (
        (t('toPay', { mode: i.paymentMethod === 'DIRECT_DEBIT' ? 'prélèvement' : 'virement' }).toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && i.statusDate) ||
        (`${i.paymentMethod === 'DIRECT_DEBIT' ? t('paidDirectDebit') : t('paidBankTransfer')}`.toLocaleLowerCase().search(newSearch.toLocaleLowerCase()) !== -1 && !i.statusDate) ||
        (i.statusDate && statusDate.toLocaleDateString().search(newSearch) !== -1) ||
        i.invoiceReference.toString().search(newSearch) !== -1 ||
        invoiceDate.toLocaleDateString('fr-FR', dateOptions).toLocaleLowerCase().search(newSearch) !== -1 ||
        includingTaxesAmount.toString().search(newSearch) !== -1 ||
        excludingTaxesAmount.toString().search(newSearch) !== -1
      );
    });
    setFilteredInvoices(filterInvoices);
  };

  const getInvoices = (from: Date) => {
    setIsFetchingInvoices(true);
    agencyInvoiceStore.fetchAgencyInvoices(
      agenciesStore.currentAgency.uid, from?.getTime())
      .then(() => {
        agencyInvoiceStore.sortInvoicesByCreationDate(agencyInvoiceStore.invoices);
        setFilteredInvoices(agencyInvoiceStore.invoices);
        applySearch(search, agencyInvoiceStore.invoices);
      })
      .catch(e => console.error(e))
      .finally(() => setIsFetchingInvoices(false));
  };

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      const from = new Date();
      from.setFullYear(from.getFullYear() - 1);
      getInvoices(from);
    }

  }, [agenciesStore.currentAgency]);


  const handleClickOpen = (key: number) => {
    setCurrentInvoiceKey(key);
    invoicesViewStore.setOpenDialog(true);
  };

  const handleClose = () => {
    invoicesViewStore.setOpenDialog(false);
  };

  return (
    <PageBlock>
      <h1>
        {t('pageTitle')}
      </h1>
      <PageSubtitle>
        {t('pageSubtitle')}
      </PageSubtitle>
      {isFetchingInvoices
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          <div className={invoicesClasses.block}>
            <Select
              value={invoicesViewStore.sinceDate}
              onChange={(evt) => {
                let from = new Date();
                switch (evt.target.value) {
                  case '0':
                    from.setMonth(from.getMonth() - 6);
                    break;
                  case '1':
                    from.setFullYear(from.getFullYear() - 1);
                    break;
                  case '2':
                    from.setFullYear(from.getFullYear() - 2);
                    break;
                  default:
                    from = null;
                }

                getInvoices(from);
                invoicesViewStore.setSinceDate(evt.target.value as string);
              }}
              labelId="label"
              id="select"
              input={<StyledSelect/>}
              IconComponent={(props) => Downarrow({ svgColor: '#FFFFFF', ...props, className: invoicesClasses.icon })}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="0">{t('since6months')}</MenuItem>
              <MenuItem value="1">{t('since1year')}</MenuItem>
              <MenuItem value="2">{t('since2years')}</MenuItem>
              <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
            </Select>
            <div className={invoicesClasses.search}>
              <InputComponent
                placeholder={t('invoiceSearchPlaceholder')}
                icon={magnifyingGlass}
                onChange={(search) =>  applySearch(search, agencyInvoiceStore.invoices)}
                value={search}
                iconLeft
              />
            </div>
          </div>
          {filteredInvoices &&
            <>
              <div className={invoicesClasses.invoices}>
                {filteredInvoices?.map((invoice, i) =>
                  <InvoiceCard
                    key={i}
                    invoiceKey={i}
                    invoice={invoice}
                    openInvoice={handleClickOpen}/>,
                )
                }
              </div>
              <Dialog className={dialogClasses.dialogPaper} fullWidth maxWidth="md"
                      open={invoicesViewStore.openDialog}
                      onClose={handleClose}>
                <InvoiceDetailsComponent invoice={filteredInvoices[currentInvoiceKey]} onClose={handleClose}/>
              </Dialog>
            </>
          }
        </>
      }
    </PageBlock>
  );
}));

export default InvoicesComponent;
