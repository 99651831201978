import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { CreateBeneficiaryRequest } from '@assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '@assets/requests/beneficiaries/UpdateBeneficiary.request';
import { UpdateBeneficiaryActivityRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryActivityRequest';
import { UpdateBeneficiaryRefRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryRef.request';
import { BeneficiariesCountResponse } from '@assets/responses/beneficiaries/BeneficiariesCount.response';
import { BeneficiariesUploadResponse } from '@assets/responses/beneficiaries/BeneficiariesUpload.response';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import {
  SendMailsToUnboardedBeneficiariesResponse,
} from '@assets/responses/emails/SendMailsToUnboardedBeneficiaries.response';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { UploadBeneficiaryRequest } from '../assets/requests/beneficiaries/UploadBeneficiary.request';
import {
  SendMailsToUnboardedBeneficiariesRequest,
} from '../assets/requests/emails/SendMailsToUnboardedBeneficiaries.request';
import { isBeneficiaryActive, isBeneficiaryActiveToday } from '../assets/utils/functions/isBeneficiaryActive.util';
import {
  createOneBeneficiary,
  getAllBeneficiariesByAgencyId,
  getBeneficiariesCountByAgencyId,
  getBeneficiaryById,
  sendMailsToUnOnboardedBeneficiaries,
  updateBeneficiaryActivities,
  updateBeneficiaryRegistrationNumber,
  updateOneBeneficiary,
  updateBeneficiaryEmailForAgency,
  uploadBeneficiaries, updateBeneficiaryIbanForAgency, manageBeneficiaryDeactivationSchedule,
} from '../Services/beneficiariesAPI.service';
import { sortBeneficiaryList } from '../Utils/sortBeneficiaryList';
import { UpdateBeneficiaryIBANRequest } from '@assets/requests/billings/UpdateBeneficiaryIBAN.request';
import { UpdateBeneficiaryEmailRequest } from '@assets/requests/beneficiaries/UpdateBeneficiaryEmail.request';
import { sanitizeEmail } from '../assets/utils/functions/sanitizeEmail.util';
import {
  ManageBeneficiaryDeactivationScheduleRequest
} from '@assets/requests/beneficiaries/ManageBeneficiaryDeactivationSchedule.request';

export interface UpdateBeneficiaryFullRequest {
  agencyId: string;
  beneficiaryId: string;
  beneficiaryMin?: UpdateBeneficiaryRequest;
  beneficiaryActivity?: UpdateBeneficiaryActivityRequest;
  registrationNumber?: UpdateBeneficiaryRefRequest;
  beneficiaryEmail?: UpdateBeneficiaryEmailRequest;
  beneficiaryIban?: UpdateBeneficiaryIBANRequest;
}

export class BeneficiariesStore {
  error = null;
  isLoading = false;

  createBeneficiaryStatus = 'PENDING';
  updateBeneficiaryStatus = 'PENDING';
  updateBeneficiaryActivityStatus = 'PENDING';
  updateBeneficiaryRefStatus = 'PENDING';
  uploadBeneficiariesStatus = 'PENDING';
  sendMailsToUnOnboardedBeneficiariesStatus = 'PENDING';

  beneficiariesList: BeneficiaryModel[] = [];
  beneficiariesFilteredList: BeneficiaryModel[] = [];
  currentBeneficiary: BeneficiaryModel = null;

  beneficiariesToRecharge: BeneficiaryModel[] = [];

  counters: BeneficiariesCountResponse = null;
  totalDaysToRecharge = 0;

  get totalBeneficiariesCount() {
    return this.counters?.totalBeneficiariesCount || 0;
  }

  get activeBeneficiariesCount() {
    return this.counters?.activeBeneficiariesCount || 0;
  }

  get inactiveBeneficiariesCount() {
    return this.totalBeneficiariesCount - this.activeBeneficiariesCount;
  }

  get activeBeneficiaryList(): BeneficiaryModel[] {
    return this.beneficiariesList.filter((beneficiary: BeneficiaryModel) => isBeneficiaryActiveToday(beneficiary));
  }

  get inactiveBeneficiaryList(): BeneficiaryModel[] {
    return this.beneficiariesList.filter((beneficiary: BeneficiaryModel) => !isBeneficiaryActiveToday(beneficiary));
  }

  get sortedBeneficiaryList(): BeneficiaryModel[] {
    return sortBeneficiaryList(this.beneficiariesList);
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setBeneficiariesToRecharge(value: BeneficiaryModel[]) {
    this.beneficiariesToRecharge = value;
  }

  @action setBeneficiariesList(value: BeneficiaryModel[]) {
    this.beneficiariesList = value;
  }

  @action setTotalDaysToRecharge(value: number) {
    this.totalDaysToRecharge = value;
  }

  filterBeneficiariesByString(showInactiveBeneficiaries: boolean, inputValueSearch: string) {
    this.beneficiariesFilteredList = this.sortedBeneficiaryList
      .filter((beneficiary: BeneficiaryModel) => {
        return showInactiveBeneficiaries || (!showInactiveBeneficiaries && isBeneficiaryActiveToday(beneficiary));
      })
      .filter(({ lastName, firstName, email, registrationNumber }: BeneficiaryModel) => {
        return [lastName, firstName, email, registrationNumber].some((value: string) => {
          return value?.toLocaleLowerCase?.().includes(inputValueSearch);
        });
      });
  }

  @action setBeneficiariesLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setBeneficiariesCount(data: BeneficiariesCountResponse) {
    this.counters = data;
  }

  async fetchAllBeneficiariesByAgencyId(agencyId: string): Promise<BeneficiaryModel[]> {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiariesResponse: BeneficiaryModel[] = await getAllBeneficiariesByAgencyId(agencyId);
      runInAction(() => {
        if (beneficiariesResponse) {
          this.beneficiariesList = beneficiariesResponse;
        }
      });
      return beneficiariesResponse;
    } catch (e) {
      this.error = 'error on load beneficiaries';
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async fetchBeneficiaryById(beneficiaryId: string) {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiaryResponse: BeneficiaryModel = await getBeneficiaryById(beneficiaryId);

      runInAction(() => {
        if (beneficiaryResponse) {
          this.currentBeneficiary = beneficiaryResponse;
        }
      });
    } catch (e) {
      this.error = 'error on load beneficiary';
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async fetchBeneficiariesCountByAgencyId(agencyId: string): Promise<BeneficiariesCountResponse> {
    this.setBeneficiariesLoading(true);

    try {
      const beneficiariesCountResponse: BeneficiariesCountResponse = await getBeneficiariesCountByAgencyId(agencyId);
      this.counters = beneficiariesCountResponse;
      return beneficiariesCountResponse;
    } catch (e) {
      this.error = 'error on load beneficiaries count';
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async createBeneficiary(agencyId: string, beneficiaryToCreate: CreateBeneficiaryRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    try {
      const createBeneficiaryResponse: BeneficiaryModel = await createOneBeneficiary(agencyId, beneficiaryToCreate);

      runInAction(() => {
        if (createBeneficiaryResponse) {
          this.currentBeneficiary = createBeneficiaryResponse;
          this.createBeneficiaryStatus = 'SUCCESS';
        }
      });

    } catch (createBeneficiaryError) {
      this.error = createBeneficiaryError.response?.data
        ? createBeneficiaryError.response?.data.messageToDisplay
        : 'error on create beneficiary';

      this.createBeneficiaryStatus = 'ERROR';
    }

    this.setBeneficiariesLoading(false);
  }

  async updateBeneficiary(agencyId: string, beneficiaryId: string, beneficiaryToUpdate: UpdateBeneficiaryRequest): Promise<void> {
    this.setBeneficiariesLoading(true);
    const standardUpdateRequest: UpdateBeneficiaryRequest = {
      title: beneficiaryToUpdate.title,
      firstName: beneficiaryToUpdate.firstName,
      lastName: beneficiaryToUpdate.lastName,
      streetAddress: beneficiaryToUpdate.streetAddress,
      additionalAddress: beneficiaryToUpdate.additionalAddress,
      postalCode: beneficiaryToUpdate.postalCode,
      city: beneficiaryToUpdate.city,
      countryCode: beneficiaryToUpdate.countryCode,
    };

    try {
      const confirmationResponse: ConfirmationResponse = await updateOneBeneficiary(agencyId, beneficiaryId, standardUpdateRequest);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryStatus = 'SUCCESS';
        }
      });

    } catch (updateBeneficiaryError) {
      this.error = updateBeneficiaryError.response?.data
        ? updateBeneficiaryError.response?.data.messageToDisplay
        : 'error on update beneficiary';

      this.updateBeneficiaryStatus = 'ERROR';
    }

    this.setBeneficiariesLoading(false);
  }

  async updateBeneficiaryActivity(beneficiaryId: string, request: UpdateBeneficiaryActivityRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    const standardUpdateRequest: UpdateBeneficiaryActivityRequest = {
      activeSundaysAndHolidays: request.activeSundaysAndHolidays,
      numberOfWorkingDays: request.numberOfWorkingDays,
      firstRightDate: request.firstRightDate,
    };

    try {
      const confirmationResponse: ConfirmationResponse = await updateBeneficiaryActivities(beneficiaryId, standardUpdateRequest);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryActivityStatus = 'SUCCESS';
        }
      });

    } catch (updateBeneficiaryActivityError) {
      this.error = updateBeneficiaryActivityError.response?.data
        ? updateBeneficiaryActivityError.response?.data.messageToDisplay
        : 'error on update beneficiary activity';

      this.updateBeneficiaryActivityStatus = 'ERROR';
    }
    this.setBeneficiariesLoading(false);
  }

  async updateBeneficiaryRef(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryRefRequest): Promise<void> {
    this.setBeneficiariesLoading(true);

    const standardUpdateRequest: UpdateBeneficiaryRefRequest = {
      registrationNumber: request.registrationNumber,
    };

    try {
      const confirmationResponse: ConfirmationResponse = await updateBeneficiaryRegistrationNumber(agencyId, beneficiaryId, standardUpdateRequest);

      runInAction(() => {
        if (confirmationResponse.info === 'ok') {
          this.updateBeneficiaryRefStatus = 'SUCCESS';
        }
      });

    } catch (updateBeneficiaryRefError) {
      this.error = updateBeneficiaryRefError.response?.data
        ? updateBeneficiaryRefError.response?.data.messageToDisplay
        : 'error on update beneficiary ref';

      this.updateBeneficiaryRefStatus = 'ERROR';
    }
    this.setBeneficiariesLoading(false);
  }

  async uploadBeneficiaries(agencyId: string, beneficiariesToUpload: UploadBeneficiaryRequest[]): Promise<BeneficiariesUploadResponse> {
    this.setBeneficiariesLoading(true);

    try {
      const uploadBeneficiariesResponse: BeneficiariesUploadResponse = await uploadBeneficiaries(agencyId, beneficiariesToUpload);
      const {
        beneficiariesUpdated,
        beneficiariesCreated,
      }: BeneficiariesUploadResponse = uploadBeneficiariesResponse;

      const updatedBeneficiariesList: BeneficiaryModel[] = this.beneficiariesList
        .map((beneficiary: BeneficiaryModel) => {
          const updatedBeneficiary: BeneficiaryModel = beneficiariesUpdated.find((updatedBeneficiary: BeneficiaryModel) => {
            return updatedBeneficiary.uid === beneficiary.uid;
          });
          return updatedBeneficiary || beneficiary;
        })
        .concat(beneficiariesCreated)
        .concat(beneficiariesUpdated.filter((updatedBeneficiary: BeneficiaryModel) => {
          return !this.beneficiariesList.find((beneficiary: BeneficiaryModel) => {
            return updatedBeneficiary.uid === beneficiary.uid;
          });
        }));

      runInAction(() => {
        this.beneficiariesList = updatedBeneficiariesList;
      });

      this.uploadBeneficiariesStatus = 'SUCCESS';
      return uploadBeneficiariesResponse;
    } catch (e) {
      this.error = 'error on upload beneficiaries';
      this.uploadBeneficiariesStatus = 'ERROR';
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryEmailForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryEmailRequest): Promise<void> {
    this.setBeneficiariesLoading(true);
    const updateEmailRequest: UpdateBeneficiaryEmailRequest = {
      email: sanitizeEmail(request.email),
    };
    try {
      await updateBeneficiaryEmailForAgency(agencyId, beneficiaryId, updateEmailRequest);
    } catch (e) {
      this.error = e.response?.data
        ? e.response?.data.messageToDisplay
        : 'error on update beneficiary email';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async updateBeneficiaryIbanForAgency(agencyId: string, beneficiaryId: string, request: UpdateBeneficiaryIBANRequest) {
    this.setBeneficiariesLoading(true);
    try {
      await updateBeneficiaryIbanForAgency(agencyId, beneficiaryId, request);
    } catch (e) {
      this.error = e.response?.data
        ? e.response?.data.messageToDisplay
        : 'error on update beneficiary iban';
      throw e;
    } finally {
      this.setBeneficiariesLoading(false);
    }
  }

  async sendBatchedMailsToUnOnboardedBeneficiaries(request: SendMailsToUnboardedBeneficiariesRequest): Promise<SendMailsToUnboardedBeneficiariesResponse> {
    this.setBeneficiariesLoading(true);

    try {
      const { mailsFailed, mailsSent } = await sendMailsToUnOnboardedBeneficiaries(request);

      runInAction(() => {
        if (!mailsFailed.length) {
          this.sendMailsToUnOnboardedBeneficiariesStatus = 'SUCCESS';
        } else {
          this.sendMailsToUnOnboardedBeneficiariesStatus = 'ERROR';
        }
      });

      return { mailsFailed, mailsSent };
    } catch (sendMailsToUnOnboardedBeneficiariesError) {
      this.error = sendMailsToUnOnboardedBeneficiariesError.response?.data
        ? sendMailsToUnOnboardedBeneficiariesError.response?.data.messageToDisplay
        : 'error on mails send';

      this.sendMailsToUnOnboardedBeneficiariesStatus = 'ERROR';
    }

    this.setBeneficiariesLoading(false);
  }

  async updateBeneficiaryAll(
    updateBeneficiaryAllRequest: UpdateBeneficiaryFullRequest,
  ): Promise<{ info: 'ok' } | { info: 'faild to update beneficiary All informations' }> {
    const {
      agencyId,
      beneficiaryId,
      beneficiaryMin,
      beneficiaryActivity,
      registrationNumber,
      beneficiaryEmail,
      beneficiaryIban,
    }: UpdateBeneficiaryFullRequest = updateBeneficiaryAllRequest;
    const updateBeneficiaryPromises: Promise<any>[] = [];

    if (beneficiaryMin) {
      updateBeneficiaryPromises.push(this.updateBeneficiary(
        agencyId, beneficiaryId, beneficiaryMin,
      ));
    }
    if (beneficiaryActivity) {
      updateBeneficiaryPromises.push(this.updateBeneficiaryActivity(
        beneficiaryId, beneficiaryActivity,
      ));
    }
    if (registrationNumber) {
      updateBeneficiaryPromises.push(this.updateBeneficiaryRef(
        agencyId, beneficiaryId, registrationNumber,
      ));
    }
    if (beneficiaryEmail) {
      updateBeneficiaryPromises.push(this.updateBeneficiaryEmailForAgency(
        agencyId, beneficiaryId, beneficiaryEmail,
      ));
    }

    if (beneficiaryIban) {
      updateBeneficiaryPromises.push(this.updateBeneficiaryIbanForAgency(
        agencyId, beneficiaryId, beneficiaryIban,
      ));
    }

    try {
      await Promise.all(updateBeneficiaryPromises);
      return {
        info: 'ok',
      };
    } catch (e) {
      return {
        info: 'faild to update beneficiary All informations',
      };
    }
  }

  getActiveBeneficiariesAtDate(date: Date) {
    const month: number = date.getMonth() + 1;
    const year: number = date.getFullYear();

    return this.sortedBeneficiaryList
      .filter((beneficiary: BeneficiaryModel) => isBeneficiaryActive(beneficiary, year, month));
  }

  async manageBeneficiaryDeactivationSchedule(beneficiaryId: string, request: ManageBeneficiaryDeactivationScheduleRequest): Promise<void> {
    try {
    await manageBeneficiaryDeactivationSchedule(beneficiaryId, request);
    } catch (e) {
      this.error = 'error on deactivate beneficiary';
    }
  }

}
