import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { FunctionComponent } from 'react';
import { BeneficiariesUploadResponse } from '../../../assets/responses/beneficiaries/BeneficiariesUpload.response';
import { useUploadManagerStyles } from './AddBeneficiariesUpload.styles';
import AddBeneficiariesUploadSuccess from './AddBeneficiariesUploadSuccess';

interface AddBeneficiariesUploadManagerProps {
  beneficiariesUploadedResponse: BeneficiariesUploadResponse;
}

export const AddBeneficiariesUploadManager: FunctionComponent<AddBeneficiariesUploadManagerProps> = (props) => {
  const uploadManagerStyles: ClassNameMap<string> = useUploadManagerStyles();
  return (
    <div className={uploadManagerStyles.block}>
      <AddBeneficiariesUploadSuccess beneficiariesUploadedResponse={props.beneficiariesUploadedResponse}/>
    </div>
  );
};