import { CircularProgress } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';
import { BeneficiariesStore } from '../../Stores/Beneficiaries.store';
import { OrderPaymentViewStore } from '../../Stores/viewStore/OrderPaymentView.store';
import { OrderValidationViewStore } from '../../Stores/viewStore/OrderValidationView.store';
import { RechargingViewStore } from '../../Stores/viewStore/RechargingView.store';
import { StepperRechargingStore } from '../../Stores/viewStore/StepperRecharging.store';
import Button from '../../Style/MuiStyles/Button';
import { useOrderValidationCompStyles } from './Styles';
import StyledTextField from '../../Style/MuiStyles/TextField';
import { InvoiceCustomMetadataModel } from '@assets/models/invoices/InvoiceCustomMetadata.model';

type LocalStore = [
  RechargingViewStore,
  OrderValidationViewStore,
  OrderPaymentViewStore,
  StepperRechargingStore,
  AgenciesStore,
  BeneficiariesStore,
  AgencyInvoiceStore,
];

/**
 * Tab: Rechargement des droits
 * Step: Validation (CONFIRMATION)
 */
const OrderValidationComponent: FunctionComponent = observer(() => {
  const [
    rechargingViewStore,
    orderValidationViewStore,
    orderPaymentViewStore,
    stepperRechargingStore,
    agenciesStore,
    beneficiariesStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(
    RechargingViewStore,
    OrderValidationViewStore,
    OrderPaymentViewStore,
    StepperRechargingStore,
    AgenciesStore,
    BeneficiariesStore,
    AgencyInvoiceStore,
  );

  const { t } = useTranslation('reloading');

  const newActiveBeneficiaryCount = orderValidationViewStore.newActiveBeneficiaryCount;
  const beneficiariesToRecharge = beneficiariesStore.beneficiariesToRecharge;
  const orderValidationClasses = useOrderValidationCompStyles();
  const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const [isDissociatedPayment, setIsDissociatedPayment] = useState<boolean>(false);

  const orderSubscriptionVatRate: number = agenciesStore.currentAgency.vat.rate;
  const subExclTax: number = Number(agenciesStore.currentAgency?.contractSubscriptionFee) * newActiveBeneficiaryCount;
  const totalTVA: number = subExclTax * (orderSubscriptionVatRate / 100);
  const subInclTax: number = subExclTax + totalTVA;
  const provisionTotalDays: number = beneficiariesToRecharge
    .reduce((sum: number, current: BeneficiaryModel) => sum + (current.numberOfWorkingDays ? current.numberOfWorkingDays : 0), 0);

  const provisionAmount: number = Number(agenciesStore.currentAgency?.contractAgencyPart) * provisionTotalDays;
  const netAmountPayable: number = provisionAmount + subInclTax;

  const { hasInvoiceInformation }: AgenciesStore = agenciesStore;
  const {
    customerReferenceValue, internalOrderNumberValue, marketNumberValue, commitmentNumberValue,
    feesCustomerReferenceValue,
  }: InvoiceCustomMetadataModel = orderPaymentViewStore.invoiceCustomMetadata;

  const month: number = rechargingViewStore.selectedReloadingDate.getMonth() + 1;
  const year: number = rechargingViewStore.selectedReloadingDate.getFullYear();
  const {
    dateString, hasAtLeastMetadata, countSetMetadata,
  }: OrderPaymentViewStore = orderPaymentViewStore;

  useEffect(() => {
    orderPaymentViewStore.setOrderDetails({
      ...orderPaymentViewStore.orderDetails,
      beneficiaryToRechargeCount: newActiveBeneficiaryCount,
      beneficiariesToRecharge,
      month,
      year,
      totalHT: subExclTax,
      tva: totalTVA,
      totalTTC: subInclTax,
      contractSubscriptionFee: agenciesStore.currentAgency?.contractSubscriptionFee,
      contractAgencyPart: agenciesStore.currentAgency?.contractAgencyPart,
      provisionTotalDays,
      provisionAmount,
      netAmountPayable,
    });
  }, [subExclTax, subInclTax, newActiveBeneficiaryCount, totalTVA, provisionTotalDays]);

  useEffect(() => {
    (async () => {
      await agencyInvoiceStore.fetchAgencyInvoiceBeneficiariesByDate(
        agenciesStore.currentAgency?.uid,
        rechargingViewStore.selectedReloadingDate.toDateString());
    })();
    setIsDissociatedPayment(agenciesStore.currentAgency?.paymentMethod === 'DISSOCIATED_BANK_TRANSFER');
  }, [agenciesStore.currentAgency?.uid]);

  useEffect(() => {
    if (agencyInvoiceStore.invoiceBeneficiariesLoadedStatus === 'success') {
      const response: string[] = agencyInvoiceStore.invoiceBeneficiariesByDateList;
      const newActiveBeneficiaries: BeneficiaryModel[] = beneficiariesStore.beneficiariesToRecharge
        .filter(beneficiary => response.indexOf(beneficiary.uid) === -1 && beneficiary.numberOfWorkingDays > 0);

      const newActiveBeneficiaryCount = newActiveBeneficiaries.length;

      orderValidationViewStore.setNewActiveBeneficiaryCount(newActiveBeneficiaryCount);
      orderPaymentViewStore.setBeneficiariesToRecharge(newActiveBeneficiaries);
      agencyInvoiceStore.setInvoiceBeneficiariesLoading(false);
    }
  }, [agencyInvoiceStore.invoiceBeneficiariesLoadedStatus]);

  return (
    <>
      {agencyInvoiceStore.isInvoiceBeneficiariesLoading
        ? <CircularProgress size={64} color={'primary'}/>
        : <>
          {stepperRechargingStore.currentStepIndex === 2 &&
              <h1 style={{ marginTop: '60px' }}>
                {t('validationOrder.recap')}
              </h1>
          }

          <div style={{ marginTop: '1rem' }}>
            <>
              <p>
                <b>
                  {t('subscriptionTitle', {
                    count: newActiveBeneficiaryCount,
                    month: dateString,
                  })}
                </b>
              </p>
              <br/>
              <div className={orderValidationClasses.secondBlock}>
                <p className={orderValidationClasses.subscription}>
                  {t('subscription')}
                </p>
                <p className={orderValidationClasses.amount}>
                  <b>
                    {`${currencyFormat.format(subExclTax)}`}
                  </b>
                </p>
              </div>
              <p className={orderValidationClasses.transaction}>
                {`${t('transaction', { count: newActiveBeneficiaryCount })} ${currencyFormat
                  .format(Number(agenciesStore.currentAgency?.contractSubscriptionFee))}`}
              </p>
              <hr/>
              <div className={orderValidationClasses.paymentBlock}>
                <div className={orderValidationClasses.excl}>
                  <p>{t('totalExcl')}</p>
                  <p className={orderValidationClasses.amount}>
                    {`${currencyFormat.format(subExclTax)}`}
                  </p>
                </div>
                <div className={orderValidationClasses.vat}>
                  <p>
                    {t('vat')} {orderSubscriptionVatRate.toFixed(2)}%
                  </p>
                  <p className={orderValidationClasses.amount}>
                    {currencyFormat.format(subInclTax - subExclTax)}
                  </p>
                </div>
                <div className={orderValidationClasses.incl}>
                  <p>{t('totalIncl')}</p>
                  <p className={orderValidationClasses.amount}>
                    {`${currencyFormat.format(subInclTax)}`}
                  </p>
                </div>
              </div>
              <hr/>
              <br/>
              {agenciesStore.currentAgency?.isManagedPaymentMode
                ? <>
                  <p><b>{t('provisionManaged', { month: dateString })}</b></p>
                  <br/>
                  <p>{t('validationOrder.provisioned')}</p>
                </>
                : <>
                  <p><b>{t('provisionNotManaged', { month: dateString })}</b></p>
                  <br/>
                  <p>{t('validationOrder.provisionNotManaged')}</p>
                </>
              }
              {agenciesStore.currentAgency?.isManagedPaymentMode === false
                ? <div className={orderValidationClasses.thirdBlock}>
                  <p className={orderValidationClasses.transaction}>
                    {t('provision_subtitle',
                      {
                        provisionTotalAmount: currencyFormat.format(provisionAmount),
                        totalNumberOfWorkingDays: provisionTotalDays,
                        quota: currencyFormat.format(Number(agenciesStore.currentAgency?.contractSubscriptionFee)),
                      })}
                    = {currencyFormat.format(provisionAmount)}
                  </p>
                </div>
                : <div className={orderValidationClasses.secondBlock}>
                  <p className={orderValidationClasses.transaction}>
                    {t('provision_subtitle',
                      {
                        provisionTotalAmount: currencyFormat.format(provisionAmount),
                        totalNumberOfWorkingDays: provisionTotalDays,
                        quota: currencyFormat.format(Number(agenciesStore.currentAgency?.contractAgencyPart)),
                      })}
                  </p>
                  <p className={orderValidationClasses.amount}>
                    <b>
                      {currencyFormat.format(provisionAmount)}
                    </b>
                  </p>
                </div>
              }
              <hr/>
              <br/>
              <div className={orderValidationClasses.paymentBlock}>
                <div className={orderValidationClasses.total}>
                  <p>{t('total')}</p>
                  <p className={orderValidationClasses.amount}>
                    {agenciesStore.currentAgency?.isManagedPaymentMode === false
                      ? currencyFormat.format(subInclTax)
                      : currencyFormat.format(netAmountPayable)}
                  </p>
                </div>
              </div>
            </>
          </div>
          {hasInvoiceInformation && hasAtLeastMetadata &&
              <>
                  <h4>{t(`referenceTitle${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}</h4>

                  <div className={countSetMetadata === 2
                    ? orderValidationClasses.start
                    : orderValidationClasses.boxInformation}>
                      <div
                          className={isDissociatedPayment ? orderValidationClasses.dissociatedPaymentReferencesArea : undefined}>
                        {feesCustomerReferenceValue
                          && <StyledTextField
                                className={countSetMetadata === 2 ? `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}` : `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}`}
                                id={'feesCustomerReferenceArea'}
                                name={'feesCustomerReferenceArea'}
                                label={t('yourInformation.feesCustomerReferenceArea')}
                                value={feesCustomerReferenceValue}
                                disabled={true}
                                fullWidth
                            />}

                        {customerReferenceValue
                          && <StyledTextField
                                className={countSetMetadata === 2 ? `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}` : `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}`}
                                id={'customerReferenceArea'}
                                name={'customerReferenceArea'}
                                label={t(`yourInformation.customerReferenceArea${isDissociatedPayment ? '_DISSOCIATED_PAYMENT' : ''}`)}
                                value={customerReferenceValue}
                                disabled={true}
                                fullWidth
                            />}
                      </div>

                    {internalOrderNumberValue
                      && <StyledTextField
                            className={countSetMetadata === 2 ? `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}` : `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}`}
                            id={'internalOrderNumberArea'}
                            name={'internalOrderNumberArea'}
                            label={t('yourInformation.internalOrderNumberArea')}
                            value={internalOrderNumberValue}
                            disabled={true}
                            fullWidth
                        />}

                    {marketNumberValue
                      && <StyledTextField
                            className={countSetMetadata === 2 ? `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}` : `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}`}
                            id={'marketNumberArea'}
                            name={'marketNumberArea'}
                            label={t('yourInformation.marketNumberArea')}
                            value={marketNumberValue}
                            disabled={true}
                            fullWidth
                        />}

                    {commitmentNumberValue
                      && <StyledTextField
                            className={countSetMetadata === 2 ? `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}` : `${orderValidationClasses.input} ${orderValidationClasses.marginTowMetadata}`}
                            id={'commitmentNumberArea'}
                            name={'commitmentNumberArea'}
                            label={t('yourInformation.commitmentNumberArea')}
                            value={commitmentNumberValue}
                            disabled={true}
                            placeholder={''}
                            fullWidth
                        />}
                  </div>
              </>}

          <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '40px' }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => stepperRechargingStore.goToPreviousStep()}
              type="button">
              {t('cancel')}
            </Button>
            <Button
              style={{ marginLeft: '2rem' }}
              variant="contained"
              color="primary"
              onClick={() => stepperRechargingStore.goToNextStep()}
              type="button">
              {t('nextStep')}
            </Button>
          </div>
        </>
      }
    </>
  );
});

export default OrderValidationComponent;
