import { Button, MenuItem, Select } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import Downarrow from '../../../assets/down-arrow';
import magnifyingGlass from '../../../assets/magnifying-glass.svg';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { PaybackModel } from '../../../assets/models/paybacks/Payback.model';
import { GetBeneficiaryPaybacksRequest } from '../../../assets/requests/paybacks/GetBeneficiaryPaybacks.request';
import InputComponent from '../../../Component/InputComponent';
import { BeneficiaryPaybackStore } from '../../../Stores/BeneficiaryPayback.store';
import StyledDataGrid from '../../../Style/MuiStyles/Datagrid';
import { StyledSelect } from '../../../Style/MuiStyles/Select/Select';
import { useBeneficiaryPaybackComponentStyles } from './BeneficiaryPaybackManagedStyles';

export interface IBeneficiaryPaybackComponentProps {
  beneficiary?: BeneficiaryModel;
  onClose: () => void;
}

type LocalStore = [BeneficiaryPaybackStore];

const BeneficiaryPaybackComponent: FunctionComponent<IBeneficiaryPaybackComponentProps> = observer((props: IBeneficiaryPaybackComponentProps) => {
  const [beneficiaryPaybackStore]: [BeneficiaryPaybackStore] = useInstances<LocalStore>(BeneficiaryPaybackStore);
  const isFetching: boolean = beneficiaryPaybackStore.isLoading;

  const beneficiaryPaybackClasses = useBeneficiaryPaybackComponentStyles(props);
  const { t } = useTranslation('beneficiaries');

  const [data, setData] = useState<PaybackModel[]>([]);
  const [filteredData, setFilteredData] = useState<PaybackModel[]>([]);

  const [search, setSearch] = useState('');
  const [sinceDate, setSinceDate] = React.useState('0');

  const applySearch = (newSearch: string, paybacks: PaybackModel[]) => {
    setSearch(newSearch);

    const filterData: PaybackModel[] = paybacks.filter((payback: PaybackModel) => {
      const transactionDate: Date = new Date(payback.transactionDate);
      const providerPayoutRequestDate: Date = new Date(payback.providerPayoutRequestDate);

      return (
        (payback.transactionDate && transactionDate.toLocaleDateString().search(newSearch) !== -1) ||
        (payback.providerPayoutRequestDate && providerPayoutRequestDate.toLocaleDateString().search(newSearch) !== -1) ||
        payback.amount.toString().search(newSearch) !== -1
      );
    });

    setFilteredData(filterData);
  };

  async function getPaybacks(fromDate: number): Promise<void> {
    const request: GetBeneficiaryPaybacksRequest = {
      status: 'ACCEPTED',
      from: fromDate,
    };

    await beneficiaryPaybackStore.getBeneficiaryPaybacks(props.beneficiary.uid, request);

    if (!beneficiaryPaybackStore.isLoading && beneficiaryPaybackStore.getBeneficiaryPaybacksStatus === 'SUCCESS') {
      setData(beneficiaryPaybackStore.beneficiaryPaybacks);
      applySearch(search, beneficiaryPaybackStore.beneficiaryPaybacks);
    }
  }

  useEffect(() => {
    const currentDate: Date = new Date();
    const fromDate: number = currentDate.setMonth(currentDate.getMonth() - 3);
    getPaybacks(fromDate);
  }, [props.beneficiary]);

  const dataGridColumns: GridColDef[] = [
    {
      field: 'transactionDate',
      headerName: 'Date de transaction',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>{new Date(params.value as number).toLocaleDateString('fr-FR')} </div>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Montant à rembourser',
      flex: 2,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {new Intl.NumberFormat('fr-FR', {
              style: 'currency',
              currency: 'EUR',
            }).format(params.value as number)}
          </div>
        );
      },
    },
    {
      field: 'providerPayoutRequestDate',
      headerName: 'Date de validation',
      flex: 3,
      renderCell: function titleCell(params: GridCellParams) {
        return (
          <div style={{ width: '100%' }}>
            {params.value ? new Date(params.value as number).toLocaleDateString('fr-FR') : ''}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <p className={beneficiaryPaybackClasses.title}>
        ENSEMBLE DES REMBOURSEMENTS VERSÉS SUR LA PÉRIODE
      </p>
      <div className={beneficiaryPaybackClasses.block}>
        <Select
          value={sinceDate}
          onChange={(evt) => {
            const currentDate = new Date();
            let from: number;
            switch (evt.target.value) {
              case '1':
                from = currentDate.setMonth(currentDate.getMonth() - 6);
                break;
              case '2':
                from = currentDate.setFullYear(currentDate.getFullYear() - 1);
                break;
              case '3':
                from = new Date(0).getTime();
                break;
              case '0':
              default:
                from = currentDate.setMonth(currentDate.getMonth() - 3);
            }

            setSinceDate(evt.target.value as string);
            getPaybacks(from);
          }}
          labelId="label"
          id="select"
          input={<StyledSelect/>}
          IconComponent={(props) => Downarrow({
            svgColor: '#FFFFFF', ...props,
            className: beneficiaryPaybackClasses.icon,
          })}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
        >
          <MenuItem value="0">{t('since3months')}</MenuItem>
          <MenuItem value="1">{t('since6months')}</MenuItem>
          <MenuItem value="2">{t('since1year')}</MenuItem>
          <MenuItem value="3">{t('sinceBeginning')}</MenuItem>
        </Select>
        <div className={beneficiaryPaybackClasses.search}>
          <InputComponent
            placeholder={t('paybackSearchPlaceholder')}
            icon={magnifyingGlass}
            onChange={(search) => applySearch(search, data)}
            value={search}
            iconLeft
          />
        </div>
      </div>

      <StyledDataGrid
        rows={filteredData}
        columns={dataGridColumns}
        showColumnRightBorder={false}
        showCellRightBorder={false}
        disableSelectionOnClick
        loading={isFetching}
      />
      <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '40px' }}>
        <Button
          onClick={props.onClose}
          type="button"
          variant="contained"
          color="primary">
          {t('close')}
        </Button>
      </div>
    </>
  );
});

export default BeneficiaryPaybackComponent;
