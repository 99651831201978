import { callable } from './httpWrapper.service';
import { GetAgencyPayinResponse } from '../assets/responses/payins/GetAgencyPayin.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { PayinReportModel } from '../assets/models/payins/PayinReport.model';

export async function getAgencyPayins (agencyId: string): Promise<GetAgencyPayinResponse[]> {
  return callable(
    'getAgencyPayins',
    { agencyId },
  );
}

export async function getAgencyPayinReports (agencyId: string, requestBody: OptionalTimePeriodRequest): Promise<PayinReportModel[]> {
  return callable(
    'getAgencyPayinReports',
    { agencyId },
    requestBody,
  );
}
