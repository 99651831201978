import { action, makeAutoObservable, runInAction } from 'mobx';
import { GetBeneficiaryProvisionResponse } from '../assets/responses/orders/GetBeneficiaryProvisions.response';
import { OptionalTimePeriodRequest } from '../assets/requests/TimePeriod.request';
import { getBeneficiaryProvisions } from '../Services/ordersAPI.service';

export class ProvisionStore {
  isLoading = false;
  getBeneficiaryProvisionsStatus = 'PENDING';
  beneficiaryProvisions: GetBeneficiaryProvisionResponse[] = [];

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  @action setLoadingProvisions (isLoading: boolean = true) {
    this.isLoading = isLoading;
  }

  @action reset() {
    this.isLoading = false;
    this.getBeneficiaryProvisionsStatus = 'PENDING';
    this.beneficiaryProvisions = [];
  }

  async getBeneficiaryProvisions(agencyUid: string, beneficiaryUid: string, request?: OptionalTimePeriodRequest) {
    this.setLoadingProvisions();
    
    if (!Object.keys(request).length) {
      const currentDate: Date = new Date();
      request.from = currentDate.setMonth(currentDate.getMonth() - 3);
    }
    
    try {
      const beneficiaryProvisions: GetBeneficiaryProvisionResponse[] = await getBeneficiaryProvisions(agencyUid, beneficiaryUid, request);

      runInAction(() => {
        if (beneficiaryProvisions) {
          this.beneficiaryProvisions = beneficiaryProvisions;
          this.getBeneficiaryProvisionsStatus = 'SUCCESS';
        }
      });
    } catch (error) {
      this.getBeneficiaryProvisionsStatus = 'ERROR';
    }

    this.setLoadingProvisions(false);
  }
}
