import axios, { AxiosResponse } from 'axios';
import { errorStore } from '../Stores/Error.store';
import { refreshAccessToken } from './accessToken.service';

const refreshTokenErrorStack: ((error: any) => void)[] = [];

export const httpInterceptors = {
  request: axios.interceptors.request,
  response: axios.interceptors.response,
};

httpInterceptors.response.use(
  (response: AxiosResponse) => Promise.resolve(response),
  async (error: any) => {
    if (error.response?.status === 406) {
      const accessToken: string | undefined = await refreshAccessToken();

      if (accessToken) {
        const { config } = error;
        config.headers['sebauth'] = accessToken;
        return axios.request(config);
      }

      // logout user if no access token was retrieved
      refreshTokenErrorStack.forEach((eventHandler: (error) => void) => eventHandler(error));
    }
    errorStore.catchError(error);
    return Promise.reject(error);
  },
);

export function onRefreshTokenError(eventHandler: (error: any) => void) {
  refreshTokenErrorStack.push(eventHandler);
};
