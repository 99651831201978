const dashboard = {
  email: 'Adresse email',
  emailPlaceholder: 'jean.dupond@mail.com',
  password: 'Mot de passe',
  passwordPlaceholder: 'Renseignez votre mot de passe',
  forgotPassword: 'Je l\'ai oublié',
  connection: 'Me connecter',
  connectionFailed: '😬 Impossible de se connecter, votre email ou votre mot de passe est invalide.',
  beneficiariesPartOne_one: 'Collaborateur',
  beneficiariesPartOne_other: 'Collaborateurs',
  beneficiariesPartTwo_one: '{{count}} n’a pas encore activé son compte, ',
  beneficiariesPartTwo_other: '{{count}} n’ont pas encore activé leurs comptes, ',
  beneficiariesPartThree_one: 'le relancer',
  beneficiariesPartThree_other: 'les relancer',
  dailyAmount: 'C\'est le montant alloué au repas par jour et par collaborateur.',
  contribution: 'Correspond aux {{companyQuota}} % de participation de l\'entreprise par jour par collaborateur.',
  hello: '👋 Bienvenue !',
  hello2: ' Comment ça va aujourd\'hui ?',
  cookiesTitle: '🍪 Nous n’utilisons pas de cookies',
  cookiesText1: 'Vous pouvez naviguer en toute tranquillité, ce site n’utilise pas de cookies.',
  cookiesValidation: 'OK, c\'est noté',
  firstname: 'Prénom',
  title_one: '{{count}} collaborateur n’a pas encore activé son compte',
  title_other: '{{count}} collaborateurs n’ont pas encore activé leur compte',

  next: 'Suivant',
  login: 'Me connecter',
  cancel: 'Annuler',
  subtitle: 'Accéder à votre espace client',
};

export default dashboard;
