import React from 'react';
import { InputBase, InputBaseProps, makeStyles, withStyles } from '@material-ui/core';
import { COLORS, FONT_WEIGHT } from '../../Style';
import { useTranslation } from 'react-i18next';

const useTextFieldStyles = makeStyles(() => ({
  inputLabel: {
    color: COLORS.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: FONT_WEIGHT.semiBold,
    '&.Mui-focused': {
      color: COLORS.white
    },
    '&.Mui-error': {
      color: COLORS.white
    }
  },
  required: (props:InputBaseProps) => ({
    color: props.error ? COLORS.error : '#A3AAB2'
  })
}));

const StyledInputSelectBlack = ({ title, ...props }: InputBaseProps) => {
  const { t } = useTranslation('common');
  const { required } = props;
  const textFieldClasses = useTextFieldStyles({ required });

  return (
    <div style={{ width: '100%', height: '60px', position: 'relative' }}>
      <div style={{
        zIndex: 1,
        position: 'absolute',
        color: COLORS.textPrimary,
        fontWeight: FONT_WEIGHT.semiBold,
        fontSize: '14px',
        userSelect: 'none',
        width: '100%',
        height: '60px',
        padding: '16px 16px 0px 16px',
        pointerEvents: 'none'
      }}>
        <div className={textFieldClasses.inputLabel}>
          <div style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '140px',
          }}>
            {title}
          </div>
          {props.required && <div className={textFieldClasses.required}>{t('required')}</div>}
        </div>
      </div>
      <InputBase fullWidth {...props} />
    </div>
  );
};

export const InputSelectBgBlack = withStyles((theme) => ({
  input: {
    width: '100%',
    height: '47px',
    borderRadius: 20,
    backgroundColor: COLORS.textPrimary,
    padding: 0,
    color: COLORS.textPrimary,
    fontSize: 14,
    textIndent: '16px',
    lineHeight: '72px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: COLORS.textPrimary,
      borderRadius: 20
    },
    '&:hover': {
      backgroundColor: COLORS.textPrimary,
      boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },

}))(StyledInputSelectBlack);
