import { makeStyles } from '@material-ui/core';
import { COLORS, FONT } from '../../../Style/Style';

export const useConnectionStyles = makeStyles(() => ({
  input: {
    marginBottom: '25px'
  },
  forgotPasswordText: {
    textAlign: 'left',
    marginBottom: '40px',
    fontSize: '14px',
    color: COLORS.textPrimary,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  snackbarContent: {
    background: 'linear-gradient(to right, #E94B32 4%, white 4%)',
    borderRadius: '20px'
  },
  snackbarMessage: {
    width: '370px',
    backgroundColor: 'white',
    color: COLORS.textPrimary,
    padding: '1.5rem',
    fontFamily: FONT.primary
  }
}));
