import * as React from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { StepperRechargingStore } from '../Stores/viewStore/StepperRecharging.store';
import { StyledStepper } from './StepperComponentStyles';
import { IStepperComponentProps } from '../Models/Interfaces/IStepperComponent.model';
import { useTranslation } from 'react-i18next';

const StepperComponent = observer((props: IStepperComponentProps) => {
  const { className, steps, onChange, onComplete } = props;

  const stepperRecharging: StepperRechargingStore = useInstance<StepperRechargingStore>(StepperRechargingStore);
  const current: number = stepperRecharging.currentStepIndex;
  const { t } = useTranslation('reloading');

  useEffect(() => {
    if (onChange) {
      onChange(current);
    }

    if (current >= steps.length && onComplete) {
      onComplete(steps.length);
    }
  }, [current]);

  return (
    <div className={classNames(className, 'stepper', StyledStepper)}>
      <span className={classNames('step-line', 'step-line-active')}/>
      {steps.map((step, key) => {
        const completedStep: boolean = key < current;
        const previousStep: boolean = key < current;
        const currentStep: boolean = key === current;

        return (
          <React.Fragment key={key}>
            <div onClick={() => stepperRecharging.returnToStep(step)}>
              <span
                className={classNames('step-circle', completedStep ? 'step-circle-completed' : currentStep ? 'step-circle-active' : 'step-circle-disable')}>
                <span
                  onClick={() => previousStep}
                  className={classNames('step-text', {
                    'step-text-current': currentStep,
                    'step-text-clickable': previousStep,
                  })}>
                  {t(step)}
                </span>
              </span>
            </div>
            <span className={classNames('step-line', previousStep ? 'step-line-active' : 'step-line-disable')}/>
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default StepperComponent;
