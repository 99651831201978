import { MenuItem, Select } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import Calendar from '../../../assets/calendar';
import Downarrow from '../../../assets/down-arrow';
import MailSvg from '../../../assets/mail';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import { ListOfCountryCode } from '../../../assets/models/specials/listOfCountryCode';
import { SelectCountryCodeModel, SelectGenderModel } from '../../../assets/models/specials/selectListData.model';
import { CreateBeneficiaryRequest } from '../../../assets/requests/beneficiaries/CreateBeneficiary.request';
import { UpdateBeneficiaryRequest } from '../../../assets/requests/beneficiaries/UpdateBeneficiary.request';
import capitalizeFirstLetter from '../../../Function/CapitalizeFirstLetter';
import isValidIBANNumber from '../../../Function/IbanValidator';
import Yup from '../../../i18n/validation';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { BeneficiariesStore, UpdateBeneficiaryFullRequest } from '../../../Stores/Beneficiaries.store';
import { BeneficiariesDialogViewStore } from '../../../Stores/viewStore/BeneficiariesDialogView.store';
import Button from '../../../Style/MuiStyles/Button';
import { InputSelect } from '../../../Style/MuiStyles/Select/Select';
import StyledSwitch from '../../../Style/MuiStyles/Switch';
import StyledTextField, { StyledTextFieldProps } from '../../../Style/MuiStyles/TextField';
import { COLORS } from '../../../Style/Style';
import { getObjectTestResult, ObjectTestResult, TestSet } from '../../../Utils/Tester/BaseTester.service';
import { getFormBeneficiaryTestSet } from '../../../Utils/Tester/BeneficiaryTester.service';
import { useBeneficiaryAccountInformationsStyles } from './BeneficiaryAccountInformationsStyles';
import Warning from '../../../assets/warning';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { allowToModifyBeneficiaryDeactivationConfig } from '../../../assets/utils/beneficiaries/beneficiaryDeactivation.util';

export interface BeneficiaryTextFieldProps extends StyledTextFieldProps {
  formik: any;
  countryFlag?: string;
}

const BeneficiaryTextField = ({ id, formik, countryFlag, ...props }: BeneficiaryTextFieldProps) => {
  const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({});
  const { t } = useTranslation('beneficiaries');
  return (
    <div style={{ position: 'relative' }}>
      <StyledTextField
        className={beneficiariesAccountInformationsClasses.input}
        id={id}
        name={id}
        label={t(id)}
        value={formik.values[id]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={Boolean(formik.errors[id]) && formik.touched[id]}
        errormessage={formik.errors[id]}
        placeholder={t(`${id}Placeholder`)}
        fullWidth
        {...props}
      />
      {countryFlag &&
          <span style={{
            position: 'absolute',
            top: 25,
            right: 100,
          }} className={`fi fi-${countryFlag}`}/>
      }
    </div>);
};

export interface IBeneficiaryAccountInformationsComponentProps {
  onClose: () => void;
  beneficiary?: BeneficiaryModel;
}

export interface BeneficiaryFormValues extends Omit<CreateBeneficiaryRequest & UpdateBeneficiaryRequest, 'firstRightDate'> {
  firstRightDate: string;
}

type LocalStore = [AgenciesStore, BeneficiariesStore, BeneficiariesDialogViewStore];

const BeneficiaryAccountInformationsComponent: FunctionComponent<IBeneficiaryAccountInformationsComponentProps> =
  observer((props: IBeneficiaryAccountInformationsComponentProps) => {
    const [
      agenciesStore,
      beneficiariesStore,
      beneficiariesDialogViewStore,
    ]: LocalStore = useInstances<LocalStore>(AgenciesStore, BeneficiariesStore, BeneficiariesDialogViewStore);
    const { beneficiary, onClose }: { beneficiary?: BeneficiaryModel, onClose: () => void } = props;

    const disableModification: boolean = useMemo(() => {
      if (!beneficiary) {
        return false;
      }
      return !allowToModifyBeneficiaryDeactivationConfig(beneficiary);
    }, [beneficiary]);

    const beneficiariesAccountInformationsClasses = useBeneficiaryAccountInformationsStyles({
      disabled: disableModification,
    });
    const { t } = useTranslation('beneficiaries');

    const [isIbanLocked, setIsIbanLocked] = useState<boolean>(true);
    const [isEmailLocked, setIsEmailLocked] = useState(true);
    const [hasSecureDataToUpdate, setHasSecureDataToUpdate] = useState(false);

    const isEditMode = (beneficiary !== null);
    const isServerError = beneficiariesDialogViewStore.isServerError;

    const existingBeneficiaryList: BeneficiaryModel[] = beneficiariesStore.beneficiariesList;
    const formBeneficiaryTestSet: TestSet<CreateBeneficiaryRequest | UpdateBeneficiaryRequest> = useMemo(() => {
      return getFormBeneficiaryTestSet(existingBeneficiaryList);
    }, [existingBeneficiaryList]);

    Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

    const validationSchema = Yup.object().shape({
      email: Yup.string().email().required(),
      registrationNumber: Yup.string()
        .required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      streetAddress: Yup.string(),
      postalCode: Yup.string()
        .min(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
        .max(5, 'Ce champ doit être renseigné avec 5 caractères obligatoirement.')
        .required(),
      city: Yup.string().required(),
      countryCode: Yup.string(),
      iban: agenciesStore.currentAgency.isManagedPaymentMode && ((isEditMode && !isIbanLocked) || !isEditMode)
        ? (Yup.string() as any).required().isValidIBANNumber()
        : Yup.string(),
      numberOfWorkingDays: Yup.number()
        .min(0, 'Ce nombre doit être compris entre 0 et 31')
        .max(31, 'Ce nombre doit être compris entre 0 et 31')
        .required(),
    });

    const listOfCountryCode: SelectCountryCodeModel[] = ListOfCountryCode;

    const listOfTitleBeneficiary: SelectGenderModel[] = [
      { label: 'M.', value: 'Mr' },
      { label: 'Mme', value: 'Mrs' },
      { label: 'Aucun', value: 'Unknown' },
    ];

    const analyseBeneficiaryData = <T, >(beneficiaryToTest: T) => {
      return getObjectTestResult(beneficiaryToTest, formBeneficiaryTestSet);
    };

    const addBeneficiary = async (
      beneficiaryToCreate: CreateBeneficiaryRequest,
      setFieldError: (field: string, errorMsg: string) => void,
      setSubmitting: (isSubmitting: boolean) => void,
    ) => {
      const formattedBeneficiary: CreateBeneficiaryRequest = {
        ...beneficiaryToCreate,
        firstName: capitalizeFirstLetter(beneficiaryToCreate.firstName.toLowerCase()),
        lastName: beneficiaryToCreate.lastName.toUpperCase(),
        iban: beneficiaryToCreate.iban.replaceAll(/\s+/g, ''),
        agencyId: agenciesStore.currentAgency?.uid,
      };

      const beneficiaryTestResult: ObjectTestResult<CreateBeneficiaryRequest> = analyseBeneficiaryData(formattedBeneficiary);

      if (!beneficiaryTestResult.passed) {
        beneficiaryTestResult.errors.forEach(error => {
          if (error.fieldName === 'email') {
            return setFieldError('email', 'Ce collaborateur existe déjà.');
          } else if (error.fieldName === 'registrationNumber') {
            return setFieldError('registrationNumber', 'Ce matricule existe déjà.');
          } else if (error.fieldName === 'iban') {
            return setFieldError('iban', 'L\'iban saisi n\'est pas valide.');
          }
        });
      }

      if (beneficiaryTestResult.passed) {
        try {
          await beneficiariesStore.createBeneficiary(agenciesStore.currentAgency.uid, formattedBeneficiary);
          onCloseDrawer();

        } catch (error) {
          beneficiariesDialogViewStore.setIsServerError(true);
          console.error(error);
        }
      }

      setSubmitting(false);
    };

    const updateBeneficiary = async (
      beneficiaryToUpdate: BeneficiaryFormValues,
      setFieldError: (field: string, errorMsg: string) => void,
      setSubmitting: (isSubmitting: boolean) => void,
    ) => {
      let updateBeneficiaryAllRequest: UpdateBeneficiaryFullRequest = {
        agencyId: agenciesStore.currentAgency.uid,
        beneficiaryId: beneficiary.uid,
        beneficiaryMin: {
          ...beneficiaryToUpdate,
          firstName: capitalizeFirstLetter(beneficiaryToUpdate.firstName.toLowerCase()),
          lastName: beneficiaryToUpdate.lastName.toUpperCase(),
        },
        beneficiaryActivity: {
          numberOfWorkingDays: beneficiaryToUpdate.numberOfWorkingDays,
          activeSundaysAndHolidays: beneficiaryToUpdate.activeSundaysAndHolidays,
        },
      };

      if (beneficiariesStore.currentBeneficiary.registrationNumber !== beneficiaryToUpdate.registrationNumber) {
        updateBeneficiaryAllRequest.registrationNumber = { registrationNumber: beneficiaryToUpdate.registrationNumber };
      }

      if (!isEmailLocked) {
        updateBeneficiaryAllRequest.beneficiaryEmail = { email: beneficiaryToUpdate.email };
      }

      if (!isIbanLocked) {
        updateBeneficiaryAllRequest.beneficiaryIban = { iban: beneficiaryToUpdate.iban };
      }

      try {
        await beneficiariesStore.updateBeneficiaryAll(updateBeneficiaryAllRequest);
        onCloseDrawer();
      } catch (error) {
        beneficiariesDialogViewStore.setIsServerError(true);
        console.error(error);
      }

      setSubmitting(false);
    };

    const formik = useFormik({
      initialValues: {
        title: beneficiary?.title ?? 'Mrs',
        firstName: beneficiary?.firstName ?? '',
        lastName: beneficiary?.lastName ?? '',
        streetAddress: beneficiary?.streetAddress ?? '',
        additionalAddress: beneficiary?.additionalAddress ?? '',
        postalCode: beneficiary?.postalCode ?? '',
        city: beneficiary?.city ?? '',
        countryCode: beneficiary?.countryCode ?? 'FR',
        registrationNumber: beneficiary?.registrationNumber ?? '',
        email: beneficiary?.email ?? '',
        firstRightDate: beneficiary?.firstRightDate
          ? moment(beneficiary.firstRightDate).format('YYYY-MM-DD')
          : moment().add(1, 'M').startOf('month').format('YYYY-MM-DD'),
        activeSundaysAndHolidays: beneficiary?.activeSundaysAndHolidays ?? false,
        iban: beneficiary?.ibanLastNumbers ? `************${beneficiary?.ibanLastNumbers}` : '',
        numberOfWorkingDays: beneficiary?.numberOfWorkingDays ?? 0,
        agencyId: agenciesStore.currentAgency?.uid,
      },
      validationSchema: validationSchema,
      validateOnBlur: true,
      onSubmit: async (beneficiaryFields: BeneficiaryFormValues, {
        setSubmitting,
        setFieldError,
      }: FormikHelpers<BeneficiaryFormValues>) => {
        setSubmitting(true);
        beneficiariesDialogViewStore.setIsServerError(false);

        if (isEditMode) {
          await updateBeneficiary(beneficiaryFields, setFieldError, setSubmitting);
        } else {
          const firstRightDateInTimeStamp: number = moment(beneficiaryFields.firstRightDate).valueOf();
          const fieldsToCreate: CreateBeneficiaryRequest = {
            ...beneficiaryFields,
            firstRightDate: firstRightDateInTimeStamp,
          };

          await addBeneficiary(fieldsToCreate, setFieldError, setSubmitting);
        }
      },
    });

    const getFlag: (iban: string) => string = useCallback((iban: string) => {
      const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
      if (!sanitizedIban || sanitizedIban.length < 2) {
        return '';
      }
      const countryCode: string = iban.slice(0, 2);
      return countryCode.toLowerCase();
    }, []);

    const onSubmitForm: () => void = useCallback(() => {
      if (isEditMode && (!isEmailLocked || !isIbanLocked) && !hasSecureDataToUpdate) {
        return setHasSecureDataToUpdate(true);
      }
      formik.handleSubmit();
    }, [hasSecureDataToUpdate, isEmailLocked, isIbanLocked, isEditMode]);

    const onCloseDrawer: () => void = useCallback(() => {
      onClose();
      setHasSecureDataToUpdate(false);
      setIsEmailLocked(true);
      setIsIbanLocked(true);
      beneficiariesStore.currentBeneficiary = null;
      formik.resetForm();
    }, []);

    const timestampToDateString = (timestamp: number): string => {
      return new Date(timestamp).toLocaleDateString();
    };

    return (
      <form onSubmit={formik.handleSubmit} noValidate>
        <fieldset disabled={beneficiary?.isActive === false}>

          {/* --- INFORMATIONS SUR LE COMPTE --- */}
          <p className={beneficiariesAccountInformationsClasses.title}>
            INFORMATIONS SUR LE COMPTE
          </p>

          {(beneficiary?.deactivationDate) &&
              <p className={beneficiariesAccountInformationsClasses.beneficiaryDeactivate}>Ce collaborateur est sorti du
                  dispositif le : {timestampToDateString(beneficiary.deactivationDate)}</p>
          }

          <div style={{ display: 'inline-flex', width: '100%' }}>
            <div style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
              <BeneficiaryTextField
                id="registrationNumber"
                required
                formik={formik}
                autoFocus
                disabled={isEditMode && !beneficiary?.isActive || disableModification}/>
            </div>
          </div>

          <div style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
          }}>
            <div style={{ width: isEditMode ? 'calc(50% - 10px)' : '100%', marginRight: '20px', paddingTop: '20px' }}>
              <BeneficiaryTextField
                id="email"
                InputProps={{ endAdornment: <MailSvg style={{ marginTop: '20px' }}/> }}
                required
                formik={formik}
                disabled={isEditMode && isEmailLocked || disableModification}
              />
            </div>
            {isEditMode && isEmailLocked &&
                <Button
                    onClick={() => setIsEmailLocked(false)}
                    type="button"
                    variant="contained"
                    color={'secondary'}
                    style={{
                      width: '30%',
                    }}
                    disabled={disableModification}
                    className={beneficiariesAccountInformationsClasses.hiddenButton}
                >
                    Dévérouiller pour modifier
                </Button>
            }
          </div>
          {/* ---------------------------------- */}

          {/* ------------ IDENTITÉ ------------ */}
          <p className={beneficiariesAccountInformationsClasses.title}>
            IDENTITÉ
          </p>

          <div className={beneficiariesAccountInformationsClasses.select} style={{ width: 'calc(50% - 10px)' }}>
            <Select
              value={formik.values.title}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('title', evt.target.value)}
              id="select"
              disabled={disableModification}
              input={<InputSelect title="Civilité" required/>}
              IconComponent={(props) => <div
                style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
              </div>}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {
                listOfTitleBeneficiary.map((title, index) =>
                  <MenuItem key={index} value={title.value}>{t(title.label)}</MenuItem>,
                )
              }
            </Select>
          </div>

          <BeneficiaryTextField id="lastName" disabled={disableModification} formik={formik} required/>

          <BeneficiaryTextField id="firstName" disabled={disableModification} formik={formik} required/>
          {/* ---------------------------------- */}

          {/* ------------- ADRESSE ------------- */}
          <p className={beneficiariesAccountInformationsClasses.title}>
            ADRESSE
          </p>

          <div className={beneficiariesAccountInformationsClasses.select}
               style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
            <Select
              value={formik.values.countryCode}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('countryCode', evt.target.value)}
              labelId="label"
              id="select"
              input={<InputSelect title="Pays de résidence"/>}
              disabled={disableModification}
              IconComponent={(props) => <div
                style={{ marginTop: '0px' }}>{Downarrow({ svgColor: COLORS.textPrimary, ...props })}
              </div>}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {
                listOfCountryCode.map((countryCode, index) =>
                  <MenuItem key={index} value={countryCode.value}>{t(countryCode.label)}</MenuItem>,
                )
              }
            </Select>
          </div>
          <BeneficiaryTextField id="streetAddress" disabled={disableModification} formik={formik}/>
          <BeneficiaryTextField id="additionalAddress" disabled={disableModification} formik={formik}/>
          <div style={{ display: 'inline-flex', width: '100%' }}>
            <div style={{ width: 'calc(50% - 10px)', marginRight: '20px' }}>
              <BeneficiaryTextField id="postalCode" disabled={disableModification} required formik={formik}/>
            </div>
            <div style={{ width: 'calc(50% - 10px)' }}>
              <BeneficiaryTextField id="city" disabled={disableModification} required formik={formik}/>
            </div>
          </div>
          {/* ---------------------------------- */}

          {/* -------- RÉFÉRENCE BANCAIRE ------- */}
          <>
            <p className={beneficiariesAccountInformationsClasses.title}>
              RÉFÉRENCE BANCAIRE
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ width: isEditMode ? 'calc(50% - 10px)' : '100%', marginRight: '20px', paddingTop: '20px' }}>
                <BeneficiaryTextField
                  id="iban"
                  formik={formik}
                  required
                  disabled={isEditMode && isIbanLocked || disableModification}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => formik
                    .setFieldValue(event.target.name, event.target.value.trim().replace(/\s+/g, ''))}
                  countryFlag={getFlag(formik.values.iban)}
                />
              </div>
              {isEditMode && isIbanLocked &&
                  <Button
                      onClick={() => setIsIbanLocked(false)}
                      type="button"
                      variant="contained"
                      color={'secondary'}
                      style={{ width: '30%' }}
                      disabled={disableModification}
                      className={beneficiariesAccountInformationsClasses.hiddenButton}
                  >
                      Dévérouiller pour modifier
                  </Button>
              }
            </div>
          </>
          {/* ---------------------------------- */}

          {/* ------------ ACTIVITÉ ------------ */}
          <p className={beneficiariesAccountInformationsClasses.title}>
            ACTIVITÉ
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ minWidth: '260px', marginRight: '20px' }}>
              <BeneficiaryTextField
                id="firstRightDate"
                type="date"
                InputProps={{ endAdornment: <Calendar style={{ marginTop: '20px' }}/> }}
                formik={formik}
                disabled={isEditMode || disableModification}
                required
              />
            </div>

            <div style={{ minWidth: '220px', marginRight: '20px' }}>
              <BeneficiaryTextField
                id="numberOfWorkingDays"
                type="number"
                formik={formik}
                disabled={disableModification}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  formik.setFieldValue(event.target.name, Number(event.target.value));
                }}
                required
              />
            </div>

            <div style={{ minWidth: '350px' }}>
              Travaille le dimanche et les jours fériés
              <StyledSwitch
                checked={(formik.values).activeSundaysAndHolidays}
                onChange={() => formik.setFieldValue('activeSundaysAndHolidays', !(formik.values).activeSundaysAndHolidays)}
                name="checkedA"
                color="secondary"
                disabled={disableModification}
                className={beneficiariesAccountInformationsClasses.switchOpacity}
              />
            </div>


          </div>
          {/* ---------------------------------- */}

          {isServerError &&
              <span style={{ color: 'red' }}>Erreur serveur veuillez réessayer plus tard !</span>
          }
        </fieldset>

        {hasSecureDataToUpdate &&
            <div className={beneficiariesAccountInformationsClasses.warning}>
                <Warning color={COLORS.blackLight}/>
                <span className={beneficiariesAccountInformationsClasses.warningTxt}>
                Vous avez modifié des données sensibles, confirmez-vous la modifications de ces informations ?
              </span>
            </div>
        }

        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px', marginBottom: '40px' }}>
          <Button
            onClick={onCloseDrawer}
            type="button"
            variant="contained"
            color={beneficiary?.isActive !== false ? 'secondary' : 'primary'}
          >
            {beneficiary?.isActive !== false ? (hasSecureDataToUpdate ? t('confirmCancel') : t('cancel')) : t('close')}
          </Button>
          {beneficiary?.isActive !== false &&
              <Button
                  style={{ marginLeft: '30px' }}
                  onClick={onSubmitForm}
                  isFetching={formik.isSubmitting}
                  disabled={formik.isSubmitting || disableModification}
                  variant="contained"
                  color="primary"
              >
                {beneficiary === null ? t('add') : hasSecureDataToUpdate ? t('confirmUpdate') : t('modify')}
              </Button>
          }
        </div>
      </form>
    );
  });

export default BeneficiaryAccountInformationsComponent;
