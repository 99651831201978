import { BeneficiaryModel } from '../assets/models/beneficiaries/Beneficiary.model';

export const checkInputValuesFilter = (beneficiaryName: string, valueCheck: string) => {
  return (beneficiaryName && beneficiaryName.toLocaleLowerCase().search(valueCheck) !== -1);
};


export function compareBeneficiaries(beneficiaryA: BeneficiaryModel, beneficiaryB: BeneficiaryModel) {
  const fullNameA = (beneficiaryA.lastName + beneficiaryA.firstName).toLowerCase();
  const fullNameB = (beneficiaryB.lastName + beneficiaryB.firstName).toLowerCase();

  if (fullNameA < fullNameB) { return -1 }
  if (fullNameA > fullNameB) { return 1 }
  return 0;
}
